import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { db } from '../firebase';
import { collection, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import './Join.css';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Link } from 'react-router-dom';
import { ReactComponent as Loader } from '../loader.svg';
import { useLocation } from "../LocationProvider";
import {useAuth} from "../AuthContext";
import { useNavigate } from "react-router-dom";
import BrandSlider from "./BrandSlider";

const errorMessage = document.getElementById("lblErrorMessage");
const initialState = {
    loginEmail: '',
    loginPassword: ''

}
export default function Login() {
    const navigate = useNavigate();
    //localStorage.removeItem('PID');
    const { twitterSignIn, facebookSignIn, googleSignIn, user  } = useAuth();
    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error){
            //console.log(error)
        }
    }
    const handleFacebookSignIn = async () => {
      try {
          await facebookSignIn();
      } catch (error){
          //console.log(error)
      }
  }

  const handleTwitterSignIn = async () => {
      try {
          await twitterSignIn();
      } catch (error){
      //console.log(error)
      }
  }
    useEffect(( ) => {
            if(user != null){
                navigate('/account')
            }
      }, [user]); // The empty dependency array means this effect runs once when the component mounts
  
    const [formData, setFormData] = useState(initialState);
    const [showLoader, setShowLoader] = useState(false);
    const [errorWrongPassword, setErrorWrongPassword] = useState(false);
    const handleInputChange = ( e ) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        setShowLoader(false);
    };
    const handleInputChangePass = ( e ) => {
        e.preventDefault();
        setShowLoader(false);
        setErrorWrongPassword(false);
    };

    const txtEmail = document.querySelector('#loginEmail');
    const txtPassword = document.querySelector('#loginPassword');
    const handleJoin = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        const loginEmail = txtEmail.value; 
        const loginPassword = txtPassword.value;
         
        //const hashPassword = (loginPassword) => {
            // Using md5 library
            // return md5(password);
          
            // Using crypto-js
           // return MD5(loginPassword).toString();
          //};
         // const hashedPassword = hashPassword(loginPassword);
        try
        {
            const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            const { uid, email } = userCredential.user;
            const getUserData = collection(db, 'Users');
            const querySnapshot = await getDocs(query(getUserData, where('Email', '==', loginEmail)));
            if (querySnapshot.size === 1) {
            const docData = querySnapshot.docs[0].data();
            const { PID, id, CashAmount } = docData;
            localStorage.setItem('PID', PID);
            localStorage.setItem('CashAmount', CashAmount);
            console.log('User UID', uid);
            console.log('User Email', email);
            console.log('PID:', PID);
            console.log('ID:', id);
            console.log('Cash Amount:', CashAmount);
            } else {
            console.error('User not found or multiple users with the same email.');
            }
          } catch (error) {
            showerror(error);
            //console.error('auth/account-exists-with-different-credential');
          } finally {
            setShowLoader(false);
          }
}
    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true);
         // const monitorAuthState = async () => {
          //  onAuthStateChanged(auth, user => {
          //      if (user) {
          //      showApp();
          //      } else {
          //       showLoginForm();
          //      }
          //  })
          //}
          //monitorAuthState();
    };
    const showerror = async (error) => {
        if (error.code === 'auth/invalid-login-credentials') {
            setErrorWrongPassword(true)
         }
      };
  

return (<>
<BrandSlider />
        <div style={{ marginTop: '80px'}} id='loginForm'>
        <h1 className='joinText'>Pinchcart&trade;</h1>
        <div className="logincontainer">
        <form onSubmit={handleJoin}>
        <h1 className='createAccount'>Log In</h1>
        <div className="social-container">
				<GoogleIcon onClick={handleGoogleSignIn} className='socialIcons' id='googleIconLogin' />
				<FacebookRoundedIcon onClick={handleFacebookSignIn} className='socialIcons' id='facebookIcon'/>
				<TwitterIcon onClick={handleTwitterSignIn} className='socialIcons' id='twitterIcon'/>
			  </div>
			  <span style={{marginBottom: '10px'}}> or log in with your email.</span>
        <input name='loginEmail' id='loginEmail' value={formData.loginEmail} onChange={handleInputChange} type="email" placeholder="Email" />
		{ errorWrongPassword && (<div className='errorAccount' style={{ fontSize: '18px', fontWeight: '600', color: '#ee4054' }}>
            Wrong Password!</div>)
        }
        <input name='loginPassword' id='loginPassword' onChange={handleInputChangePass} autoComplete="current-password webauthn" type="password" placeholder="Password" />
        <div style={{ fontSize: '12px', display: 'flex', textAlign: 'left', width: '100%' }}><Link to='/forgotpassword'>Forgot Password.</Link></div>
        <button type='submit' id='loginButton' style={{marginBottom: '30px', marginTop: '30px',transition: 'opacity 0.3s ease',}} > {showLoader ? <Loader className="spinner" /> : 'Log in'}</button>
        </form>
        <div>Don't have and account? <Link to='/join'>Join</Link></div>
        </div>
        </div>
        <footer>
	      <p>
        © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	      </p>
        </footer>
        </>
    );
  }

//import firebase from 'firebase/compat/app';
//import 'firebase/compat/firestore';
//import 'firebase/compat/auth';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage'; // Import the getStorage function
import { getFunctions } from 'firebase/functions'; // Import getFunctions function
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  
    authDomain: "pinchcart-a5077.firebaseapp.com",
  
    projectId: "pinchcart-a5077",
  
    storageBucket: "pinchcart-a5077.appspot.com",
  
    messagingSenderId: "516011809928",
  
    appId: "1:516011809928:web:d6a88f4423fd0a404ad33d",
  
    measurementId: "G-9YWXJ97ST9"
};


//firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Firebase Storage
const functions = getFunctions(app); // Initialize Firebase Functions

export { app, db, auth, analytics, storage, functions };
import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import PrivateTheme from './PrivateTheme';
import BrandSlider from './BrandSlider';
function Private() {

  return (
    <>
    <Header />
    <PrivateTheme />
    <BrandSlider />
    <Footer />

    </>
  );
}

export default Private;
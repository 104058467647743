import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import './Join.css';
import { ReactComponent as Loader } from '../loader.svg';
import {useAuth} from "../AuthContext";
import { useNavigate } from "react-router-dom";
import BrandSlider from "./BrandSlider";

export default function ContactUs() {
    //localStorage.removeItem('PID');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
      
    const [showLoader, setShowLoader] = useState(false);
    const handleInputChange = ( e ) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        setShowLoader(false);
    };
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    // You can add your form submission logic here, such as sending the data to a server or displaying it in the console
    console.log('Form submitted:', formData);
    try {
        const docRef = await addDoc(collection(db, 'contactus'), formData);
        console.log('Document written with ID: ', docRef.id);
            // Reset the form after successful submission
        setFormData({
            name: '',
            email: '',
            message: '',
          });
          setSubmitSuccess(true);
    } catch (error) {
        console.error('Error adding document: ', error);
    }
  };
   
  
return (<>
<BrandSlider />
        <div style={{ marginTop: '80px'}} id='loginForm'>
        <h1 className='joinText'>Contact Pinchcart&trade;</h1>
        <div className="logincontainer">
        <form onSubmit={handleSubmit} style={{ width: '700px', margin: '0 auto' }}>
      <div style={{ marginBottom: '16px' }}>
        <label style={{ display: 'block', marginBottom: '8px', marginTop: '30px' }}>Name:</label>
        <input
          style={{width: '400px'}}
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>

      <div style={{ marginBottom: '16px' }}>
        <label style={{ display: 'block', marginBottom: '8px' }}>Email:</label>
        <input
          style={{width: '400px'}}
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      <div style={{ marginBottom: '16px' }}>
        <label style={{ display: 'block', marginBottom: '8px' }}>Message:</label>
        <textarea
          style={{width: '400px', height: '100px'}}
          name="message"
          value={formData.message}
          onChange={handleChange}
          maxLength={1500}
        />
      </div>
      <button type="submit" style={{ backgroundColor: '#ee4054', color: '#fff', padding: '10px 20px', border: 'none', cursor: 'pointer' }}>Submit</button>
    </form>
    {submitSuccess && (
        <div style={{ marginTop: '16px', color: 'green' }}>
          Form submitted successfully!
        </div>
      )}
        </div>
        </div>
        <footer>
	      <p>
        © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	      </p>
        </footer>
        </>
    );
  }

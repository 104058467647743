
import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
 
const Footer = () => {
    return (
        <>
        <Box >
            
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "8px",
                }}
            >
                
            </h1>
            <FooterContainer>
                <Row>
                    <Column>
                        <Heading>Get to Know Us</Heading>
                        <FooterLink href="/about">
                         About Pinchcart
                        </FooterLink>
                        <FooterLink href="/terms">
                         Terms & Conditions
                        </FooterLink>
                        <FooterLink href="/private">
                        Privacy Policy
                        </FooterLink>
                     
                    </Column>
                    <Column>
                        <Heading>Our Technologies</Heading>
                        <FooterLink href="/cashback">
                        Web App
                        </FooterLink>
                        <FooterLink href="">
                        Browser Extentions
                        </FooterLink>

                        <FooterLink href="https://chromewebstore.google.com/detail/pinchcart/gmojmpooieblcilokienbhpmlhabimeh">
                        <br/> - Chrome (Released 2/3/24)
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Reach Out</Heading>
                        <FooterLink href="/contactus">
                        Contact Form
                        </FooterLink>
                        <FooterLink href="/contactus">
                        Help
                        </FooterLink>
                        <FooterLink href="/contactus">
                        Delete Data Request
                        </FooterLink>


                    </Column>
                    <Column>
                        <Heading>Social Media</Heading>
                        <FooterLink href="https://twitter.com/ceopinchcart">
                            <i className="fab fa-twitter">
                                <span
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                   <svg data-ds-type="Icon" width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className="r-o8gllb r-4qtqp9 r-yyyyoo r-mabqd8 r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1yvhtrz"><path d="M27.93 9.88c.02.26.02.53.02.8 0 8.13-6.19 17.51-17.51 17.51-3.34 0-6.62-.95-9.43-2.76a12.38 12.38 0 0 0 9.1-2.55 6.17 6.17 0 0 1-5.75-4.27c.92.18 1.87.14 2.78-.11a6.16 6.16 0 0 1-4.94-6.03v-.08c.86.48 1.81.74 2.79.77a6.16 6.16 0 0 1-1.9-8.22 17.46 17.46 0 0 0 12.68 6.43 6.16 6.16 0 0 1 10.49-5.61c1.38-.27 2.7-.78 3.91-1.49a6.18 6.18 0 0 1-2.71 3.4c1.22-.14 2.41-.47 3.53-.97a12.5 12.5 0 0 1-3.06 3.18z"></path></svg>
                                </span>
                            </i>
                        </FooterLink>
                        <FooterLink href="https://www.instagram.com/pinchcart_pinnacle/">
                            <i className="fab fa-instagram">
                                <span
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    <svg data-ds-type="Icon" width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className="r-o8gllb r-4qtqp9 r-yyyyoo r-mabqd8 r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1yvhtrz"><path d="M29.92 10.23c-.07-1.49-.3-2.51-.65-3.4a7.01 7.01 0 0 0-1.61-2.48 6.746 6.746 0 0 0-2.48-1.61c-.89-.35-1.91-.58-3.4-.65C20.28 2.02 19.8 2 16 2s-4.28.02-5.77.08c-1.49.07-2.51.3-3.4.65-.92.36-1.7.84-2.48 1.61-.78.79-1.26 1.57-1.62 2.49-.35.89-.58 1.91-.65 3.4C2.02 11.72 2 12.2 2 16s.02 4.28.08 5.77c.07 1.49.3 2.51.65 3.4.36.92.84 1.7 1.61 2.48.78.78 1.56 1.26 2.48 1.61.89.35 1.91.58 3.4.65 1.5.07 1.98.09 5.78.09s4.28-.02 5.77-.08c1.49-.07 2.51-.3 3.4-.65.92-.36 1.7-.84 2.48-1.61.78-.78 1.26-1.56 1.61-2.48.35-.89.58-1.91.65-3.4.07-1.5.09-1.98.09-5.78s-.02-4.28-.08-5.77zM27.4 21.66c-.06 1.36-.29 2.11-.48 2.6-.25.65-.56 1.12-1.05 1.61s-.96.79-1.61 1.05c-.49.19-1.23.42-2.6.48-1.48.07-1.92.08-5.66.08s-4.18-.01-5.66-.08c-1.36-.06-2.11-.29-2.6-.48-.65-.25-1.12-.56-1.61-1.05s-.79-.96-1.05-1.61c-.19-.49-.42-1.23-.48-2.6-.06-1.48-.08-1.92-.08-5.66s.01-4.18.08-5.66c.06-1.36.29-2.11.48-2.6.25-.65.56-1.12 1.05-1.61s.96-.79 1.61-1.05c.49-.19 1.23-.42 2.6-.48 1.48-.07 1.92-.08 5.66-.08s4.18.01 5.66.08c1.36.06 2.11.29 2.6.48.65.25 1.12.56 1.61 1.05s.79.96 1.05 1.61c.19.49.42 1.23.48 2.6.07 1.48.08 1.92.08 5.66s-.02 4.18-.08 5.66zM16 8.81a7.191 7.191 0 0 0 0 14.38 7.191 7.191 0 0 0 0-14.38zm0 11.86c-2.58 0-4.67-2.09-4.67-4.67s2.09-4.67 4.67-4.67 4.67 2.09 4.67 4.67-2.09 4.67-4.67 4.67zm9.15-12.14c0 .93-.75 1.68-1.68 1.68-.93 0-1.68-.75-1.68-1.68 0-.93.75-1.68 1.68-1.68.93 0 1.68.75 1.68 1.68z"></path></svg>
                                </span>
                            </i>
                        </FooterLink>
                        <FooterLink href="#">
                            <i className="fab fa-facebook-f">
                                <span
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    
                                </span>
                            </i>
                        </FooterLink>
                        <FooterLink href="">
                            <i className="fab fa-youtube">
                                <span
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    
                                </span>
                            </i>
                        </FooterLink>
                    </Column>
                </Row>
            </FooterContainer>
        </Box>
      
            <p style={{margin: "0px",width: "100%",backgroundColor: "#ee4054", color: "white", paddingBottom: "50px", paddingTop: "40px"}}>
            © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates. * limited to qualified sales and prejoin members only
            </p>
       
        </>
    );
};
export default Footer;
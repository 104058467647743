import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import {db} from '../firebase';
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import './Join.css';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Link } from 'react-router-dom';
import { ReactComponent as Loader } from '../loader.svg';
import { useLocation } from "../LocationProvider";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import BrandSlider from "./BrandSlider";


//const errorMessage = document.getElementById("lblErrorMessage");
//const errorMessageNoDoubleAccounts = document.getElementById("lblErrorMessageNoDoubleAccounts");


const initialState = {
    joinName: '',
    joinEmail: '',
    joinPassword: '',
    loginEmail: '',
    loginPassword: ''
}
  export default function Join() {

    const [signUpMethod, setSignUpMethod] = useState('');
    const errorMessageRef = useRef(null);
    const errorMessageNoDoubleAccountsRef = useRef(null);
    const navigate = useNavigate();
    const { twitterSignIn, facebookSignIn, googleSignIn, user, logOut } = useAuth();
    
    
    
    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
            setSignUpMethod('Google');
        } catch (error){
            //console.log(error)
            //showerror(error);

        }
    }

    const handleFacebookSignIn = async () => {
        try {
            await facebookSignIn();
            setSignUpMethod('Facebook');
        } catch (error){
            //console.log(error)
            //showerror(error);

        }
    }

    const handleTwitterSignIn = async () => {
        try {
            await twitterSignIn();
            setSignUpMethod('Twitter');

        } catch (error){
            //console.log(error)
            //showerror(error);
            //console.log('auth/account-exists-with-different-credential')
            //showerror(error);

            
        }
    }



    const showerror = async (error) => {
      if (error.code === 'auth/email-already-in-use') {
        errorMessageRef.current.style.display = 'block';
        errorMessageNoDoubleAccountsRef.current.style.display = 'none';
      } else if (error.code === 'auth/account-exists-with-different-credential') {
        //console.log('error code', error.code)
        errorMessageNoDoubleAccountsRef.current.style.display = 'block';
        console.error('User not found or multiple users with the same email.');
        } else {
            if (errorMessageRef.current == 'null' || errorMessageRef.current == 'undefined')
            try {
             errorMessageRef.current.style.display = 'none';
             errorMessageNoDoubleAccountsRef.current.style.display = 'none'; 
             } catch(error) {
            errorMessageRef.current.style.display = 'none';
            errorMessageNoDoubleAccountsRef.current.style.display = 'none';
             }
          }
    };



  //useEffect(() => {
  //  const userAgent = window.navigator.userAgent;
  //  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

  //  if (isMobile) {
      // Redirect to the mobile message page
  //    window.location.replace('/mobilemessage');
  //  }
 // }, []);






      useEffect(() => {
        const handleAuthStateChanged = async (authUser) => {
          if (authUser) {
            const email = authUser.email;
            // Check if the user already exists in the database
            const getUserData = collection(db, 'Users');
            const querySnapshot = await getDocs(query(getUserData, where('Email', '==', email)));
    
            if (querySnapshot.size === 1) {
              // User exists, retrieve and update data
              const docData = querySnapshot.docs[0].data();
              const { PID, id, signUpMethod } = docData;
              console.log('User UID', authUser.uid);
              console.log('User Email', email);
              console.log('PID:', PID);
              console.log('ID:', id);
              console.log('signUpMethod:', signUpMethod);
              localStorage.setItem('PID', PID);
              localStorage.setItem('newUser', 1);
            } try {
              // New user, add data to the database
              const initialCashAmount = (10.00).toFixed(2); // Ensure it's treated as a floating-point number

              const serviceCashApp = 'Cash App';
              const cashBackID = generateCashBackID(authUser.uid);
              const EmailValid = 0;
              const docData = {
                Name: authUser.displayName,
                Email: email,
                DateTime: new Date(),
                Ipaddress: details.IPv4,
                City: details.city,
                Country_Code: details.country_code,
                Country_Name: details.country_name,
                Latitude: details.latitude,
                Longitude: details.longitude,
                State: details.state,
                Postal_Code: details.postal, 
                SignUpMeathod: signUpMethod,
                CashAmount: initialCashAmount,
                PaymentMethod: serviceCashApp,
                EmailValid: EmailValid,

                // Add other user data as needed
              };
    
              const postNewUser = collection(db, 'Users');
              const docRef = await updateDoc(postNewUser, docData);
              const newDocumentId = docRef.id;
              const documentToUpdate = doc(db, 'Users', newDocumentId);
              await updateDoc(documentToUpdate, { id: newDocumentId, PID: cashBackID });
              console.log('User UID', authUser.uid);
              console.log('User Email', email);
              console.log('Database ID', newDocumentId);
              console.log('Cash Back ID:', cashBackID);
            }
            catch (error){
              showerror(error);
            console.error('auth/account-exists-with-different-credential');

            }
          } else {
            //console.error('Google sign-in failed. User credential not available.');

          }
        };
    
        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    
        return () => {
          unsubscribe(); // Cleanup the listener when the component unmounts
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts
    
    useEffect(( ) => {
        // Run this code when the component mounts
            if(user != null){
              navigate('/account')
            }
      }, [user]); // The empty dependency array means this effect runs once when the component mounts
  
    const generateCashBackID = (uid) => {
        const uidPrefix = uid.slice(0,2).toUpperCase();
        const randomDigits = Math.floor(1000 + Math.random() * 9000);
        const cashBackID = uidPrefix + randomDigits;
        return cashBackID;
    };
    const { details } = useLocation();
    const [formData, setFormData] = useState(initialState);
    const [showLoader, setShowLoader] = useState(false);
    const isFormValid = () => {
        return formData.joinName.trim() !== '' &&
               formData.joinEmail.trim() !== '' &&
               formData.joinPassword.length >= 6;
      };
      
    const handleInputChange = ( e ) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
    };

    const txtEmail = document.querySelector('#loginEmail');
    const txtPassword = document.querySelector('#loginPassword')

    const handleJoin = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        const loginEmail = txtEmail.value; 
        const loginPassword = txtPassword.value; 

        try
        {
            const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            const { uid, email } = userCredential.user;
            const getUserData = collection(db, 'Users');
            const querySnapshot = await getDocs(query(getUserData, where('Email', '==', loginEmail)));
            setSignUpMethod('Email');
            if (querySnapshot.size === 1) {
            // Assuming there is only one document with the given email
            const docData = querySnapshot.docs[0].data();
            const { PID, id } = docData;
            console.log('User UID', uid);
            console.log('User Email', email);
            console.log('PID:', PID);
            console.log('ID:', id);
            } else {
            // Handle the case where there are multiple or no documents with the given email
            console.error('User not found or multiple users with the same email.');
            }

          } catch (error) {
            //console.error('Error signing in:', error);
           /// showerror(error);

          } finally {
            setShowLoader(false);
          }
};

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            // Display an error message or handle invalid form submission
            return;
          }
          setShowLoader(true);

          const serviceCashApp = 'Cash App';
          const initialCashAmount = (10.00).toFixed(2); // Ensure it's treated as a floating-point number
          const EmailValid = 0;
          const docData = {
              Name: formData.joinName,
              Email: formData.joinEmail,
              DateTime: new Date(),
              Ipaddress: details.IPv4,
              City: details.city,
              Country_Code: details.country_code,
              Country_Name: details.country_name,
              Latitude: details.latitude,
              Longitude: details.longitude,
              State: details.state,
              Postal_Code: details.postal, 
              SignUpMethod: 'Email',
              CashAmount: initialCashAmount, 
              PaymentMethod: serviceCashApp,
              EmailValid: EmailValid,

          }
        try{
            const userCredential = await createUserWithEmailAndPassword(auth, formData.joinEmail, formData.joinPassword);
            const { uid, email } = userCredential.user;
            const cashBackID = generateCashBackID(uid);
            const postNewUser = collection(db, 'Users'); 
            const docRef = await addDoc(postNewUser, docData);
            const newDocumentId = docRef.id;  
            const documentToUpdate = doc(db, 'Users', newDocumentId);
            setSignUpMethod('Email');
            await updateDoc(documentToUpdate, { id: newDocumentId, PID: cashBackID });
            localStorage.setItem('PID', cashBackID);
            localStorage.setItem('CashAmount', initialCashAmount);
            console.log('User UID',uid)
            console.log('User Email',email)
            console.log('Database ID',newDocumentId)
            console.log('Cash Back ID:', cashBackID);
           // chrome.runtime.sendMessage({ type: 'userJoined', cashbackID: cashBackID });
          } catch (error) {
            console.log(error);
            console.log('Donkey Duck butter');

            //console.log('Twitter sign-in error:', error)

            showerror(error);
  
          } finally {
            setShowLoader(false);
          }
        
          //sign in with google function
    };
    return (<>
    <BrandSlider />
        <div style={{ marginTop: '80px'}} id='joinForm' className='joinForm'>

        <h1 className='joinText'>Create a Pinchcart&trade; account so we make sure you get Cash Back on your purchases.</h1>
        <div className="signincontainer">
			<h1 className='createAccount'>Join using</h1>
			<div className="social-container">
				<GoogleIcon onClick={handleGoogleSignIn} className='socialIcons' id='googleIconJoin' />
				<FacebookRoundedIcon onClick={handleFacebookSignIn} className='socialIcons' id='facebookIcon'/>
				<TwitterIcon onClick={handleTwitterSignIn} className='socialIcons' id='twitterIcon'/>
			</div>
            <form onSubmit={handleSubmit}>
			<span style={{marginBottom: '10px'}}> or use your email for registration</span>
			<input name='joinName' value={formData.joinName} onChange={handleInputChange} type="text" placeholder="Name" />
			<input name='joinEmail'  value={formData.joinEmail} onChange={handleInputChange} type="email" placeholder="Email" />
			<input name='joinPassword'  value={formData.joinPassword} onChange={handleInputChange} autoComplete="current-password webauthn" type="password" placeholder="Password ( Min 6 Characters )" />
            <div id='divLoginError'>
                <div ref={errorMessageRef} id='lblErrorMessage' className='errorLabel'>Email already in use. Go log in. <Link to='/login' style={{color: '#000'}}>Click me</Link></div>
                <div ref={errorMessageNoDoubleAccountsRef} id='lblErrorMessageNoDoubleAccounts' className='errorLabel'>User Email already exist. Login the same way you joined.<Link to='/login' style={{color: '#000'}}> Click me</Link></div>
            </div>
            <button type='submit' id='joinButton' style={{marginBottom: '30px', marginTop: '30px', opacity: isFormValid() ? '1' : '0.6',pointerEvents: isFormValid() ? 'auto' : 'none',transition: 'opacity 0.3s ease',}} disabled={!isFormValid()} > {showLoader ? <Loader className="spinner" /> : 'Join'}</button>
            <div>Already have an account? <Link to='/login'>Sign In</Link></div>
            </form>
            <span>By joining you agree to our <Link style={{fontSize: '12px', color: '#ee4054'}}  to='../terms'>Terms & Conditions</Link> and <Link style={{fontSize: '12px', color: '#ee4054'}}  to='../private'>Privicy Policy</Link></span>
        </div>
        </div>
      
        <footer>
	        <p>
            © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	        </p>
        </footer>
        </>
    );
  }

import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Breadcrum from './Breadcrum';
import { useLocation } from 'react-router-dom';
import './Goods.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Footer from './Footer';
import { db } from '../firebase';
import { collection, where, query, getDocs } from 'firebase/firestore'; // Import collection and addDoc functions
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PetSupplies from './Sliders/goods/homegaden/PetSupplies';
import GardenAndOutdoor from './Sliders/goods/homegaden/GardenAndOutdoor';
import Tools from './Sliders/goods/homegaden/Tools';
import Discover from './Sliders/goods/homegaden/Discover';
import Decor from './Sliders/goods/homegaden/Decor';
import Furniture from './Sliders/goods/homegaden/Furniture';
import Kitchen from './Sliders/goods/homegaden/Kitchen';
import BedBath from './Sliders/goods/homegaden/BedBath';
import Lighting from './Sliders/goods/homegaden/Lighting';
import Appliances from './Sliders/goods/homegaden/Appliances';
import WatchesJewelry from './Sliders/goods/ClothingShoes/WatchesJewelry';
import Fashion from './Sliders/goods/ClothingShoes/Fashion';


import { Link } from 'react-router-dom';

function Goods(details) { 

  const CategoryLocation = useLocation();
  const CategoryParts = CategoryLocation.pathname.split('/');
  const OnCategory = CategoryParts[2];
  const listRef = useRef(null);


const maxListHeight = 900; // Adjust this value to your preferred maximum 
   let initialCategories = [
    {
      name: 'Health & Beauty',
      open: false,
      subcategories: ['Aromatherapy', 'Bath & Body', 'Cosmetics', 'Fragrance', 'Hair Care', 'Massage','Men\'s','Oral Care','Personal Care'],
    },
    {
      name: 'Clothing & Shoes',
      open: false,
      subcategories: ['Watches & Jewelry', 'Fashion', 'Women','Men','Girls','Boys','Baby','Luggage','Luxury'],
    },
    {
      name: 'Home & Garden',
      open: false,
      subcategories: ['Tools', 'Discover', 'Decor','Furniture','Kitchen & Dining','Bed & Bath','Garden & Outdoor','Lighting','Appliances','Pet Supplies'],
    },
    {
      name: 'Electronics',
      open: false,
      subcategories: ['Computers', 'TV & Video', 'Home Audio & Theater','Camera Phone & Video', 'Cell Phones', 'Accessories','Headphones','Games','Car Electronics','Musical','Wearables'],
    },
    {
      name: 'More',
      open: false,
      subcategories: ['Sports', 'Outdoors', 'Automotive','Food & Grocery', 'Toys Kids & Baby', 'Industrial','Books', 'Software'],
    }
  ];
  

    //initialCategories = [
      // {
       //  name: 'Dogs',
       //  open: false,
       //  subcategories: ['Health', 'Supplies', 'Toys', 'Services'],

      // },
      // {
       //  name: 'Cats',
       //  open: false,
       //  subcategories: ['Health', 'Supplies', 'Toys', 'Services'],

       //},
      // {
       //  name: 'Fish & Aquatic Pets',
       //  open: false,
       //  subcategories: ['Health', 'Supplies', 'Toys', 'Services'],

       //},
      // {
       //  name: 'Bird',
       //  open: false,
       //  subcategories: ['Health', 'Supplies', 'Toys', 'Services'],

       //},
      // {
        // name: 'Horses',
        // open: false,
        // subcategories: ['Health', 'Supplies', 'Riding', 'Services'],

       //}
    // ];
   
   
  
    const [categories, setCategories] = useState(initialCategories);
    const [clickedButtons, setClickedButtons] = useState(initialCategories.map(() => false));
   
    const toggleCategory = (index) => {
      const updatedCategories = [...categories];
      const categoryToClose = updatedCategories[index]; // Store the category being closed
      updatedCategories[index].open = !updatedCategories[index].open;


   
      const isClicked = clickedButtons[index] || false;
      setClickedButtons((prevClickedButtons) => {
        prevClickedButtons[index] = !isClicked;
        return [...prevClickedButtons];
      });
  
      updatedCategories.forEach((category, idx) => {
        category.className = clickedButtons[idx] ? 'true' : 'false';
      });
  
      const totalOpenHeight = updatedCategories
      .filter((category) => category.open)
      .reduce((total, category) => total + 800 /* Adjust this value based on your design */, 0);
  

      if (totalOpenHeight > '3200') {
       window.scrollTo(0, 0);
      }


      setCategories(updatedCategories);
    };
  

    function arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
    
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
    
      return true;
    }
    
    useEffect(() => {
      const list = listRef.current;
      if (list) {
        const listHeight = list.scrollHeight;
        if (listHeight > maxListHeight) {
          // Automatically close categories one by one from the top down
          let totalHeight = 1000;
          const updatedCategories = categories.map((category) => {
            const categoryHeight = 350; // Adjust this value based on your design
            if (totalHeight + categoryHeight > maxListHeight) {
              if (category.open) { // Only update if it's open
                return {
                  ...category,
                  open: false,
                };
              }
            }
            totalHeight += categoryHeight;
            return category;
          });
          if (!arraysAreEqual(updatedCategories, categories)) {
            setCategories(updatedCategories);
          }
        }
      }
    }, [categories]);
    
    function formatSubCategory(subCategory) {
      // Convert to lowercase
      const lowerCaseSubCategory = subCategory.toLowerCase();
      
      // Replace spaces with hyphens and '&' with 'and'
      const formattedSubCategory = lowerCaseSubCategory
        .replace(/ /g, '-') // Replace spaces with hyphens
        .replace(/&/g, 'and'); // Replace '&' with 'and'
      
      return formattedSubCategory;
    }

  const location = useLocation();
  const getCategory = location.pathname.split('/').pop();
  const selectedCategory = location.state?.selectedCategory; // Use the selected category or a default value
  const selectedState = location.state?.selectedState; // Use the selected category or a default value
  const selectedCity = location.state?.selectedCity; // Use the selected category or a default value
  const [data, setData] = useState([]); // State to hold the fetched data
  const page = 'goods';
  const pullcategory = 'Goods';
  


  return (
    <>
    <Header />
    <Breadcrum page={page} category={selectedCategory} state={selectedState} city={selectedCity}/>
    <div className='box'>
      <div className='leftgoods'>
      <div className="local-container">
      <div className="categories" ref={listRef}>
        <ul>
          {categories.map((category, index) => (
            <li key={index}>
            <button id={`category-button-${index}`} onClick={() => toggleCategory(index)} className={`categories-button ${category.open ? 'open' : ''} ${clickedButtons[index] ? 'true' : 'false'} ${index === 1 && clickedButtons[1] && clickedButtons[2] ? 'border-top' : ''}`}  >{category.name} {category.open ? <span className="removeicon"><RemoveIcon /></span> : <span className="addicon"><AddIcon /></span>}</button> 
           
              <ul className={`subcategories ${category.open ? 'open' : ''}`}>
                {category.subcategories.map((subCategory, subIndex) => (
                  <li key={subIndex}>
                  <Link to={`/goods/${formatSubCategory(subCategory)}`}>{subCategory}</Link>
                   </li>
                    ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
        </div>
      <div className='right'>

      {!OnCategory &&  <WatchesJewelry data className='petsupplies' />}
      {!OnCategory &&  <Fashion data className='petsupplies' />}
      {!OnCategory &&  <Tools data className='petsupplies' />}
      {!OnCategory &&  <Discover data className='petsupplies' />}
      {!OnCategory &&  <Decor data className='petsupplies' />}
      {!OnCategory &&  <Furniture data className='petsupplies' />}
      {!OnCategory &&  <Kitchen data className='petsupplies' />}
      {!OnCategory &&  <BedBath data className='petsupplies' />}
      {!OnCategory &&  <GardenAndOutdoor data className='petsupplies' />}
      {!OnCategory &&  <Lighting data className='petsupplies' />}
      {!OnCategory &&  <Appliances data className='petsupplies' />}
      {!OnCategory &&  <PetSupplies data className='petsupplies' />}






      {OnCategory === 'watches-&-jewelry' &&  <WatchesJewelry data className='petsupplies' />}
      {OnCategory === 'watches-and-jewelry' &&  <WatchesJewelry data className='petsupplies' />}
      {OnCategory === 'fashion' &&  <Fashion data className='petsupplies' />}


       {OnCategory === 'pet-supplies' &&  <PetSupplies data className='petsupplies' />}
       {OnCategory === 'garden-&-outdoor' &&  <GardenAndOutdoor data className='petsupplies' />}
       {OnCategory === 'garden-and-outdoor' &&  <GardenAndOutdoor data className='petsupplies' />}
       {OnCategory === 'tools' &&  <Tools data className='petsupplies' />}
       {OnCategory === 'discover' &&  <Discover data className='petsupplies' />}
       {OnCategory === 'decor' &&  <Decor data className='petsupplies' />}
       {OnCategory === 'furniture' &&  <Furniture data className='petsupplies' />}
       {OnCategory === 'kitchen-&-dining' &&  <Kitchen data className='petsupplies' />}
       {OnCategory === 'kitchen-and-dining'&&   <Kitchen data className='petsupplies' />}
       {OnCategory === 'bed-and-bath'&&   <BedBath data className='petsupplies' />}
       {OnCategory === 'bed-&-bath' &&  <BedBath data className='petsupplies' />}
       {OnCategory === 'lighting' &&  <Lighting data className='petsupplies' />}
       {OnCategory === 'appliances' &&  <Appliances data className='petsupplies' />}


          </div>
         
    </div>
    </>
    
                
 );
}

export default Goods;

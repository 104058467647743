import React, { useState, useEffect } from 'react';
import './components/Join.css';


  export default function NotFound() {
  

    return (<>

        <div style={{ marginBottom: '50px',marginTop: "40px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <div style={{ width: '500px', display: 'inline-flex',alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'  }}>
        <h1 className='appText'>Pinchcart™</h1><h1  style={{fontSize: '60px', color: '#ee4054'}}>404 Error</h1>

        </div>
        <div className="appcontainer">
        <div className="profileContainerTopCards">
           
          
        
            <h3 style={{ marginBottom: "10px" }} >Um. This Page does not exist.</h3><h3 style={{ fontSize: "25px",color: "#ee4054" }}></h3>

           
           
        </div>

        
        
        </div>

        </div>        
        <footer>
	        <p>
            © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	        </p>
        </footer>
        </>
    );
  }

import React, { useState, useEffect } from 'react';
import './PopupEmail.css'; // Create a CSS file for styling
import {db} from './firebase';
import { collection, addDoc } from 'firebase/firestore'; // Import collection and addDoc functions
import { ReactComponent as Loader } from './loader.svg';

function Popup({details}) {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

 
  const closePopup = () => {
    setIsOpen(false);
//set cookie
  };



  const Button = ({onSubmit, text, loading, disabled}) => {
   
    const handleClick = async () => {
      try {
        if (!loading && !submitted) {
          onSubmit();
        } else {
          closePopup();
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <button className="buttonCSS" onClick={handleClick} disabled={disabled}>
      {!loading ? (submitted ? "Close" : text) : <Loader className="spinner" />}
    </button>
    )

  }
 
   

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  const handleSubmit = async() => {
    if (email) {
      setShowLoader(true); // Set loading state to true
      try{
        const newsletterCollection = collection(db, 'newsletterEmail');

        const docData = {
          Email: email,
          DateTime: new Date(),
          IpAddress: details ? details.IPv4 : null,
          State: details ? details.state : null,
          City: details ? details.city : null,
          Postal: details ? details.postal : null,
          County: details ? details.country_name : null,
          Latitude: details ? details.latitude : null,
          Logitude: details ? details.longitude : null,
        };

        await addDoc(newsletterCollection, docData)
        console.log("You Joined the Pinchcart Email List: New user: Enjoy $50-$8 With Coupon DH8NEWOctober at DHgate.com USE LINK: https://www.tkqlhce.com/click-9328366-15625742");
        localStorage.setItem('hasJoined', 'true');

        
        setSubmitted(true);
        
        console.log('setSubmitted:', setSubmitted);
      } catch (error)
      {
        console.log("There was an error talking to the database");
      } finally {
        setShowLoader(false);
      }

    }
    // Handle the form submission here
    //console.log('Email submitted:', email);
    // You can send the email data to your backend or perform any other necessary action.
  };


  useEffect(() => {
try{
    // Check for a cookie to determine if the user has joined
    const hasJoined = localStorage.getItem('hasJoined');
        if (!hasJoined !== true) {
//console.log('hasJoined:', hasJoined);
    // If the user hasn't joined, show the popup
      setIsOpen(false);
      setSubmitted(true);
    }
  } catch (error) {
    console.error (error)
  }
    // Open the popup after 2 seconds
    const popupTimer = setTimeout(() => {
      const logged = localStorage.getItem('hasJoined');
      if (logged !== 'true'){
      setIsOpen(true)
      }
    }, 3000);

    // Clear the timer on unmount to avoid memory leaks
    return () => {
      clearTimeout(popupTimer);
    };

    
  }, []); // The empty dependency array ensures this effect runs only once

 


  return (
    <div className="popup">
      {isOpen && (
        <div className="popup-content">
             <span className="close-icon" onClick={togglePopup}>
            &#x2716; {/* Unicode "X" character */}
          </span>
         {/**<h1 className='emailCard-h1'>Sign up. Save <b>BIG</b>.</h1><h1 className='pinchcart'>PinchCart</h1>*/}
          <h5>Get Weekly eCoupons, Promotions, Discounts and Deals on things you love. </h5>
          <div className='input-button-container'>
          <input className='emailInput'
          required
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
          <div className='button-container'>

          <Button text='Join' loading={showLoader} onSubmit={handleSubmit} disabled={showLoader} submitted={submitted}>
           
          </Button>
          </div>
          </div>
          <h6 className='popupfooter'>
See <a href='/terms'>Terms of Service</a>. Local and onine category deals. By subscribing, I agree to the Terms of Use and have read the <a href='./privacy'>Privacy Statement</a>.
</h6>
        </div>
      )}
            {isOpen && <div className="overlay" onClick={togglePopup} />}
    </div>
  );
}

export default Popup;
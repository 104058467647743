import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "../LocationProvider";
import './Landing.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import logo from './Pinchcart.jpg';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import { auth, db } from '../firebase';
import { useAuth } from "../AuthContext";
import { ReactComponent as Loader } from '../loader.svg';
import Landflyone from './landflyone.png';
import Landflytwo from './landflytwo.png';
import Landflythree from './landflythree.png';
import masscot from './mascot-trim.png';
import Webstore from './webstore1.png';
import Wish from './wish-removebg.png';
import Duracell from './duracell-removebg.png';
import Swagbucks from './swagbucks.png';
import Slider from './BrandSlider';
import UpArrow from './arrow-up-grey16.png';
import LandingHeroThree from './landingherothree.png';
import { Link } from 'react-router-dom';
import PasswordCross from './password-cross.svg';
import PasswordCheck from './password-check.svg';
import Eye from './eye.svg';
import { useNavigate } from "react-router-dom";
import { functions } from '../firebase'; // Assuming you have your firebase.js file in the same directory

function Landing() {
  const { user } = useAuth();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [validationCode, setValidationCode] = useState('');
  const errorEmailInUseRef = useRef(null)
  const [isInputSelected, setIsInputSelected] = useState(false);
  const [joinEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasUpperAndLowerCase, setHasUpperAndLowerCase] = useState(false);  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const initialState = {
    joinEmail: '',
    joinPassword: ''
}
const [formData, setFormData] = useState(initialState);

const handleEmailChange = (event) => {
  const newEmail = event.target.value;
  setEmail(newEmail);
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
  setIsEmailValid(isValidEmail);
  const { name, value } = event.target;
  const updatedFormData = { ...formData, [name]: name === 'joinEmail' ? value.toLowerCase() : value };
  setFormData(updatedFormData);
};




const handlePasswordChange = (event) => {
  const newPassword = event.target.value;
  setPassword(newPassword);
  // Check if password meets criteria
  setHasUpperAndLowerCase(/[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword));
  setIsLengthValid(newPassword.length >= 8);
  setHasNumber(/\d/.test(newPassword));
  setHasSymbol(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newPassword));
  const { name, value } = event.target;
  const updatedFormData = { ...formData, [name]: value };
  setFormData(updatedFormData);

};

  const isButtonDisabled = !isEmailValid || !hasUpperAndLowerCase || !isLengthValid || !hasNumber || !hasSymbol;
 
  const handleInputFocus = () => {
    setIsInputSelected(true);
  };

  const handleInputBlur = () => {
    setIsInputSelected(false);
  };


  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

    const [currentSlide, setCurrentSlide] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [expandedtwo, setExpandedtwo] = useState(false);
    const [expandedthree, setExpandedthree] = useState(false);
    const [expandedfour, setExpandedfour] = useState(false);
    const [expandedfive, setExpandedfive] = useState(false);

    // Function to handle dot clicks
        const handleDotClick = (index) => {
            setCurrentSlide(index);
        };
        const toggleExpand = () => {
            setExpanded(!expanded);
        };
        const toggleExpandtwo = () => {
            setExpandedtwo(!expandedtwo);
        };
        const toggleExpandthree = () => {
            setExpandedthree(!expandedthree);
        };
        const toggleExpandfour = () => {
            setExpandedfour(!expandedfour);
        };
        const toggleExpandfive = () => {
            setExpandedfive(!expandedfive);
        };
    const slideText = [
        {
            title: 'Quick and easy',
            subtitle: 'So you can get on with your shopping.'
        },
        {
            title: 'It all adds up',
            subtitle: 'Members earn on average $400+ back a year.'
        },
        {
            title: 'Pay out using Cash App',
            subtitle: 'Get your money directly to your Cash App account.'
        }
    ];
    const { details } = useLocation();
    const generateCashBackID = (uid) => {
      const uidPrefix = uid.slice(0,2).toUpperCase();
      const randomDigits = Math.floor(1000 + Math.random() * 9000);
      const cashBackID = uidPrefix + randomDigits;
      return cashBackID;
  };

  useEffect(( ) => {
    // Run this code when the component mounts
        if(user != null){
          navigate('/account')
        }
  }, [user]); // The empty dependency array means this effect runs once when the component mounts


    const showerror = async (error) => {
      if (error.code === 'auth/email-already-in-use'){
        errorEmailInUseRef.current.style.display = 'block';
      }
      else {
        if (errorEmailInUseRef.current == 'null' || errorEmailInUseRef.current == 'undefined')
        try {
          errorEmailInUseRef.current.style.display = 'none';
         } catch(error) {
        errorEmailInUseRef.current.style.display = 'none';
         }
      }
    };



    const handleSubmit = async ( e ) => {
      e.preventDefault();
     // const generateValidationCode = () => {
     //   return Math.floor(100000 + Math.random() * 900000);
     // };
     // const validationCode = generateValidationCode(); // Generate validation code here
      const serviceCashApp = 'Cash App';
      const initialCashAmount = (10.00).toFixed(2);
      const EmailValid = 0;
      setShowLoader(true);
      const docData = {
        Email: formData.joinEmail,
        DateTime: new Date(),
        Ipaddress: details.IPv4,
        City: details.city,
        Country_Code: details.country_code,
        Country_Name: details.country_name,
        Latitude: details.latitude,
        Longitude: details.longitude,
        State: details.state,
        Postal_Code: details.postal, 
        SignUpMethod: 'Email',
        CashAmount: initialCashAmount, 
        PaymentMethod: serviceCashApp,
        EmailValid: EmailValid,
        

    }
    try{
      const userCredential = await createUserWithEmailAndPassword(auth, formData.joinEmail, formData.joinPassword);
      const { uid, email } = userCredential.user;
      const cashBackID = generateCashBackID(uid);
      const postNewUser = collection(db, 'Users'); 
      const docRef = await addDoc(postNewUser, docData);
      const newDocumentId = docRef.id;  
      const documentToUpdate = doc(db, 'Users', newDocumentId);
      //setSignUpMethod('Email');
      await updateDoc(documentToUpdate, { id: newDocumentId, PID: cashBackID });
      localStorage.setItem('PID', cashBackID);
      localStorage.setItem('CashAmount', initialCashAmount);
      console.log('User UID',uid)
      console.log('User Email',email)
      console.log('Database ID',newDocumentId)
      console.log('Cash Back ID:', cashBackID);

      const sendValidationCode = functions().httpsCallable('sendValidationCode');
      const result = await sendValidationCode({ email: formData.joinEmail });
    } catch (error) {
      console.log(error);
      console.log('Donkey Duck butter');  
      showerror(error);

    } finally {
      setShowLoader(false);
    }
  
    }
  

    
return (
        <>
        <header className="landingheader">
        <img src={logo} className="logo" alt='pinchcart logo'/>
        <Link to="/login"> <button style={{fontWeight: "600"}} className="landinglogin" >Sign In</button> </Link>
        </header>
        <div className="landing-slice">
            <div className="landing-wrapper">
                <div className="landing-text-and-form">
                    <div className="landing-text">
                        <h1 className="landing-text-h1">Get money back when you shop online</h1>
                        <p className="desktop-text">New members get a $10 bonus when they spend $50 or more</p>
                        <p className="mobile-desktop-text">Save hundreds of dollars each year from countless of top online retailers.</p>
                        <img className="masscot" src={masscot} />
                    </div>
                    <div className="landing-form-wrap">
                        <div className="landing-form">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                            <label className="label-email" htmlFor="email">Email</label> 
                            <input name="joinEmail" type="text" value={formData.joinEmail} onChange={handleEmailChange} onFocus={handleInputFocus} onBlur={handleInputBlur}/> 
                            </div>
                            <div ref={errorEmailInUseRef} id='errorEmail' className='errorEmail'>Email already used. <Link to='/login' style={{color: '#000'}}>Log In</Link> </div>
                            <div className="form-group-two">
                            <label className="label-name" htmlFor="password">Choose a password</label>
                              <input onFocus={handleInputFocus} onBlur={handleInputBlur} onChange={handlePasswordChange} value={formData.joinPassword} className="password" style={{width: '290px', marginTop: '40px'}}  type={isPasswordVisible ? 'text' : 'password'}  id="password" name="joinPassword" /> 
                              <img className="eye-icon" 
                                src={Eye} 
                                alt="Toggle Password Visibility" 
                                onClick={togglePasswordVisibility} 
                              />
                                {isInputSelected && (
                                  <div className="belowInputDiv">
                                    {/* Contents of the div to show below the input */}
                                   <div> <img className="passwordcross" width="8px" src={isEmailValid ? PasswordCheck : PasswordCross}></img>  Valid Email</div>
                                   <div> <img className="passwordcross" width="8px" src={hasUpperAndLowerCase ? PasswordCheck : PasswordCross}></img>  Upper and lowercase letters</div>
                                   <div> <img className="passwordcross" width="8px" src={isLengthValid ? PasswordCheck : PasswordCross}></img>  At least 8 characters</div>
                                   <div> <img className="passwordcross" width="8px" src={hasNumber ? PasswordCheck : PasswordCross}></img>  At least one number</div>
                                   <div> <img className="passwordcross" width="8px" src={hasSymbol ? PasswordCheck : PasswordCross}></img>  At least one symbol</div>

                                  </div>
                                  
                                )}
                            </div>
                            <button className="joinfree" disabled={isButtonDisabled}>{showLoader ? <Loader className="spinner" /> : 'Join Free'}</button>
                            <div style={{fontSize: '12px', color: 'GrayText', marginTop: '40px', marginBottom: '20px'}}>By joining you agree to our <a href="https://pinchcart.com/terms">terms & conditions</a> and <a href="https://pinchcart.com/private">privacy policy</a>.</div>
                        </form>
                        </div>
                        <div className="underform">
                            <div className="topbrands">Top Brands</div>
                            <div className="wishdiv"><img className="wishimg" src={Wish} /></div>
                            <div className="duracelldiv"><img style={{width: '155px', height: '70px', marginTop: '20px'}} src={Duracell} /></div>
                            <div className="swagdiv"><img style={{width: '140px', height: '30px', marginTop: '20px'}} src={Swagbucks} /></div>
                            <div className="webstorediv"><a href="https://bit.ly/pinchcart4chrome"><img style={{width: '200px', height: '60px'}} src={Webstore} /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        <Slider /> 
        <div className="landing-slice-two">
            <h1 className="quicktext">Cash back is quick, rewarding and flexible</h1>
            <div className="flydiv" >
                <img src={currentSlide === 0 ? Landflyone : currentSlide === 1 ? Landflytwo : Landflythree} alt="Slider" className="landflyone" />
            </div>
            <div className="non-flydiv" >
                <img src={Landflyone} alt="Slider" className="landflyone" />
                <img src={Landflytwo} alt="Slider" className="landflytwo" />
                <img src={Landflythree} alt="Slider" className="landflythree" />

            </div>
        </div>
        <div className="flydivtext"><h1 className="flydivh1">{slideText[currentSlide].title}</h1>
                <h6 className="flydivsubtext">{slideText[currentSlide].subtitle}</h6>
        </div>

        <div className="non-flydivtext">
            <div className="bigfly-left">
                <h1 className="flydivh1">Quick and easy</h1>
                <h6 className="flydivsubtext">So you can get on with your shopping.</h6>
            </div>
            <div className="bigfly">
                <h1 className="flydivh1">It all adds up</h1>
                <h6 className="flydivsubtext">Members earn on average $400+ back a year.</h6>
            </div>
            <div className="bigfly-right">
                <h1 className="flydivh1">Pay out using Cash App</h1>
                <h6 className="flydivsubtext">Get your money directly to your Cash App account.</h6>
            </div>
        </div>
        <div className="dots">
                <div className={currentSlide === 0 ? 'dot active' : 'dot'} onClick={() => handleDotClick(0)}></div>
                <div className={currentSlide === 1 ? 'dot active' : 'dot'} onClick={() => handleDotClick(1)}></div>
                <div className={currentSlide === 2 ? 'dot active' : 'dot'} onClick={() => handleDotClick(2)}></div>
        </div>
        <div className="landing-slice-three-large">
  <h1 className="landing-slice-three-h1">Frequently Asked Questions</h1>

  <div className="columns">
    <div className="column">
      <div className={expanded ? 'qonediv expanded' : 'qonediv'} onClick={toggleExpand}>
        How does Pinchcart make money?
        <img className="arrowone" src={UpArrow} />
        <div className={expanded ? 'contentone expanded' : 'contentone'}>Pinchcart revinue is though affiliate commisions and other ad sources in our plateforms.</div>
      </div>

      <div className={expandedthree ? 'qtwodiv expanded' : 'qtwodiv'} onClick={toggleExpandthree}>
        <div className="content">
          Will it cost me anything?
        </div>
        <img className="arrowone" src={UpArrow} />
        <div className={expandedthree ? 'contentone expanded' : 'contentone'}>Never. Our service is free to use and download.</div>
      </div>
    </div>

    <div className="column">
      <div className={expandedtwo ? 'qtwodiv expanded' : 'qtwodiv'} onClick={toggleExpandtwo}>
        <div className="content">
          How does cash back work?
        </div>
        <img className="arrowone" src={UpArrow} />
        <div className={expandedtwo ? 'contentone expanded' : 'contentone'}>Download the browser extension to activate cash back shopping.</div>
      </div>

      <div className={expandedfour ? 'qthreediv expanded' : 'qthreediv'} onClick={toggleExpandfour}>
        <div className="content">
          When will I get my cash back?
        </div>
        <img className="arrowone" src={UpArrow} />
        <div className={expandedfour ? 'contentone expanded' : 'contentone'}>30-60 days from date of validation.</div>
      </div>

    </div>
  </div>
</div>



        <div className="landing-slice-three">
        <h1 className="landing-slice-three-h1">Frequently Asked Questions</h1>
        <div className={expanded ? 'qonediv expanded' : 'qonediv'} onClick={toggleExpand}>               
                How does Pinchcart make money?
             <img className="arrowone" src={UpArrow} />  
            <div className={expanded ? 'contentone expanded' : 'contentone'}>Pinchcart revinue is though affiliate commisions and other ad sources in our plateforms.</div>
        </div>
        <div className={expandedtwo ? 'qtwodiv expanded' : 'qtwodiv'} onClick={toggleExpandtwo}>           
            <div className="content">
                How does cash back work?
            </div>
            <img className="arrowone" src={UpArrow} />  
            <div className={expandedtwo ? 'contentone expanded' : 'contentone'}>Download the browser extension to activate cash back shopping.</div>
        </div>       
        <div className={expandedthree ? 'qtwodiv expanded' : 'qtwodiv'} onClick={toggleExpandthree}>           
            <div className="content">
            Will it cost me anything?
            </div>
            <img className="arrowone" src={UpArrow} />  
            <div className={expandedthree ? 'contentone expanded' : 'contentone'}>Never.  Our service is free to use and download.</div>
        </div>
        <div className={expandedfour ? 'qthreediv expanded' : 'qthreediv'} onClick={toggleExpandfour}>           
            <div className="content">
            When will I get my cash back?
            </div>
            <img className="arrowone" src={UpArrow} />  
            <div className={expandedfour ? 'contentone expanded' : 'contentone'}>30-60 days form date of validation.</div>
        </div>
        </div>
       
      <div className="landing-slice-four-large">
        <div style={{display: 'flex', textAlign: 'left'}}><h1 style={{color: 'white', fontSize: '3rem', marginLeft: '40px'}}>
          Turn your shopping into savings by earning cash back on your favorite brands</h1></div>
        <div style={{width: '95%', marginRight: '50px'}}>
        <img style={{maxWidth: '500px'}} src={LandingHeroThree}/>
        </div>
      </div>

      <div className="landing-slice-four-medium">
        <div style={{display: 'flex', textAlign: 'left'}}><h1 style={{color: 'white', fontSize: '2rem', marginLeft: '40px'}}>
          Turn your shopping into savings by earning cash back on your favorite brands</h1></div>
        <div style={{width: '95%', marginRight: '50px'}}>
        <img style={{maxWidth: '500px'}} src={LandingHeroThree}/>
        </div>
      </div>

      <div className="landing-slice-four">
        <div><h1 style={{color: 'white', width: '95%'}}>Turn your shopping into savings by earning cash back on your favorite brands</h1></div>
        <div>
        <img style={{width: '80%'}} src={LandingHeroThree}/>
        </div>
      </div>


<div className="landing-slice-five">
   <h1> Huge savings on 1000's of top brands.</h1>

<div className="brand-columns">
  <div className="brand-column">
    <div className="panel">
      <h2>Fashion</h2>
      <ul>
        <li><a href="https://dhgate.sjv.io/xk616d">DH Gate</a></li>
        <li><a href="https://newchic.sjv.io/Or5e2W">New Chic</a></li>
        <li><a href="https://bellelily.pxf.io/daA9vj">Bellelily</a></li>
        <li><a href="https://bostanten.pxf.io/6eLZWm">Bostanten</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Fitness</h2>
      <ul>
        <li><a href="https://trugritfitness.pxf.io/Y9PzDr">TruGrit</a></li>
        <li><a href="https://imp.i277339.net/LXBkP0">Tempo</a></li>
        <li><a href="https://thefitville.pxf.io/rQrdnD">FitVille</a></li>
        <li><a href="https://lifespark.pxf.io/DKGxVa">LifeSpark</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Home & Garden</h2>
      <ul>
        <li><a href="https://duracell.sjv.io/k0bNQn">Duracell</a></li>
        <li><a href="https://imp.i265064.net/baJXj9">KeeyPon</a></li>
        <li><a href="https://pish-posh-baby.sjv.io/vNbObO">PishPosh</a></li>
        <li><a href="https://grillagrills.pxf.io/1rOVoa">GrillaGrills</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Travel</h2>
      <ul>
        <li><a href="https://swa.eyjo.net/eKJogZ">Southwest Points</a></li>
        <li><a href="https://jetblue.jyeh.net/QyLD16">JetBlue Points</a></li>
        <li><a href="https://hilton.ijrn.net/PyExGR">Hilton Honors</a></li>
        <li><a href="https://alaska.gqco.net/R5dDWg">Alaska Mileage</a></li>
      </ul>
    </div>
  </div>
  <div className="brand-column">
    <div className="panel">
      <h2>Health & Beauty</h2>
      <ul>
        <li><a href="https://livwellnutrition.pxf.io/JzXnPv">LivWell</a></li>
        <li><a href="https://unicoeye.pxf.io/KjW0Py">Unicoeye</a></li>
        <li><a href="https://kailo.sjv.io/c/4901218/1354101/16311?utm_campaign=xca1&utm_medium=xca1&utm_source=xca1354101">Kailo</a></li>
        <li><a href="https://nature-made.pxf.io/Y9YKgj">Nature Made</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Food & Drink</h2>
      <ul>
        <li><a href="https://120life.sjv.io/Y9GknR">120 Life</a></li>
        <li><a href="https://strawberryhillcheesecakes.pxf.io/R5DJ5R">Strawberry Hill</a></li>
        <li><a href="https://greatfill.pxf.io/jrgKge">Greatfill</a></li>
        <li><a href="#">More Soon</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Tech</h2>
      <ul>
        <li><a href="https://jegs.ork2.net/JzQ2ZE">Jegs</a></li>
        <li><a href="https://atlasvpn.sjv.io/5gErr1">AtlasVPN</a></li>
        <li><a href="https://sentrypc.7eer.net/GmJWW2">SentryPC</a></li>
        <li><a href="https://advancedpropertyanalysis.7eer.net/vN6A9y">Estate Wealth Builder</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Marketplace</h2>
      <ul>
        <li><a href="https://wish.pxf.io/ZQ5aMg">Wish</a></li>
        <li><a href="https://swagbucks.7eer.net/5gmeYD">Swagbucks</a></li>
        <li><a href="https://temu.to/m/ua9ecregg8z">Temu</a></li>
      </ul>
    </div>
  </div>
</div>
</div>

<div className="landing-slice-five-medium">
   <h1> Huge savings on 1000's of top brands.</h1>

<div className="brand-columns">
  <div className="brand-column">
    <div className="panel">
      <h2>Fashion</h2>
      <ul>
        <li><a href="https://dhgate.sjv.io/xk616d">DH Gate</a></li>
        <li><a href="https://newchic.sjv.io/Or5e2W">New Chic</a></li>
        <li><a href="https://bellelily.pxf.io/daA9vj">Bellelily</a></li>
        <li><a href="https://bostanten.pxf.io/6eLZWm">Bostanten</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Fitness</h2>
      <ul>
        <li><a href="https://trugritfitness.pxf.io/Y9PzDr">TruGrit</a></li>
        <li><a href="https://imp.i277339.net/LXBkP0">Tempo</a></li>
        <li><a href="https://thefitville.pxf.io/rQrdnD">FitVille</a></li>
        <li><a href="https://lifespark.pxf.io/DKGxVa">LifeSpark</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Home & Garden</h2>
      <ul>
        <li><a href="https://duracell.sjv.io/k0bNQn">Duracell</a></li>
        <li><a href="https://imp.i265064.net/baJXj9">KeeyPon</a></li>
        <li><a href="https://pish-posh-baby.sjv.io/vNbObO">PishPosh</a></li>
        <li><a href="https://grillagrills.pxf.io/1rOVoa">GrillaGrills</a></li>
      </ul>
    </div>

</div>
    <div className="brand-column">
   
    <div className="panel">
      <h2>Travel</h2>
      <ul>
        <li><a href="https://swa.eyjo.net/eKJogZ">Southwest Points</a></li>
        <li><a href="https://jetblue.jyeh.net/QyLD16">JetBlue Points</a></li>
        <li><a href="https://hilton.ijrn.net/PyExGR">Hilton Honors</a></li>
        <li><a href="https://alaska.gqco.net/R5dDWg">Alaska Mileage</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Health & Beauty</h2>
      <ul>
        <li><a href="https://livwellnutrition.pxf.io/JzXnPv">LivWell</a></li>
        <li><a href="https://unicoeye.pxf.io/KjW0Py">Unicoeye</a></li>
        <li><a href="https://kailo.sjv.io/c/4901218/1354101/16311?utm_campaign=xca1&utm_medium=xca1&utm_source=xca1354101">Kailo</a></li>
        <li><a href="https://nature-made.pxf.io/Y9YKgj">Nature Made</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Food & Drink</h2>
      <ul>
        <li><a href="https://120life.sjv.io/Y9GknR">120 Life</a></li>
        <li><a href="https://strawberryhillcheesecakes.pxf.io/R5DJ5R">Strawberry Hill</a></li>
        <li><a href="https://greatfill.pxf.io/jrgKge">Greatfill</a></li>
        <li><a href="#">More Soon</a></li>
      </ul>
    </div>
    
    </div>
  
 
  <div className="brand-column">
   
    <div className="panel">
      <h2>Tech</h2>
      <ul>
        <li><a href="https://jegs.ork2.net/JzQ2ZE">Jegs</a></li>
        <li><a href="https://atlasvpn.sjv.io/5gErr1">AtlasVPN</a></li>
        <li><a href="https://sentrypc.7eer.net/GmJWW2">SentryPC</a></li>
        <li><a href="https://advancedpropertyanalysis.7eer.net/vN6A9y">Estate Wealth Builder</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Marketplace</h2>
      <ul>
        <li><a href="https://wish.pxf.io/ZQ5aMg">Wish</a></li>
        <li><a href="https://swagbucks.7eer.net/5gmeYD">Swagbucks</a></li>
        <li><a href="https://temu.to/m/ua9ecregg8z">Temu</a></li>
      </ul>
    </div>
  </div>
</div>
</div>



<div className="landing-slice-five-large">
   <h1> Huge savings on 1000's of top brands.</h1>

<div className="brand-columns">
  <div className="brand-column">
    <div className="panel">
      <h2>Fashion</h2>
      <ul>
        <li><a href="https://dhgate.sjv.io/xk616d">DH Gate</a></li>
        <li><a href="https://newchic.sjv.io/Or5e2W">New Chic</a></li>
        <li><a href="https://bellelily.pxf.io/daA9vj">Bellelily</a></li>
        <li><a href="https://bostanten.pxf.io/6eLZWm">Bostanten</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Fitness</h2>
      <ul>
        <li><a href="https://trugritfitness.pxf.io/Y9PzDr">TruGrit</a></li>
        <li><a href="https://imp.i277339.net/LXBkP0">Tempo</a></li>
        <li><a href="https://thefitville.pxf.io/rQrdnD">FitVille</a></li>
        <li><a href="https://lifespark.pxf.io/DKGxVa">LifeSpark</a></li>
      </ul>
    </div>
   </div>


    <div className="brand-column">
    <div className="panel">
      <h2>Travel</h2>
      <ul>
        <li><a href="https://swa.eyjo.net/eKJogZ">Southwest Points</a></li>
        <li><a href="https://jetblue.jyeh.net/QyLD16">JetBlue Points</a></li>
        <li><a href="https://hilton.ijrn.net/PyExGR">Hilton Honors</a></li>
        <li><a href="https://alaska.gqco.net/R5dDWg">Alaska Mileage</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Health & Beauty</h2>
      <ul>
        <li><a href="https://livwellnutrition.pxf.io/JzXnPv">LivWell</a></li>
        <li><a href="https://unicoeye.pxf.io/KjW0Py">Unicoeye</a></li>
        <li><a href="https://kailo.sjv.io/c/4901218/1354101/16311?utm_campaign=xca1&utm_medium=xca1&utm_source=xca1354101">Kailo</a></li>
        <li><a href="https://nature-made.pxf.io/Y9YKgj">Nature Made</a></li>
      </ul>
    </div>   
  </div>


 

  <div className="brand-column">
    <div className="panel">
      <h2>Food & Drink</h2>
      <ul>
        <li><a href="https://120life.sjv.io/Y9GknR">120 Life</a></li>
        <li><a href="https://strawberryhillcheesecakes.pxf.io/R5DJ5R">Strawberry Hill</a></li>
        <li><a href="https://greatfill.pxf.io/jrgKge">Greatfill</a></li>
        <li><a href="#">More Soon</a></li>
      </ul>
    </div>
     <div className="panel">
      <h2>Home & Garden</h2>
      <ul>
        <li><a href="https://duracell.sjv.io/k0bNQn">Duracell</a></li>
        <li><a href="https://imp.i265064.net/baJXj9">KeeyPon</a></li>
        <li><a href="https://pish-posh-baby.sjv.io/vNbObO">PishPosh</a></li>
        <li><a href="https://grillagrills.pxf.io/1rOVoa">GrillaGrills</a></li>
      </ul>
    </div>
    </div>


  <div className="brand-column">
    <div className="panel">
      <h2>Tech</h2>
      <ul>
        <li><a href="https://jegs.ork2.net/JzQ2ZE">Jegs</a></li>
        <li><a href="https://atlasvpn.sjv.io/5gErr1">AtlasVPN</a></li>
        <li><a href="https://sentrypc.7eer.net/GmJWW2">SentryPC</a></li>
        <li><a href="https://advancedpropertyanalysis.7eer.net/vN6A9y">Estate Wealth Builder</a></li>
      </ul>
    </div>
    <div className="panel">
      <h2>Marketplace</h2>
        <ul>
        <li><a href="https://wish.pxf.io/ZQ5aMg">Wish</a></li>
        <li><a href="https://swagbucks.7eer.net/5gmeYD">Swagbucks</a></li>
        <li><a href="https://temu.to/m/ua9ecregg8z">Temu</a></li>
        </ul>
      </div>
    </div>
    </div>
   
   
</div>


<div className="landing-slice-six">
    <h1>
    Get Coupons and Cash Back using the Chrome Extension
    </h1>
    <a href="https://bit.ly/pinchcart4chrome"><img style={{width: '200px', height: '60px'}} src={Webstore} /></a>
</div>

<div className="landing-footer">
    <div className="facebookSocial"></div>
    <div className="twitterSocial"></div>
    <div className="instagramSocial"></div>
    <div className="pintrestSocial"></div>
    <div className="copy"><p>&copy; 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.</p></div>
</div>
        </>
    );
}

export default Landing;
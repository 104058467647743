import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrum.css';
import { useLocation } from 'react-router-dom';


function Breadcrum({ page, category, state, city }) {
    const location = useLocation();
  const currentURL = location.pathname; // Get the current URL path
  const categoryFromURL = getCategoryFromURL(currentURL);


  const newcategory = formatCategory(categoryFromURL);

    function getCategoryFromURL(url) {
        // Split the URL by '/'
        const urlParts = url.split('/');
        // Find the category part in the URL (assuming it's the last part)
        const category = urlParts[urlParts.length - 1];
        return category;
      }

      function formatCategory(category) {
        const firstLetterCapitalized = category.charAt(0).toUpperCase() + category.slice(1);
        const formattedCategory = firstLetterCapitalized
          .replace(/-/g, ' ')
          .replace(/ and /g, ' & ');
        return formattedCategory;
      }

    if (page === 'goods' && !category == ''){
        const showpage = 'Goods';
    return (
        <>
        <div className='breadcrum'>
            <Link to="/">Home</Link>&nbsp;&gt; <Link to="/goods">{showpage}</Link> &gt; {newcategory}
        </div>
        <div>
            <h1 className='crumHeader'>Goods {category}</h1>
        </div>
        </>
    );

    } else if (page ==='local' && !category == ''){
        const showpage = 'Local';

    return (
        <>
        <div className='breadcrum'>
            <Link to="/">Home</Link>&nbsp;&gt; <Link to="/local">{showpage}</Link> &gt; {state} &gt; {city} &gt; {category}
        </div>
        <div>
            <h1 className='crumHeader'>Local {category} in and around {city}, {state}</h1>
        </div>
        </>
    );
    } else if (page === 'travel' && !category == ''){
        const showpage = 'Travel';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; <Link to="/travel">{showpage}</Link> &gt; {category}
            </div>
            <div>
                <h1 className='crumHeader'>{category} </h1>
            </div>
            </>
        );
    } else if (page === 'coupons' && !category == ''){
        const showpage = 'Coupons';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; <Link to="/coupons">{showpage}</Link> &gt; {category}
            </div>
            <div>
                <h1 className='crumHeader'>{category} </h1>
            </div>
            </>
        );
    } else if (page === 'dailydeals'){
        const showpage = 'DailyDeals';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; {showpage}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Daily Deals
                </h1>
            </div>
            </>
        );
    } else if (page === 'greatgifts'){
        const showpage = 'Geat Gifts';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; {showpage}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Great Gifts
                </h1>
            </div>
            </>
        );
    } else if (page === 'goods'){
        const showpage = 'Goods';
        if (newcategory === 'Goods'){
        let newcategory = '';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt;  <Link to="/goods">{showpage}</Link>  &nbsp;&gt; {newcategory}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Goods {newcategory}
                </h1>
            </div>
            </>
        );
        }

        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt;  <Link to="/goods">{showpage}</Link>  &nbsp;&gt; {newcategory}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Goods {newcategory}
                </h1>
            </div>
            </>
        );
    } else if (page === 'local'){
        const showpage = 'Local';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; {showpage} &nbsp;&gt; {newcategory}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Local {newcategory}
                </h1>
            </div>
            </>
        );
    } else if (page === 'travel'){
        const showpage = 'Travel';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; {showpage}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Travel 
                </h1>
            </div>
            </>
        );
    } else if (page === 'coupons'){
        const showpage = 'Coupons';
        return (
            <>
            <div className='breadcrum'>
                <Link to="/">Home</Link>&nbsp;&gt; {showpage} &nbsp;&gt; {newcategory}
            </div>
            <div>
                <h1 className='crumHeader'>
                    Coupons {newcategory}
                </h1>
            </div>
            </>
        );
    } 
}

export default Breadcrum;

import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';

function PostImage() {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUrlChange = (e) => {
    setImageUrl(e.target.value);
  };

  const handleUpload = async () => {
    if (image) {
      const storageRef = ref(storage, `productImages/${image.name}`);

      try {
        // Upload the image bytes
        await uploadBytes(storageRef, image);

        // Get the download URL
        const url = await getDownloadURL(storageRef);

        console.log('Image uploaded:', url);
        // You can save the URL to your database or perform any other actions here
      } catch (error) {
        console.error('Error uploading image:', error.message);
      }
    } else if (imageUrl) {
      try {
        // Download image from URL
        const response = await fetch(imageUrl);
        const imageData = await response.blob();

        // Upload the image bytes
        const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        const storageRef = ref(storage, `productImages/${imageName}`);
        await uploadBytes(storageRef, imageData);

        // Get the download URL
        const url = await getDownloadURL(storageRef);

        console.log('Image downloaded and uploaded:', url);
        // You can save the URL to your database or perform any other actions here
      } catch (error) {
        console.error('Error downloading and uploading image:', error.message);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <input type="text" placeholder="Image URL" value={imageUrl} onChange={handleUrlChange} />
      <button onClick={handleUpload}>Upload/Download</button>
      <progress value={progress} max="100" />
    </div>
  );
}

export default PostImage;

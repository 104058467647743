import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function Private() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
    
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
            >
            Pinchcart Privacy
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Privacy Policy and Notice of Personal Information Collection
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
              
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>
            This Privacy Policy describes how Pinchcart.com DBA Pinchcart Pinnacle ("we," "us," "our," "Company") collect, use, disclose, and protect information in connection with the Company websites, mobile websites, mobile applications, browser extensions, and any related services that refer or link to this Privacy Policy ("Services"). Company is part of Pinchcart Pinnacle, which includes the affiliates and subsidiaries of our parent company, Pinchcart Pinnacle, based in Texas, United States.

</Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
              1. Collection of Information
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            <br />Sources of Collection<br />
           </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>

            <br /> We collect, process, and use information about you when you:
            <br /><ul><li> - Register for our Services</li></ul>
            <li> - Interact with our Services</li>
            <li> - Install and/or access our Services through our application(s), including our mobile application and browser extension</li>
            <li> - Connect to our Services through third parties, including social media accounts, email accounts, and business partners (described in Section 3 below)</li>
            <li> - Contact us for support</li>
            <li> - Enter a sweepstakes, contest, or promotion, or respond to a survey or poll</li>
            We collect certain information when you submit or provide it to us directly. When you have installed our mobile application or browser extension or enabled our mobile browser extensions, or when you interact with our Services online, we also automatically collect information about you and your use of the Services through the use of log processes, cookies, location-identifying technologies, and other tracking technologies. Certain information is collected about you from third parties, including when you connect through a third-party service or business partner site or when information is shared by merchants and business partners with whom you’ve also interacted.
           
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            <br />Types of Information We May Collect<br />
           </Typography>
           <Typography variant="h7" align="left" color="text.secondary" paragraph>

            <br /> The types of information we collect about you may include personal information of the following types:
            <br /><ul><li> - Identifiers that you provide us directly, such as your first and last name, postal address, email address, tax information (for sweepstakes, contest, and promotion winners), and/or phone number</li></ul>
            <li> - Identifiers collected through your use of the Services, such as unique online or device identifiers, IP address, device or session IDs and information regarding your system configuration (operating system and version), network, and connected hardware (e.g., computer or mobile device and carrier) and software applications (e.g., other applications installed on your mobile device)</li>
            <li> - Customer Record Information, such as payment account name, payment account number, payment card expiration date and billing address</li>
            <li> - Protected Classification Characteristics, such as your gender, age, household income, marital status, or your inclusion in any other classification protected under California law, only to the extent you provide this information to us as demographic information in a survey response that is identifiable to you</li>
            <li> - Commercial Information about you, such as records of products, product types, merchants, merchant types, goods or services purchased, obtained, or considered by you, including products, merchants and coupons you searched for, viewed or clicked, items added to cart and abandoned, shopping trips initiated, merchant sites visited from our Services, transaction history related to our Services, purchase confirmation data, accrued cash-back, American Express Member points, and other loyalty currency</li>
            <li> - Internet and other Network Activity, such as your browsing and search history and your interactions with our sites and Services, including:</li>
            <li> - Products, coupons, offers, or merchants you search, click, favorite, and/or view, including clicks you make on the merchant sites you visit within our mobile app;</li>
            <li> - URL’s of the pages you visit, including those tracked by our browser extensions and mobile application;</li>
            <li> - Date and time stamps associated with such browsing, searches, and clicks;</li>
            <li> - Click stream data, meaning the page by page clicks that you make as you navigate through our Services, including the web page visited before you came to our website;</li>
            <li> - Locale preferences for your use of the Services Sensory-related information, such as audio recordings of your voice to the extent you call our member services representatives, as permitted under applicable law;</li>
            <li> - Member account credentials, including username and password; and</li>
            <li> - Inferences drawn about you and your likely preferences, characteristics, behavior, and attitudes from any information we collect about you, except "sensitive" information.</li>
            In some jurisdictions, certain types of information we collect may be considered “sensitive” personal information including:
            <br /><li> - Demographic information, such as your gender, age, household income, or marital status, only to the extent you provide this information to us as demographic information in a survey response that is identifiable to you, and</li>
            <li> - Precise geo-location data, to the extent you have configured your device to disclose such information to us.</li>
            Note that we only use this sensitive information to provide the Services to you and we do not collect, use or disclose this sensitive information for the purpose of inferring characteristics about you. We do not “sell” or “share” sensitive personal information or use sensitive data for “targeted advertising” as those terms are defined in U.S. state privacy laws.

            </Typography>
           
           
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            2. Use of Information We Collect
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            <br />We use information we collect about you for the following business and commercial purposes:<br />
           </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>

            <br /><b>To Provide Our Services and Manage Your Account, including to:</b><br /><br />
            <br /><ul><li> - Process your registration with our Services, including verifying whether your email address is active and valid and enrolling you into our programs</li></ul>
            <li> - Manage your account and interaction preferences, including tracking your favorite stores, merchants, and preferred locales and your transaction history</li>
            <li> - Facilitate and manage your purchase transactions from our gift card mall</li>
            <li> - Deliver you rewards or payments and identify opportunities for rewards and/or offers</li>
            <li> - Send you technical notices, updates, security alerts and administrative messages</li>
            <li> - Provide you member services and support regarding your account and reply to your questions, comments, and requests</li>
            <br /><b>To Personalize and Improve Our Services</b><br /><br />
            We may use information we collect about you to create a profile of your preferences to personalize and tailor Services content so that it is more likely to be of interest to you. For example, we may use your most frequently visited stores or shopping history to display offers for similar stores in relevant parts of the Services or to present special offers that may interest you via our Services, including in-store.
<br /><br />
We may also use information we collect about you to identify usage trends and perform statistical analyses to improve our Services, websites, applications, marketing efforts, and the products and services of our business partners we recommend or offer, in order to provide a better service to you in the future.
  <br /><br />

  <b>To Provide You Marketing Information</b><br /><br />

Company may use information we collect about you to provide you more personalized offers, special offers, discounts, and/or more relevant offers from our participating merchants via email and other direct communications, and through digital advertising. We may use your information to directly send you email newsletters, rewards offers, discounts, promotion codes, events and general information about the Services. You can manage these direct marketing communications as provided in Section 4 (Your Choices) below. We may also use your information to advertise to you online, including targeting advertising to you on other websites. You can opt-out of use of your personal information for targeted online advertising as provided in Section 4 (Your Choices) and Section 5 (Your Rights).

<br /><br /><b>To Protect You, Others, and our Business</b><br /><br />

We may use collected information to assess risk and to investigate and limit fraud, spam, and other malicious online activities in our Services, and to improve our security measures. We may also use collected information to assert our legal rights or to defend our legal rights against third parties.

<br /><br /><b>To Comply With Applicable Laws</b><br /><br />

We may use information we collect about you to enable us to comply with applicable laws, rules, regulations, and judicial authorities in each geographical region in which we operate.

<br /><br /><b>To Administer Contests, Sweepstakes, Surveys, Polls, and Promotions</b><br /><br />

From time to time, Company may invite you to enter various contests, sweepstakes, surveys, polls, and promotions. If you choose to participate in a contest or promotion, your personal information may be used to administer the contest, such as to determine your eligibility for the contest or to award a prize. If you choose to participate in a survey or poll, the feedback you submit will be aggregated and deidentified to provide us insight on business and shopping trends. We maintain this information in a deidentified manner and do not attempt to re-identify it.

<br /><br /><b>To Administer Referral Programs</b><br /><br />

We may offer features that allow you to inform another individual about our Services. If you choose to use our referral features, we will ask for your referral’s email address to send them an electronic communication inviting them to join our Services. We will store this information for the sole purpose of sending this email and tracking the success of our referral program. If you choose to participate in our referral programs, you represent and warrant that you have a personal relationship with your referral and that you are authorized to share their information with Company for the purpose of Company sending a communication to invite them to join the Services. If you believe that one of your contacts has provided us with your personal information through our referral service and you would like to request that it be removed from our database, please contact us at<br /><a href='https://pinchcart.com/contactus'> https://pinchcart.com/contactus</a>.
<br /><br />


            </Typography>
           </Container>

           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            3. Disclosure of Information
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            <br />We may disclose the below-specified categories of information for our business or commercial purposes, including the following:<br />
           </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>

            <br /><b>Disclosure to Our Service Providers</b><br /><br />
            We may disclose all categories of your collected personal information as necessary with our third-party service providers (which may include Company affiliates) who perform various business operations, solely for our benefit or on our behalf, under our instructions and in compliance with appropriate contractual, technical and organizational security measures to protect your data from unauthorized use, retention, or disclosure. For example, we may partner with other companies to provide you member support services, authentication services, prevent fraud on our Services, process payments, administer our promotions, referral programs, sweepstakes, and surveys, perform analytics, maintain the accuracy of our databases, deliver direct marketing and communications, limit delivery of repetitive or unnecessary marketing or communications, facilitate your gift card purchases, fulfill your product purchases, place and fulfill your takeout orders, and administer your rewards or payments.<br /><br />
            <br /><b>Disclosure to Our Business Partners</b><br /><br />
            We work with various types of third-party business partners to offer you our Cash Back shopping Services. We may disclose the following types of information with the following types of Business Partners:<br />
            <br /><br /><b>Affiliate Partners</b><br /><br />

            When you click on a link to a merchant site presented on our Services to start your Cash Back shopping, you evidence your intent to interact with that business through our Services, and thereby are directing us to disclose certain information about you (including the link you clicked in our Services and a unique ID assigned to track that your shopping originated from our Services) to an affiliate network to properly account for your purchases with a merchant. For a small number of merchants that do not utilize an affiliate network, this information is provided directly to the merchant. The Affiliate Partner will collect Internet and Network Activity information about your use of our Services in relation to the merchant site, products, or offers. Our Affiliate Partners collect this information to track the shopping trip, but some of them may also use this information to measure performance, contribute it to their own user profiles and/or combine it with information they collect elsewhere, which they may make available for use by their other business clients. In such cases, our disclosure to certain of these Affiliate Partners could be considered a “sale” or “sharing” of your personal information as those terms are defined under applicable U.S. state privacy laws, and the opt-out we offer to consumers in Section 5, below, would signal these Affiliate Partners to not collect or use your information from our Services except as necessary to validate and provide your Cash Back.<br /><br />
            In addition, to the limited extent that we target direct marketing to you or personalize the offers you see in our Services based on the profile data we compile from your behavior tracked via our Services, for the direct benefit of a particular merchant, this marketing or personalization may also be considered “selling”, “sharing”, or processing for the purposes of “targeted advertising” of your personal information. In this circumstance, the opt-out we provide in Section 5, below, would cover this type of targeted marketing and personalization. We may use information we collect about you in combination with information about you from current and prospective merchant partners to test and analyze how our Services help merchants and business partners. The information we disclose to and receive from a merchant or business partner to effectuate this analysis may include online shopping identifiers (such as shopping ID’s, order ID’s), a hashed email address, and Commercial Information (such as whether a purchase was made and the corresponding order value). We may use the results from these tests in an aggregated and deidentified manner to improve our Services and to market our Services to merchants and business clients. You may opt out of our disclosure of your information to these certain third parties for this purpose in accordance with Section 5, below.<br /><br />
            <br /><br /><b>Referral Marketing Partners</b><br /><br />
            In order to expand your options to sign up for our Services and start your Cash Back shopping from third-party sites where you learn about our Services, we contract with certain referral marketing business partners to enable that functionality, which may require that we disclose certain data about you to them. For example, if you are referred to our Services from a business partner’s websites, we may provide that partner with information about your sign up to validate the referral and activate your Services account access from the partner’s site. In addition, we may disclose information about whether you have made a purchase with us recently and whether you have installed our mobile app or browser extension so that the partner can personalize their email and site content to you. This information disclosure about your behavior in our Services may be considered “selling”, “sharing”, or processing for “targeted advertising” under applicable U.S. state privacy laws, so we provide an opt-out opportunity in Section 5, below.            <br /><br /><b>To Comply With Applicable Laws</b><br /><br />
            We may use information we collect about you to enable us to comply with applicable laws, rules, regulations, and judicial authorities in each geographical region in which we operate.
            <br /><br /><b>Financial Partners</b><br /><br />
            For the purposes of providing you additional redemption options for your Cash Back and to market our co-branded credit cards to you, we may disclose your collected information, including Identifiers, to financial business partners, which may include Pinchcart Pinnacle Companies. If you select loyalty points or other alternative methods of receiving your Cash Back, you are directing us to disclose your personal information, as necessary, to the relevant financial partner to facilitate that transaction. If you indicate that you would like to receive information about our co-branded credit cards from our financial partner(s), you are also directing us to disclose your information to such parties to enable them to provide that information.<br /><br />
            We may also disclose personal information about you to our financial partners in order to facilitate their marketing purposes and in some instances for the financial partner to directly send marketing to you. This could be considered “selling”, “sharing”, or processing for “targeted advertising” under applicable U.S. state privacy laws, and you may opt-out of this disclosure for partner marketing in Section 5, below.<br /><br />
            <br /><br /><b>Disclosure to Digital Advertising Providers</b><br /><br />
            To provide us with targeted advertising and marketing services, we partner with certain third parties that automatically collect Internet and Network Activity information about your use of the Services, such as your browsing history, IP address, pages viewed, and links clicked, so that they can use this information to serve you ads for our Services on other sites you visit. Some of these third parties may also use this collected information to draw inferences about you for their own purposes or separately collect personal information about your online activities over time and across different websites (in addition to our Services) to help predict your preferences and to display (and enable other third parties to display) ads to you that are more likely to be of interest to you. You have the right to opt out of our sharing of your information with certain third parties for the purposes of cross-context behavioral or targeted advertising. To exercise this right, you can opt-out via the instructions in Section 5, below. Note that this Privacy Policy covers only our use of data and does not include further use of data by third parties. If you wish to opt out of interest-based advertising in general, please email: userdata@pinchcart.com        <br /><br />
            <br /><br /><b>Disclosure to Other Pinchcart Media Companies</b><br /><br />
            We work closely with our affiliated businesses and companies within the Pinchcart Media and disclose your personal information for our internal business administration. Some of our Service Providers and Business Partners to whom we disclose personal information, as described above, may include other Pinchcart Pinnacle Companies. We may also disclose information in the categories of Identifiers (email address), Commercial Information (product metrics), and Internet and other Network Activity to our affiliates within the Pinchcart Pinnacle for those corporate affiliates to analyze and monitor usage and trends to improve their services and websites and for strategic planning purposes at the Pinchcart Pinnacle level. Our opt-out and delete request functionality will serve to remove you from these disclosures.<br /><br />
            <br /><br /><b>Disclosure for Legal Purposes</b><br /><br />
            We may disclose the information we collect, including personal information, to the extent permitted by applicable law, (i) in response to subpoenas or other legal processes or if, in our good faith opinion such disclosure is required or permitted by law without a specific disclosure; (ii) at the request of governmental authorities conducting an investigation; (iii) to enforce our terms and conditions or other policies applicable to our Services; and (iv) to protect the rights, property, life, health, security and safety of Company or any third party.<br /><br />
            <br /><br /><b>In Relation to Business Asset Transfers</b><br /><br />
            We may disclose the information we collect, including personal information, to any third party in connection with, or during confidential negotiations of, any proposed or actual merger, purchase, sale (including a liquidation, realization, foreclosure or repossession) or any other type of acquisition of all or any portion of Company assets, conveyance or transfer of all or a portion of our business to another company. In such event, the acquiring company would be contractually required to use your collected information in accordance with this Privacy Policy and applicable law or to provide prior notice to you of any new or materially changed practices.<br /><br />
            In addition to the above listed disclosures, we may also disclose aggregated or de-identified information that cannot reasonably be used by recipients to identify you.<br /><br />




            </Typography>
           </Container>

           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            4. Your Choices
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>

            <br /><b>Updating Account Information</b><br /><br />
            We encourage you to maintain the accuracy of the information you submit to us. You may provide account updates by logging into your Service account and updating your information in the Settings.<br /><br />
            <br /><b>Email Marketing</b><br /><br />
            We may send you periodic electronic communications and direct mailings when you sign up for an account. You can opt out of receiving promotional communications from us at any time by following the instructions provided in those communications to unsubscribe or stop them, modifying the settings of your account, or contacting us through the Services. Please note that even if you opt out of receiving promotional communications, we may continue to send you non-promotional emails and other types of communications, as permitted by law, such as service emails relating to our ongoing business relationship. Service emails contain service-related announcements that affect your account, such as product order confirmations, reward or payment updates, and responses to your comments and feedback submitted to us.<br />
            <br /><br /><b>Mobile Application Notifications</b><br /><br />
            When using our mobile application, your preferences for receiving push notifications and other mobile alerts can be configured in the settings of your mobile device.<br /><br />
            <br /><br /><b>SMS Messaging</b><br /><br />
            f you elect to provide us a mobile number and consent to receive certain messaging, we may use SMS messaging to contact you when you make account updates, to provide you updates on your customer support tickets, and for account recovery purposes. You may opt out of such messages by removing your mobile number in your account preferences or replying STOP to any incoming messages.<br /><br />
            <br /><br /><b>Data Collection and Sharing Preferences</b><br /><br />
            You may in some cases have the option to decline to provide us with certain information; however, your choice may impact your use of certain features or services for which that information is a necessary component.<br /><br />
            <br /><br /><b>Mobile App/Browser Extension</b><br /><br />
            You can prospectively stop all collection of information by our mobile application and/or browser extension by uninstalling our mobile application and/or browser extension. Because the browser extension and mobile application permit data about your online behavior on that device or browser to be collected when installed, even without interaction, they must be uninstalled to withdraw your consent to this data collection. In some instances, you can opt out of certain data collection by updating your Settings within the browser extension or your mobile device settings.<br /><br />
           



            </Typography>
           </Container>

           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            5. Your Privacy Rights
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>

            You have certain rights in relation to your personal information under applicable laws, subject to certain exceptions.<br /><br />
            <br /><b>Right to Know and Access</b><br /><br />
            You have the right to request to know the personal information specific to you that we collected, used, disclosed for a business purpose, and sold or shared for the purposes of cross-context or targeted advertising, including the purposes of use, categories of sources from which we acquired your information and the categories of third parties to whom we disclosed your information. You also have the right to request to access (receive a copy of) the specific pieces of personal information we have collected about you (note that we will not provide any sensitive personal information we may hold in response to this type of request).<br /><br />
            <br /><br /><b>Right to Request Deletion</b><br /><br />
            You have the right to request that we delete any personal information we collected from you or maintain about you, subject to certain exceptions. We will delete your personal information upon such request, except to the extent we determine that we need the information for a business purpose and have a legal right to maintain it, in which case we will inform you of that legal exception.<br /><br />
            <br /><br /><b>Right to Correct</b><br /><br />
            You have the right to request that the Company correct any inaccurate or incomplete personal information we have collected from you or maintain about you, subject to certain exceptions. You have the ability to correct information provided by you in your account Settings located at https://www.pinchcart.com/account once you log in to your account.<br /><br />
            <br /><br /><b>Right to Opt-out of Sale or Sharing of Your Personal Information/Personal Data Opt-Out</b><br /><br />
            As described in Section 3 of this Privacy Policy, certain disclosures of information to our merchant partners, third parties or affiliates in order to provide you rewards and benefits and for our targeted marketing purposes may be considered “selling”, “sharing”, or processing for the purpose of “targeted advertising” under applicable U.S. state privacy laws. If you would like to opt-out of having your personal information used or disclosed in this way, please click the following link if you are not a member: DO NOT SELL OR SHARE MY PERSONAL INFORMATION / PERSONAL DATA OPT-OUT. If you are a member please log in to your account and visit our Privacy Preference Center, located at https://www.pinchcart.com/privacy-preferences to exercise your data privacy rights. Please note that to the extent you opt-out of certain disclosures we may be unable to offer you some of our services which require such disclosures. If you have further questions, you can contact us using the information listed in the “Contact Us” section, below.<br /><br />
            We recognize valid opt-out preference signals sent from browsers or devices, including the Global Privacy Control. When we receive such signals that do not conflict with your existing privacy preferences, we will automatically exercise opt-out rights that may be available to you under certain U.S. state privacy laws, such as the rights to opt out of sale, sharing, and processing your personal information for targeted advertising, for your browser, device or account, as applicable. To send an opt-out signal via the Global Privacy Control please follow the instructions for your device or browser located here: https://globalprivacycontrol.org.<br /><br />
            <br /><br /><b>Right to Non-Discrimination</b><br /><br />
            We will not discriminate against you for exercising any of your rights. Unless otherwise permitted by applicable law, we will not do any of the following because you request to know, access, correct, or delete your information or opt out of its sale or sharing:<br /><br />
             - deny you goods or services<br /><br />
             - charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties<br /><br />
             - provide you a different level or quality of goods or services, or<br /><br />
             - suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services<br /><br />
            <br /><br /><b>How To Exercise Rights to Know, Access, Correct, and Request Deletion</b><br /><br />
            To verify your identity, we will ask you to first login to your account in order to reach the Privacy Center, and then to obtain a verification code from the email address associated with your account. If we cannot verify your identity from the information we have on file, we may request additional information from you, which we will only use to verify your identity, and for security or fraud-prevention purposes. Please note that we are not required to respond more than twice per calendar year to requests to know/access your personal information.<br /><br />
            <br /><br /><b>Right to Appeal</b><br /><br />
            You may have the right to appeal our decision regarding your privacy rights request, depending on your state of residence. You may submit an appeal request by contacting us at userdata@pinchcart.com.  We will respond according to the law applicable in your location.<br /><br />
            <br /><br /><b>Agents</b><br /><br />
            Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. For any agent making a request on your behalf, we may require you to prove that you authorized this agent to submit a request on your behalf, and we may require you to independently verify your identity or log in to your account to provide the access code.<br /><br />



            </Typography>
           </Container>


           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            6. Other Notices
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>
            <br /><b>Shine the Light</b><br /><br />
            California law permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes.<br /><br />
            <br /><br /><b>Notice regarding information of minors</b><br /><br />
            This site and any related services are intended for adults 18 years of age or over, and we do not knowingly collect or sell or share personal information of consumers under 16 years of age without affirmative authorization or without verifiable opt-in consent from a parent for those under age 13.<br /><br />
            <br /><br /><b>Retention of Data</b><br /><br />
            We may retain information about you for as long as it is necessary to fulfill the purposes described in Section 2. Some of your information may be retained in our systems even after you request your information to be deleted if an exception applies, or in order to comply with our obligations under applicable law, collect fees owed, resolve disputes, enforce our legal rights, undertake any investigations necessitated by the foregoing, or otherwise use internally in a lawful manner that is reasonably aligned with your reasonable expectations or compatible with the context in which the information was provided.<br /><br /> 
            <br /><br /><b>Information Security</b><br /><br />
            We have implemented technical and organizational measures to provide a level of security appropriate to the risk to the personal information that we process. These measures are aimed at preserving the ongoing integrity and protecting the confidentiality of personal information. We evaluate these measures on a regular basis to confirm that they continue to maintain and apply an appropriate level of security.<br /><br />
           
            </Typography>
           </Container>


           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            7. Changes to This Privacy Policy
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>
            This Privacy Policy is current as of the effective date set forth below. We reserve the right to change this Privacy Policy from time to time consistent with applicable privacy laws and principles and our practices in relation to personal information. If we make changes to this Privacy Policy, we will notify you by revising the date on this Privacy Policy. In the event of a material change, we will provide you with additional notice (such as adding a statement to the homepages of our Services or sending you an email notification).<br /><br />
            </Typography>
           </Container>


           <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h6"
              align="left"
              color="text.primary"
              gutterBottom
            >
            8. Contact Us
            </Typography>
            <Typography variant="h7" align="left" color="text.secondary" paragraph>
            If you have any questions regarding this Privacy Policy, please feel free to contact us at userdata@pinchcart.com or by mail at 22183 CR 173, Bullard, TX 75757, Attn: Pinchcart Pinnacle Rewards Legal.
<br /><br />
Updated: Dec 10, 2023<br /><br />
            </Typography>
           </Container>
        </Box>
      
     
    </ThemeProvider>
  );
}
import React, { useState, useRef } from 'react';
import { auth } from '../firebase';
import './Join.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from '@firebase/auth';
import { db } from '../firebase';
import { collection, getDocs , where, query } from 'firebase/firestore';
import { ReactComponent as Loader } from '../loader.svg';
import BrandSlider from "./BrandSlider";



  export default function Forgotpassword() {
    const navigate = useNavigate();
    const inputEmailRef = useRef(null);
    const clickJoinText = useRef(null);  
    const [errorAccount, setErrorAccount] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorConnection, setErrorConnection] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const gotoLoginPage = () => {
      // Navigate to the login page
      navigate('/login');
  };


    const handleSendCode = async (e) => {
        e.preventDefault();
        setErrorEmail(false);
        setShowLoader(false);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const email = inputEmailRef.current.value;
        
        if(!email || !emailRegex.test(email)){
          setErrorEmail(true);
          return;
        }


        try {
          const getUserData = collection(db, 'Users');
            const querySnapshot = await getDocs(query(getUserData, where('Email', '==', email)));
              console.log(querySnapshot.size)  
            if (querySnapshot.size === 0){
                  setErrorAccount(true)  
                }
                else {

                  try {
                    await sendPasswordResetEmail(auth, email);
                    setShowLoader(true);
                    setIsEmailSent(true);
                    setErrorAccount(false);
                    setErrorEmail(false);
                    setErrorConnection(false);
                 }
                 catch (error) {
                   console.error('error sending email...', error)
                 }
       
                }
              
        }
        catch {
          setErrorConnection(true);
        } 
      

      };

return (<>
<BrandSlider />
        <div style={{ marginTop: '80px'}} id='loginForm'>
        <h1 className='joinText'>Pinchcart&trade;</h1>
        <div className="logincontainer">
        <form>
        <h1 style={{marginTop: '30px', marginBottom: '50px'}} className='createAccount'>Reset Password</h1> 

        { errorAccount && (<div className='errorAccount' style={{ fontSize: '18px', fontWeight: '600', color: '#ee4054' }}>
            No Account With That Email. Please join or try again.</div>)
        }

        { errorConnection && (<div className='errorAccount' style={{ fontSize: '18px', fontWeight: '600', color: '#ee4054' }}>
            No Connection... Wait a minute. Then try again.</div>)
        }


        { errorEmail && (<div className='errorAccount' style={{ fontSize: '18px', fontWeight: '600', color: '#ee4054' }}>
            Please Enter a Valid Email Address.</div>)
        }

        {isEmailSent ? (
              <>
                <h1 style={{ marginTop: '50px', marginBottom: '50px' }}>Check your Email to reset your password</h1>
                <button onClick={gotoLoginPage} type='button' style={{marginBottom: '60px', marginTop: '50px'}} >Login</button>

              </>
            ) : (
            <>
              <h1 style={{ marginTop: '50px', marginBottom: '-10px' }}>Enter the Email used to Join.</h1>
              <input  style={{ marginTop: '50px'}} ref={inputEmailRef} name='loginEmail' id='loginEmail' type="email" placeholder="Email" /> 
              <button onClick={handleSendCode} type='button' id='sendCodeButton' style={{marginBottom: '60px', marginTop: '50px'}} > {showLoader ? <Loader className="spinner" /> : 'Send Code'}</button>
            </>
            )}
              </form>
              <div className="joinLink" ref={clickJoinText}>Don't have and account? <Link to='/join'>Join</Link></div>
              </div>
              </div>
              <footer>
	      <p>
        © 2023-2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	      </p>
        </footer>
        </>
    );
  }


import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import {db} from '../firebase';
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import './Join.css';
import { Link } from 'react-router-dom';
import { useLocation } from "../LocationProvider";
import {useAuth} from "../AuthContext";
import Confetti from 'react-confetti';
import BrandSlider from "./BrandSlider";
import { ReactComponent as Loader } from '../loader.svg';


  export default function Account() {
    const [showPopup, setShowPopup] = useState();
    const [yourName, setName] = useState('');
    const [yourPid, setPid] = useState('');
    const [emailVerNum, setVerEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [itemId, setItemId] = useState('');
    const [cashValue, setCashValue] = useState('');
    const [paypay, setPay] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);
    const [paymentUpdate, setPaymentUpdate] = useState('');
    const { user, logOut } = useAuth();
    const targetCashbackValue = parseFloat(cashValue); 
    const userEmailRoot = user.email;
    const [showLoader, setShowLoader] = useState(false);
    const isMobile = window.innerWidth <= 767; 
    const [verificationCode, setVerificationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
      fetchData(); // Fetch data on component mount
    }, []); 

    useEffect(() => {
      // Delay fetchData by 2 seconds (adjust the delay as needed)
      const delayFetchData = setTimeout(() => {
        fetchData();
      }, 5000);
  
      // Clear the timeout if the component unmounts before the delay
      return () => clearTimeout(delayFetchData);
    }, []); // Empty dependency array ensures this effect runs only once after mount
  
  // Use useEffect to fetch data and start counting up
  useEffect(() => {

    const fetchDataAndCountUp = async () => {
    await fetchData();
    countUp();
    };
 
    fetchDataAndCountUp();
  }, [cashValue]);

async function updatePayment(e) {
    e.preventDefault();
    setShowLoader(true);
    const newDocumentId = userId;  
    const documentToUpdate = doc(db, 'Users', newDocumentId);


  try {
    await updateDoc(documentToUpdate, { SignUpMeathod: itemId });
    await fetchData();
    setPaymentUpdate(itemId);
  } catch (error) {
  console.error('Error updating payment method:', error);
  } finally {
  //setLoading(false);
  } 
}

async function fetchData() {

    const getUserData = collection(db, 'Users');
    const querySnapshot = await getDocs(query(getUserData, where('Email', '==', userEmailRoot.toLowerCase())));
    
    if (querySnapshot.size > 0) {
      const userDataArray = querySnapshot.docs.map(doc => doc.data());
      //console.log('User Data Array from Database:', userDataArray);
      const nameU = userDataArray[0].Name; 
      const Pid = userDataArray[0].PID; 
      const uId = userDataArray[0].id;
      const cashValue = userDataArray[0].CashAmount;
      const pay = userDataArray[0].SignUpMeathod;
      const verEmail = userDataArray[0].Validate;
      const validEmail = userDataArray[0].EmailValid;
      //console.log(nameU);
      setName(nameU);
      setUserId(uId);
      setPid(Pid);
      setPay(pay);
      setCashValue(cashValue);
      setItemId('');
      setVerEmail(verEmail);

      if (validEmail === 1){
        setShowPopup(false);
      } else {
        setShowPopup(true);
      }
    } 
    
  }
  
  
   // State to manage the current cashback value
   const [currentCashbackValue, setCurrentCashbackValue] = useState(0);
 
   // Function to update the cashback value
   const updateCashbackValue = (value) => {
     setCurrentCashbackValue(Number(value).toFixed(2)); // Ensure the value is displayed with two decimal places
   };
   
const countUp = () => {
    let currentValue = 0;
    const increment = 0.01;
    const animationFrameDuration = 30; // Adjust as needed

    // Convert the cashValue from the database to a number
    const targetValue = parseFloat(cashValue);

    const updateValue = () => {
      updateCashbackValue(currentValue.toFixed(2)); // Ensure the value is displayed with two decimal places
      currentValue += increment; // Increment by 0.01 for a smooth count up
      //console.log('Current Value', currentValue);

      // Check if currentValue is greater than or equal to targetValue
      if (currentValue >= targetValue) {
        setShowConfetti(true);
        setTimeout(() => {
            setShowConfetti(false);
          }, 10000);
      } else {
        setTimeout(() => requestAnimationFrame(updateValue), animationFrameDuration);
      }
    };

    // Start the count-up process once cashValue is set
    if (cashValue !== '') {
      updateValue();
    }
  };
  const handleFocus = (e) => {
    e.target.select();
};
  const handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key !== 9 && (key < 48 || key > 57)) {
        e.preventDefault();
        return false;
    }
    return true;
};

const handleKeyUp = (e) => {
  const key = e.which || e.keyCode;
  const target = e.target;
  if (key === 9) {
      return true;
  }

  let nextInput;
  if (target.nextElementSibling) {
      nextInput = target.nextElementSibling;
  } else {
      nextInput = document.querySelector('input');
  }
  if (nextInput instanceof HTMLInputElement) {
    nextInput.select();
}
};

const handleSignOut = async () => {
        try {
            localStorage.removeItem('PID');
            localStorage.removeItem('CashAmount');

            await logOut();
        } catch (error){
            console.log(error)
        }
}



const handleVerify = async () => {
  // Log emailVerNum to check its value
  console.log('emailVerNum:', emailVerNum);

  // Log verificationCode to check its value
  console.log('verificationCode:', verificationCode);

  // Add your verification logic here
  const codeString = Array.from(document.querySelectorAll('input[type="text"]')).map(input => input.value).join('');
  const code = parseInt(codeString, 10); // Parse the string as an integer
  
  await fetchData();
  
  if (code === emailVerNum) {
 
  console.log('codes match');
  try {
    const userDocRef = doc(db, 'Users', userId);
    await updateDoc(userDocRef, { EmailValid: 1 });
    setShowPopup(false);
    setErrorMessage(''); // Reset error message
    console.log('Email validation updated successfully.');
  } catch (error) {
    console.error('Error updating email validation:', error);
    setErrorMessage('An error occurred while updating email validation.'); // Set error message
  }
  }
  else {
    console.log('Verification code:', code);
    console.log('datbase code:', emailVerNum);

    setErrorMessage('Sorry, the code does not match.'); // Set error message
  }

  //console.log('Verification code:', code);
  // Close the popup after verification
};
    const affiliates = [
      { name: 'PinPaws.com', url: 'http://pinpaws.com' },
      { name: 'GrillaGrills.com', url: 'http://www.grillagrills.com' },
      { name: 'Pure Hemp Botanicals', url: 'http://purehempbotanicals.com' },
      { name: 'Arkuda Digital LLC - arkudadigital', url: 'http://www.arkmc.com' },
      { name: 'Bostanten', url: 'http://www.bostanten.com/' },
      { name: 'CBD For Life', url: 'http://www.cbdforlife.us/' },
      { name: 'Clean Email', url: 'https://clean.email' },
      { name: 'Arkmc.com', url: 'http://arkmc.com' },
      { name: 'DHgate.com', url: 'https://www.dhgate.com' },
      { name: 'Duracell', url: 'http://www.duracell.com/en-us/' },
      { name: 'DYU EBIKE', url: 'http://dyucycle.com/' },
      { name: 'eBrands.com', url: 'http://www.ebrands.com/' },
      { name: 'FitVille', url: 'https://www.thefitville.com' },
      { name: 'Unicoeye.com', url: 'http://unicoeye.com/' },
      { name: 'Hundeo GmbH', url: 'https://www.hundeo.com/en/' },
      { name: 'Innovative Extracts', url: 'http://www.ie-cbd.com' },
      { name: 'Jegs', url: 'http://www.jegs.com' },
      { name: 'Kailo', url: 'http://Gokailo.com' },
      { name: 'King Par LLC', url: 'http://www.clubsandsticks.com/' },
      { name: 'Lenovo India', url: 'http://www.lenovo.com/in/en' },
      { name: 'LivWell Nutrition', url: 'http://livwellnutrition.com' },
      { name: 'Marketxls.com', url: 'http://www.marketxls.com' },
      { name: 'Namecheap', url: 'http://namecheap.com' },
      { name: 'Nature Made', url: 'http://naturemade.com' },
      { name: 'Naturecan', url: 'https://uk.naturecan.com' },
      { name: 'Newchic Company Limited', url: 'https://www.newchic.com/' },
      { name: 'Pish Posh Baby', url: 'http://pishposhbaby.com' },
      { name: 'Play Caller Sports', url: 'https://www.playcallersports.com' },
      { name: 'Poplin', url: 'http://poplin.co' },
      { name: 'Swagbucks', url: 'https://swagbucks.com' },
      { name: 'Prop Money Inc.', url: 'http://propmoney.com' },
      { name: 'Real Geeks', url: 'http://www.realgeeks.com' },
      { name: 'Sesame Care', url: 'http://sesamecare.com/' },
      { name: 'Simply Sensitivity Checks', url: 'http://www.simplyhealthchecks.com/' },
      { name: 'Skillshare', url: 'http://www.skillshare.com' },
      { name: 'SkullJewelry.com', url: 'https://SkullJewelry.com' },
      { name: 'Sports Brands Inc', url: 'http://www.sportsbrandsinc.com' },
      { name: 'Sentrypc.com', url: 'https://www.sentrypc.com' },
      { name: 'Star Stable', url: 'http://www.starstable.com' },
      { name: 'StealthWriter', url: 'http://stealthwriter.ai' },
      { name: 'Strawberry Hill Cheesecakes', url: 'https://strawberryhillcheesecakes.com' },
      { name: 'Cocoandeve.com', url: 'http://us.cocoandeve.com' },
      { name: 'Sandandsky.com', url: 'http://www.sandandsky.com' },
      { name: 'Temu', url: 'https://temu.to/m/ua9ecregg8z' },
      { name: 'The Hedgehog Company', url: 'http://www.thehedgehogcompany.com/' },
      { name: 'The Jäger Company', url: 'https://www.thejagercompany.com/' },
      { name: 'Tiny Land', url: 'http://tinylandus.com' },
      { name: 'Token Metrics Inc.', url: 'http://tokenmetrics.com' },
      { name: 'Tru Grit Fitness', url: 'http://trugrit-fitness.com/' },
      { name: 'Watches2U', url: 'http://www.watches2u.com' },
      { name: 'Wish', url: 'http://wish.com' },
      { name: 'XSTANCE', url: 'https://www.thexstance.com' },
      { name: 'Bellelily', url: 'https://www.bellelily.com' },
    ];
    

 
    return (<>
        {showPopup && (
          <div className="popupContainer">
            <div className="popupContainerInner">
            <h2>Enter the 6 digit code we sent to your email.</h2>
            <h4>(Be sure to check your spam folder)</h4>
            <div id="form">
            <input type="text" id="inputOne" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)} />
            <input type="text" id="inputTwo" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)}  />
            <input type="text" id="inputThree" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)}  />
            <input type="text" id="inputFour" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)}  />
            <input type="text" id="inputFive" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)}  />
            <input type="text" id="inputSix" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onFocus={handleFocus} onChange={(e) => setVerificationCode(e.target.value)}  />
            <button className="btn btn-primary btn-embossed" onClick={handleVerify}>Verify</button>
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
            </div>
          </div>
        )}
        {showConfetti && (
            <Confetti
              width={isMobile ? 450 : 2000}               
              height={1000}
              numberOfPieces={500}
              gravity={0.4}
          />
       )}
      <BrandSlider />
        <div style={{ marginBottom: '50px',marginTop: "40px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <div className='profileHeader'>
        <h1 className='appText'>Pinchcart™ Profile</h1>
        <button style={{ margin: '10px' }} onClick={handleSignOut}>Log Out</button> 

        </div>
        <div className="appcontainer">
        <div className="profileContainerTopCards">
            <div className="profileContainerTopCardLeft">
            {//user?.email ? <button onClick={handleSignOut}>Log Out</button> : <Link to='/signin'>SignIn</Link>
            }
            <h4 style={{ marginBottom: "10px" }}>{yourName}</h4>
            <h5 style={{ marginBottom: "10px" }}>{user.email}</h5>
            <h3 style={{ marginBottom: "10px" }} >Cash Back ID:</h3><h3 style={{ fontSize: "25px",color: "#ee4054" }}>{yourPid}</h3>

            </div>
            <div className="profileContainerTopCardRight">
            
           est. Cash Back Value <h1 style={{ fontSize: "90px", color: "#00FF7F"}}>${currentCashbackValue}</h1>
            </div>
        </div>

        <div className="profileContainerBottomCards">
            
            <div className="profileContainerBottomCardLeft">
            {//user?.email ? <button onClick={handleSignOut}>Log Out</button> : <Link to='/signin'>SignIn</Link>
            }
            
            <h3 style={{ marginBottom: "20px", marginTop: "20px" }} >Payment Method</h3>
            <h3 style={{ marginBottom: "0px" }} >Cash App</h3>
            <h3 style={{ fontSize: "25px",color: "#ee4054", marginTop: '20px' }}>{paypay || <form onSubmit={updatePayment}>
                <input style={{ textAlian: 'left', alignItems: 'left', width: '270px', borderRadius: '20px' }} type="text" value={itemId} onChange={(e) => setItemId(e.target.value)} placeholder="$CashTag"></input>
                <button>{showLoader ? <Loader className="spinner" /> : 'Set Payment'}</button>
            </form> }</h3>
            </div>
            <div className="profileContainerBottomCardRight">
            <div className="scrollingList">Cash Back Shopping 
        {affiliates.map((affiliate, index) => (
          <a key={index} href={affiliate.url} target="_blank" rel="noopener noreferrer">
            {affiliate.name}
          </a>
        ))}
      </div>           
       </div>
        </div>
        
        </div>

        </div>        
        <footer>
	        <p>
            © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
	        </p>
        </footer>
        </>
    );
  }

import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import './App.css';
import './firebase';
import Header from './Header';
import HotSlider from './components/HotSlider';
import CardSlider from './components/CardSlider';
import CouponSlider from './CouponSlider';
import Popup from './PopupEmail';
import CounterCard from './CounterCard';
import Local from "./Local";

import BrandSlider from "./components/BrandSlider";
import Footer from "./components/Footer";
import { useLocation } from "./LocationProvider";
import Work from "./components/Work";
import Join from "./components/Join";
import PrivateRoute from "./PrivateRoute";
import PostAd from "./PostAd";
import NotFound from "./NotFound";

import PostImage from './PostImage';
import { AuthProvider } from "./AuthContext";
import About from "./components/About";
import Terms from "./components/Terms";
import Goods from "./components/Goods";
import Travel from "./components/Travel";
import Coupons from "./components/Coupons"; 
import DailyDeals from "./components/DailyDeals";
import GreatGifts from "./components/GreatGifts";
import Cashback from "./components/Cashback";
import Profile from "./components/Profile";
import Login from "./components/Login";
import ContactUs from "./components/ContactUs";
import DataURL from "./components/DataURL";
import Private from "./components/Private";
import MobileMessage from "./components/MobileMessage";
import MobileSuccess from "./components/Success";
import Forgotpassword from "./components/Forgotpassword";
import Landing from "./components/Landing";

function App() {


//get user city, state, country, zipcode, lati, long, ip address
const { updateDetails, details } = useLocation();

const GetUserGeoLocationDetails = () => {
  fetch("https://geolocation-db.com/json/a1fee630-6949-11ee-9e0b-c1364ca7997e")
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      updateDetails(data);
     
    })
    .catch(error => {
      //console.error('Error fetching data:', error);
    });
}
if (details){
  localStorage.setItem('userCity',details.city);
  localStorage.setItem('userState',details.state);
  }

useEffect(() => {
  GetUserGeoLocationDetails(); 

}, []);


//const db = firebase.firestore();
// Reference the "newsletterEmail" collection
//const newsletterCollection = db.collection('newsletterEmail');
// Query the documents in the collection
//newsletterCollection.get()
//  .then((querySnapshot) => {
//    querySnapshot.forEach((doc) => {
//      const data = doc.data();
 //     console.log('Data from "newsletterEmail" collection:', data);
 //   });
 // })
 // .catch((error) => {
 //   console.error('Error retrieving data:', error);
 // });
  return (
    <>
    <div className="App">
   <AuthProvider>
      <Router>
      
        <Routes>
          <Route exact path="/" element={
            <>
            <Landing />
           {/* <Header details={details}/> 
            <CouponSlider />
            <BrandSlider />
            <HotSlider/>           
            <CardSlider />
            <Popup />
          <Footer /> */}
            </> 
            } />
      
      <Route />
          <Route path="/local/:state/:city/:category/" element={<Local />} />
          <Route path="/goods/:category/" element={<Goods />} />
          <Route path="/goods" element={<Goods />} />
          <Route path="/local" element={<Cashback />} />
          <Route path="/cashbackqueen" element={<Cashback />} />
          <Route path="/success" element={<MobileSuccess />} />
          <Route path="/beta" element={<Cashback />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/cashback" element={<Cashback />} />
          <Route path="/travel/:category/" element={<Travel />} />
          <Route path="/coupons/:category/" element={<Coupons />} />
          <Route path="/dailydeals" element={<DailyDeals />} />
          <Route path="/greatgifts" element={<GreatGifts />} />
          <Route path="/local" component={Local} />
          <Route path="/goods" component={Goods} />
          <Route path="/work" element={<Work />} />
          <Route path="/userdata" element={<DataURL />} />
          <Route path="/join" element={<Join />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path='/about' element={<About />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/private' element={<Private />} />
          <Route path='/userdata' element={<ContactUs />} />
          <Route path="/postimage" element={<PostImage />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/mobilemessage" element={<MobileMessage />} />
          <Route path="*" element={<NotFound />} />
         <Route element={<PrivateRoute />} >
          <Route path="/postad" element={<PostAd />} />
         </Route>
          </Routes>
         
          </Router>
          </AuthProvider>
    </div>
    </>
  );
}

export default App;

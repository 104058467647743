import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import {db} from '../firebase';
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import './Join.css';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Link } from 'react-router-dom';
import { ReactComponent as Loader } from '../loader.svg';
import { useLocation } from "../LocationProvider";
import {useAuth} from "../AuthContext";
import { useNavigate } from "react-router-dom";


const errorMessage = document.getElementById("lblErrorMessage");


const initialState = {
    joinName: '',
    joinEmail: '',
    joinPassword: '',
    loginEmail: '',
    loginPassword: ''

}
  export default function DataURL() {
   
    const { details } = useLocation();
    const [formData, setFormData] = useState(initialState);
    const [showLoader, setShowLoader] = useState(false);
   

    const sendRequest = async (e) => {
        e.preventDefault();
        setShowLoader(true);

        //try
       // {
            //const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            //const { uid, email } = userCredential.user;
            //const getUserData = collection(db, 'Users');
            //const querySnapshot = await getDocs(query(getUserData, where('Email', '==', loginEmail)));

            //if (querySnapshot.size === 1) {
            // Assuming there is only one document with the given email
           // const docData = querySnapshot.docs[0].data();
           // const { PID, id } = docData;
      
          //  console.log('User UID', uid);
          //  console.log('User Email', email);
          //  console.log('PID:', PID);
          //  console.log('ID:', id);
         //   } else {
            // Handle the case where there are multiple or no documents with the given email
          //  console.error('User not found or multiple users with the same email.');
          //  }

         // } catch (error) {
         //   console.error('Error signing in:', error);
         // } finally {
          //  setShowLoader(false);
         // }

}

   // const handleSubmit = async (e) => {
     //   e.preventDefault();
        

       // setShowLoader(true);
       // const docData = {
       //     Name: formData.joinName,
       //     Email: formData.joinEmail,
       //     Password: formData.joinPassword,
       //     DateTime: new Date(),
       //     Ipaddress: details.IPv4,
       //     City: details.city,
       //     Country_Code: details.country_code,
        //    Country_Name: details.country_name,
         //   Latitude: details.latitude,
         //   Longitude: details.longitude,
         //   State: details.state,
         //   Postal_Code: details.postal, 
        
      //  }     


    //};
    return (<>

        <div id='deleteDataFomr'>
        <h1 className='joinText'>Pinchcart</h1>
        <div className="deletecontainer">
        <form onSubmit={sendRequest}>
            <h1 className='createAccount'>Delete Data Request</h1>
			<span style={{marginBottom: '30px', marginTop: '30px'}}>By deleting your account data you forfit any/all cash back payments from this account.  You will not be able to use the pinchcart extentions for any browser. We will send you a validation code for your account data delete request.  Please validate by email that you want your data deleted.</span>
            <button type='submit' id='loginButton' style={{marginBottom: '30px', marginTop: '30px',transition: 'opacity 0.3s ease',}} > {showLoader ? <Loader className="spinner" /> : 'Delete data'}</button>

        </form>
        </div>
        </div>

        <footer>
	        <p>
            © 2023-2024, Pinchcart.com, or its affiliates.
	        </p>
        </footer>
        </>
    );
  }

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
    
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
            >
            Pinchcart
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Terms and Conditions
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Terms and Conditions
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            These terms and conditions ("Terms") govern your use of the Pinchcart website and browser extentions, located at https://www.pinchcart.com (the "Site"). By accessing or using our services/applications, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please refrain from using the Site.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
              1. Use of the Site
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            1.1.<br />You must be at least 18 years old or the legal age of majority in your jurisdiction to use our Site. By using our Site, you represent and warrant that you meet this requirement.<br />
            1.2.<br /> You agree not to use our Site for any unlawful or prohibited purpose, including but not limited to violating any applicable laws and regulations.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            2. Intellectual Property
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            2.1. <br />The content on our Site, including text, images, graphics, logos, and other materials, is protected by copyright and other intellectual property rights. You may not use, reproduce, or distribute any content from our Site without our prior written consent.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            3. User Accounts
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            3.1.<br /> If you create an account on our Site, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>


          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            3. Cash Back
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            3.1.<br /> Any Cash Back value listed in user accounts is not valid until verification.  First $10 account deposit in only valid when user make first valid cash back purchase.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>

          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            4. Privacy Policy
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            4.1. <br />Your use of our Site is also governed by our Privacy Policy, which can be found at Privacy Policy. By using our Site, you consent to the practices described in the Privacy Policy.           </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>

          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            5. Limitation of Liability
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            5.1.<br /> PinchCart and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages resulting from your use or inability to use our Site/Extentions/Applications or services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.</Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>

          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            6. Changes to the Terms
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            6.1.<br /> We reserve the right to modify these Terms at any time. We will post the updated Terms on our Site, and the date of the latest revision will be indicated at the top of this page. Your continued use of our Site after the revised Terms are posted constitutes your acceptance of the changes.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            7. Termination
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            7.1.<br /> We may, at our discretion, terminate or suspend your access to the Site, without notice, for any reason, including breach of these Terms.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            8. Governing Law
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            8.1.<br /> These Terms are governed by and construed in accordance with the laws of Texas, without regard to its conflict of law principles.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
            9. Contact Information
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            9.1. <br />If you have any questions or concerns about these Terms, please contact us at ceo@pinchcart.com 
           </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
        </Box>
        
      
     
    </ThemeProvider>
  );
}
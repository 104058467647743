import React from 'react';
import { Navigate, Outlet} from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import your authentication context

const PrivateRoute = ({ children }) => {
  const { user } = useAuth(); // Assuming you have an AuthContext that provides user information

  if (!user) {
    return <Navigate to='/login' />;
  }

  return children

  //return user ? (
  //  <Outlet />
  //) : (
  //  <Navigate to="/work" replace /> // Redirect to the login page if the user is not authenticated
  //);
}

export default PrivateRoute;

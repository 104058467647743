
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, signOut } from "firebase/auth";
import './PostAd.css';
import {db} from './firebase';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore'; // Import collection and addDoc functions
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as Loader } from './loader.svg';
import { ReactComponent as Loader50 } from './components/loader50x50.svg';
import { categories, subcategories, states, citiesData } from './components/Data';
import SellIcon from '@mui/icons-material/Sell';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase'; // Import your Firebase storage instance

function PostAd() {


  const [isSolid, setIsSolid] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 200, height: 200 });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const [showInstagramIcon, setShowInstagramIcon] = useState(false);
  const [showTwitterIcon, setShowTwitterIcon] = useState(false);
  const [showFacebookIcon, setShowFacebookIcon] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const shareUrl = 'http://pinchcart.com';
  const title = 'Pinchcart';
  const related = ['CeoPinchcart','reurldotus'];





  const handleImageDimensionsChange = (e) => {
    const { name, value } = e.target;
    setImageDimensions((prevDimensions) => ({
      ...prevDimensions,
      [name]: value ? parseInt(value) : '', // Set to an empty string if value is empty
    }));
  };



const handleIconClick = () => {
  setIsSolid(!isSolid);
};
const handleShareClick = () => {
  if (showSocialIcons) {
    // If social icons are visible, initiate bounce and slide-out animations
    setTimeout(() => {
      setShowTwitterIcon(false);
    }, 0);
    setTimeout(() => {
      setShowInstagramIcon(false);
    }, 250);
    setTimeout(() => {
      setShowFacebookIcon(false);
    }, 500);

    setTimeout(() => {
      setShowSocialIcons(false);
    }, 500); // Adjust the time to match your animation duration
  } else {
    // If social icons are not visible, initiate bounce and slide-in animations
    setButtonClicked(!showSocialIcons);
    setShowSocialIcons(true);

    setTimeout(() => {
      setShowFacebookIcon(true);
    }, 0);

    setTimeout(() => {
      setShowInstagramIcon(true);
    }, 250);

    setTimeout(() => {
      setShowTwitterIcon(true);
    }, 500);
  }
};



  const couponRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategoriesForCategory, setSubcategoriesForCategory] = useState([]);
  const [showSelectors, setShowSelectors] = useState(true);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const MAX_DISPLAY_LENGTH = 23; // Maximum number of characters to display
  const auth = getAuth();
   // Assuming formData.affiliate_url is the full affiliate link

   const showSuccess = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000); // 3000 milliseconds (3 seconds)
  };

  const handleCategoryChange = (e) => {
  const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    setSelectedSubcategory('');
    setSubcategoriesForCategory(subcategories[selectedCategory] || []);
    setShowSelectors(true);
  };
  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    setShowSelectors(true);
  
  };

  const handleReset = () => {
    setSelectedCategory('');
    setSelectedSubcategory('');
    setShowSelectors(true);
    setSelectedState('');
  };
  
  

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setShowSelectors(true);
    setSelectedCity(''); // Reset city selection when the state changes
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedCity(selectedCity);
  };

// Render state and city selectors
const stateOptions = states.map((state) => (
    <option key={state} value={state}>
      {state}
    </option>
  ));

  const cityOptions = selectedState
    ? citiesData[selectedState].map((city) => (
        <option key={city} value={city}>
          {city}
        </option>
      ))
    : null;

    const calculateDiscountPercentage = (salePrice, regularPrice) => {
        if (!salePrice || !regularPrice) return 0;
        const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
        return Math.floor(discountPercentage);
      };
      
      

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful. You can navigate to a different page or update your UI.
    })
    .catch((error) => {
      // An error occurred. Handle it appropriately.
      console.error('Error signing out:', error);
    });
};


useEffect(() => {
    const handleClick = (event) => {
      const el = event.target.closest('.coupon.onclick');
      if (el) {
        el.classList.toggle('active');

      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  
  const initialFormData = {
    title: '',
    description: '',
    price: '',
    image_url: '',
    vendor: '',
    vendor_address: '',
    affiliate_url: '',
    brand: '',
    percent_off: '',
    end_date: '',
    start_date: '',
    category: '',
    sub_category: '',
    sale_price: '',
    state: '',
    city: '',
    coupon_code: '',
  };

  const initialState = {
    selectedState: '',
    selectedCity: '',
    selectedCategory: '',
    selectedSubcategory: '',
  };

  const resetFormData = () => {
    setFormData(initialFormData);
    setSelectedState(initialState.selectedState);
    setSelectedCity(initialState.selectedCity);
    setSelectedCategory(initialState.selectedCategory);
    setSelectedSubcategory(initialState.selectedSubcategory);
  };  

  
  
    const [formData, setFormData] = useState(initialFormData);
    const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    // Calculate the discount percentage
    if (name === "sale_price" || name === "price") {
      const salePrice = parseFloat(updatedFormData.sale_price);
      const regularPrice = parseFloat(updatedFormData.price);
      updatedFormData.percent_off = calculateDiscountPercentage(salePrice, regularPrice);
    }
    updatedFormData.state = selectedState;
    updatedFormData.city = selectedCity;
    updatedFormData.category = selectedCategory;
    updatedFormData.sub_category = selectedSubcategory;
    
    setFormData(updatedFormData);
  };

  const hashtag = formData.brand;
  const Twitterquate = formData.title;
  const fullAffiliateLink = formData.affiliate_url;
  const price = formData.price;
  const saleprice = formData.sale_price;
  //Check if the price contains a decimal point
  const hasDecimalPoint = price.toString().includes(".");
  const salepricehasDecimalPoint = saleprice.toString().includes(".");

  let integerPart = 0;
  let saleintegerPart= 0;
  let salewholePart = saleprice;
  let wholePart = price;

  if (hasDecimalPoint) {
  // Split the price by the decimal point and get the part after the decimal point
    const parts = price.toString().split(".");
    if (parts.length > 1) {
    integerPart = parseInt(parts[1]);
    wholePart = parseInt(parts[0]);
    }
  }

  if (salepricehasDecimalPoint){
    const salepart = saleprice.toString().split(".");
    if (salepart.length > 1){
      saleintegerPart = parseInt(salepart[1]);
      salewholePart = parseInt(salepart[0]);
    }
  }

  if (integerPart >= 0 && integerPart <= 9) {
    integerPart = `0${integerPart}`;
  }

  if (saleintegerPart >= 0 && saleintegerPart <= 9){
    saleintegerPart = `0${saleintegerPart}`;
  }

// Display only the first 30 characters (or less if the link is shorter)
  const displayedAffiliateLink = fullAffiliateLink.length > MAX_DISPLAY_LENGTH
  ? `${fullAffiliateLink.slice(0, MAX_DISPLAY_LENGTH)}...`
  : fullAffiliateLink;


  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const likeCount = 0;
    try{
      const productDealsCollection = collection(db, 'Product_Deals'); 

      const docData = {
        title: formData.title,
        description: formData.description,
        price: formData.price,
        sale_price: formData.sale_price,
        image_url: formData.image_url,
        vendor: formData.vendor,
        vendor_address: formData.vendor_address,
        affiliate_url: formData.affiliate_url,
        brand: formData.brand,
        percent_off: formData.percent_off,
        start_date: formData.start_date,
        end_date: formData.end_date,
        category: selectedCategory,
        subcategory: selectedSubcategory,
        city: selectedCity,
        state: selectedState,
        coupon_code: formData.coupon_code,
        img_width: imageDimensions.width,
        img_height: imageDimensions.height,
        likeCount: likeCount,
        DateTime: new Date(),
      }

      const docRef = await addDoc(productDealsCollection, docData);
      const newDocumentId = docRef.id;

      const response = await fetch(formData.image_url);
      
    const imageData = await response.blob();
  
      const storageRef = ref(storage, `productImages/${newDocumentId}`);
       await uploadBytes(storageRef, imageData);
       const imageUrl = await getDownloadURL(storageRef);
      const documentToUpdate = doc(db, 'Product_Deals', newDocumentId);

      await updateDoc(documentToUpdate, { id: newDocumentId, image_url: imageUrl });
      console.log("data successfully posted to Firebase");
      //clear form data 
      resetFormData();
      showSuccess();
    } catch (error) {
      console.error("error posting data to firestore", error);
    } finally {
      setShowLoader(false);
    }

    
  };


  return (
  <>
  <div className='Box-post'>
    <div className='left-post'>
    <div className='post-ad-container'>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td className="label-cell"><label>Title:</label></td>
              <td><input required className="input" type="text" name="title" maxLength="56" value={formData.title} onChange={handleInputChange} placeholder='max 55'/></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Description:</label></td>
              <td><textarea required  className='input' name="description" maxLength="250" value={formData.description} onChange={handleInputChange} placeholder='max 250'/></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Old Price:</label></td>
              <td><input required className="input" type="text" name="price" value={formData.price} onChange={handleInputChange} placeholder='just numbers no $'/></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Sale Price:</label></td>
              <td><input required className="input" type="text" name="sale_price" value={formData.sale_price} onChange={handleInputChange} placeholder='just number no $' /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Percent % Off:</label></td>
              <td><input required  className="input" type="text" name="percent_off" value={formData.percent_off} onChange={handleInputChange} placeholder='Auto Formated '/></td>
            </tr>
            <tr>
              <td colSpan={2} className="label-cell"><label>Image URL:</label></td>
              </tr>
              <tr>
              <td colSpan={2}><input required  className="input" type="text" name="image_url" value={formData.image_url} onChange={handleInputChange} placeholder='https://example.com/img.jpg' />
            
              <input required  className="input" type="text" name="width" value={imageDimensions.width} onChange={handleImageDimensionsChange} placeholder='w-adjust' /> width
              <input required  className="input" type="text" name="height" value={imageDimensions.height} onChange={handleImageDimensionsChange} placeholder='h-adjust' /> height</td>
        
            </tr>
            
            <tr>
              <td className="label-cell"><label>Vendor:</label></td>
              <td><input required  className="input" type="text" name="vendor" value={formData.vendor} onChange={handleInputChange} placeholder='Amazon' /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Vendor Address:</label></td>
              <td><input required  className="input" type="text" name="vendor_address" value={formData.vendor_address} onChange={handleInputChange} /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Affiliate URL:</label></td>
              <td><input required  className='input' type="text" name="affiliate_url" value={formData.affiliate_url} onChange={handleInputChange} /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Brand:</label></td>
              <td><input required  className="input" type="text" name="brand" value={formData.brand} onChange={handleInputChange} /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>Start Date:</label></td>
              <td><input required  className="input" type="date" name="start_date" value={formData.start_date} onChange={handleInputChange} /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>End Date:</label></td>
              <td><input required  className="input" type="date" name="end_date" value={formData.end_date} onChange={handleInputChange} /></td>
            </tr>
            <tr>
              <td className="label-cell"><label>
                Select a Category</label></td>
              <td>
    <div>


      {showSelectors ? (
        <div>
          <select required style={{height: '45px'}} className='input' id="categorySelect" onChange={handleCategoryChange} value={selectedCategory}>
            <option value="">Choose a category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>

          {selectedCategory && (
            <div>
              <select
              required 
              style={{height: '45px'}}
              className='input'
              id="subcategorySelect"
              onChange={handleSubcategoryChange}
              value={selectedSubcategory}
              >
              {selectedSubcategory ? (
              <option disabled value={selectedSubcategory}>
              {selectedSubcategory}
              </option>
              ) : (
              <option value="">Choose a subcategory</option>
              )}
              {subcategoriesForCategory.map((subcategory, index) => (
              <option key={index} value={subcategory}>
              {subcategory}
              </option>
              ))}
              </select>
            </div>
          )}
        </div>
      ) : (
        <button onClick={handleReset}>Reset</button>
      )}
      {selectedCategory && selectedSubcategory && (
        <div>
          Selected Category: {selectedCategory}, Selected Subcategory: {selectedSubcategory}
        </div>
      )}
    </div>
          </td>
            </tr>
            <tr>
            <td className="label-cell"><label>
                Select a State</label></td>
              <td>
              <div>
      <select  style={{height: '45px'}} required  className='input' onChange={handleStateChange} value={selectedState}>
        <option value="">Choose a state</option>
        {stateOptions}
      </select>

      {selectedState && (
        <div>
          <label>Select a city:</label>
          <select required  style={{height: '45px'}} className='input' onChange={handleCityChange} value={selectedCity}>
            <option value="">Choose a city</option>
            {cityOptions}
          </select>
        </div>
      )}

{selectedState && selectedCity && (
        <p>
          Selected State: {selectedState}, selectedCity: {selectedCity}
        </p>
      )}
      </div>
          </td>
          </tr>
            <tr>
              <td className="label-cell"><label>Coupon Code:</label></td>
              <td><input required  className="input" type="text" name="coupon_code" value={formData.coupon_code} onChange={handleInputChange} /></td>
            </tr>
          </tbody>
        </table>
        <div style={{justifyContent: 'center', marginTop: '50px'}}>
            {showSuccessMessage && (
            <div className="success-message">Upload to the Database Successful</div>
              )}
        <button type="submit" className='button-ad'>  {showLoader ? <Loader className="spinner" /> : 'Submit'}</button>

        
        </div>
      </form>
    </div>
</div>
<div className='right-post'>
<Card sx={{ maxWidth: 300, minWidth: 300, minHeight: 350, borderRadius: '15px',
 }}>

    <div className='icon-containter'>
      <i className="heart-icon" onClick={handleIconClick}>
      {isSolid ? <FavoriteIcon className='solidIcon' sx={{ fontSize: 30}}  /> : <FavoriteBorderIcon  className='outlineIcon' sx={{ fontSize: 30}} />}
      </i>
      <i className='share-icon' onClick={handleShareClick}>
      {isShare ? <ShareIcon className='solidIconShare' sx={{ fontSize: 30}} /> : <ShareOutlinedIcon  className='outlineIconShare' sx={{ fontSize: 30}} />}
      </i>
            {showSocialIcons && (
              <div className='visible'> 
              <i className="fab fa-facebook">
                <FacebookShareButton url={shareUrl} quote={title} hashtag={hashtag}>
                <FacebookRoundedIcon className="FacebookIcon" sx={{ fontSize: 30, marginLeft: '25px'}} /> 
                </FacebookShareButton>
             </i>
             </div>
              )}
             {showInstagramIcon && (
              <div className='visible'> 
                <i className="fab fa-instagram">
                <PinterestShareButton>
                  <PinterestIcon className="PintrestIcon" sx={{ fontSize: 30, marginLeft: '25px'}} />  
                  </PinterestShareButton>
                </i>
                </div>
              )}
              {showTwitterIcon && (
              <div className='visible'>
                 <i className="fab fa-twitter"><TwitterShareButton url={shareUrl} title={Twitterquate} via={related.join(',')}>
                  <TwitterIcon className="TwitterIcon" sx={{ fontSize: 30, marginLeft: '25px'}} />
                  </TwitterShareButton>
                 </i>  
              </div>

         )}
    </div>

  <CardMedia sx={{ height: 250, mt: '30px',mb:'-40px' }}>
    
  {!formData.image_url ? <Loader50 className="spinner" sx={{fontSize: '40'}} /> : ''}
    <img
      src={formData.image_url}
      width={imageDimensions.width}
      height={imageDimensions.height}
      className="card-img-top"
      alt="..."
      title={formData.brand}
    />
  </CardMedia>
  <CardContent
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    }}
  >
    <Typography  gutterBottom variant="h6" component="div" fontWeight={600} align="left">
      {formData.title}
    </Typography>
    
        <div style={{ marginBottom: '-10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          <strike style={{  marginRight:'10px',color: 'red',fontSize: '20px', fontWeight: '600' }}>
            <span>
               ${wholePart}
            </span>
           
            {(isNaN(integerPart) || integerPart == 0) ? null : (  
            <span style={{ color: 'red',fontSize: '12px', fontWeight: '300' }}>
            {integerPart}
            </span>
            )}
           </strike>
            <div className="coupon-post">
              <div ref={couponRef} className="coupon onclick" data-link={formData.affiliate_url}>
                <div className="code">
                  <h2>{formData.coupon_code}</h2>
                </div>
                  <div className="mask">Use Code</div>
                </div>
              </div>
              <div style={{ color: 'green',fontSize: '20px', fontWeight: '600' }}>
                <span>${salewholePart}</span>
              {(isNaN(saleintegerPart) || saleintegerPart == 0) ? null : (
              <span style={{ color: 'green',fontSize: '12px', fontWeight: '300' }}>
              {saleintegerPart}
              </span>
              )}
              </div>
          </div>
</CardContent>
</Card>

<div style={{position: 'absolute',top: '9px', left: '-23px'}}>
    <SellIcon sx={{position: 'absolute', color: 'red', marginTop: '5px',
      marginLeft: '0px',
      fontWeight: '900',
      width: '70px',
      height: '70px',
      padding: '5px',
      fontSize: '14px',
      fontWeight: '900',
      left: '290px',
      top: '-8px',
      rotate: '45deg',
      
  }}
>

</SellIcon>
<span className='redtaging' style={{position: 'absolute',color: 'white', left: '315px', top: '25px',rotate: '0deg', fontSize: '12px', fontWeight:'900'}}
>{formData.percent_off}%<br/>OFF</span>

</div>

      <div style={{marginTop: "10px", textAlign: "left"}}>Vender: <b>{formData.vendor}</b></div> 
      <div style={{marginTop: "10px", textAlign: "left"}}>Vender Address: <b>{formData.vendor_address}</b></div> 
      <div style={{marginTop: "10px", textAlign: "left"}}>Brand: <b>{formData.brand}</b></div> 
      <div style={{marginTop: "30px", textAlign: "left"}}>Start Date: <b>{formData.start_date}</b></div> 
      <div style={{marginTop: "10px", textAlign: "left"}}>End Date: <b>{formData.end_date}</b></div> 
      <div style={{marginTop: "30px", textAlign: "left"}}>Category: <b>{selectedCategory}</b></div> 
      <div style={{marginTop: "10px", textAlign: "left"}}>Sub Category: <b>{selectedSubcategory}</b></div> 
      <div style={{marginTop: "30px", textAlign: "left"}}>State: <b>{selectedState}</b></div> 
      <div style={{marginTop: "10px", textAlign: "left"}}>City: <b>{selectedCity}</b></div> 
      <div style={{marginTop: "20px", textAlign: "left"}}>Coupon Code: <b>{formData.coupon_code}</b></div> 
      <div style={{marginTop: "20px", textAlign: "left"}}>Affiliate Link: <b>{displayedAffiliateLink}</b></div> 

    </div>
 
</div>
</>
  );
}

export default PostAd;
import React, { useState, useEffect } from 'react';
import '../components/tab.css';
import { Link } from 'react-router-dom';
import { useStateChange } from '../StateChangeContext';
import expedia from "../components/img/maxresdefault.jpg";
import { ReactComponent as Loader } from '../loader.svg';



const Tabs = ( props ) => {
  const userCity = localStorage.getItem("userCity");
  const userState = localStorage.getItem("userState");
  const [imageLoaded, setImageLoaded] = useState(false);
    const { setStateChanged } = useStateChange();
    const [showTabs, setShowTabs] = useState(true); // State variable to control visibility
    const handleLinkClick = () => {
    // Set the stateChanged flag to true when a link is clicked
    setStateChanged(true);
    // Hide the tabs container when a link is clicked
    setShowTabs(false);
  };
  
  const handleMouseLeave = () => {
    setStateChanged(true);
    setShowTabs(false);
  };
     // Define default state and city values
    const defaultState = 'Texas'; // Replace with your desired default state
    const defaultCity = 'Dallas'; // Replace with your desired default city
    ///////////////////////////////////////////
    const gaspass = 'Beaty & Spas';
    const categories = ['Face & Skin Care', 'Massage', 'Salons', 'Spas', 'Hair & Styling', 'Makeup', 'Hair Removal', 'Nail Salons', 'Blowouts & Styling', 'Tanning'];    
      
    const gaspassTwo = 'Thinks To Do';
    const categoriesTwo = ['Kids Activites', 'Fun & Leisure', 'Tickets & Events', 'Sports & Outdoors', 'Nightlife'];    
   
    const gaspassThree = 'Food & Drink';
    const categoriesThree = ['Restaurants', 'Breweries Wineries & Distilleries', '', 'Cakes & Treats', 'Groceries & Markets'];    

    const gaspassFour = 'Health & Fitness';
    const categoriesFour = ['Gyms', 'Weight Loss & Fitness Classes', 'Medical', 'Natural Medicine', 'Sports', 'Dental', 'Vision'];    
   
    const gaspassFive = 'More'; 
    const categoriesFive = ['Automotive', 'Home Service & Meal Prep', 'Online Learning', 'Personal Servies', 'Personalized Items', 'Retail', 'Pet Adoption'];    
    /////////////////////////////////////////////////////
    const goodspass = 'Health & Beauty';
    const goodscategory = ['Aromatherapy', 'Bath & Baby', 'Cosmetics', 'Fragrance', 'Hair Care', 'Massage', 'Mens', 'Oral Care', 'Personal Care', 'Shaving & Grooming', 'Skin Care', 'Vitamins & Supplements'];    

    const goodspassTWO = 'Clothing, Shoes, Jewelry & Watches';
    const goodscategoryTwo = ['Watches & Jewelry','Fashion', 'Women', 'Men', 'Girls', 'Boys', 'Baby', 'Luggage', 'Luxury'];    

    const goodspassTHREE = 'Home & Garden';
    const goodscategoryTHREE = ['Tools','Discover', 'Decor', 'Furniture', 'Kitchen & Dining', 'Bed & Bath', 'Garden & Outdoor', 'Lighting', 'Appliances', 'Pet Supplies'];    

    const goodspassFOUR = 'Electronics';
    const goodscategoryFOUR = ['Computers', 'TV & Video', 'Home Audio & Theater', 'Camera Photo & Video', 'Cell Phones & Accessories', 'Headphones', 'Games', 'Car Electronics', 'Musical', 'Wearable'];    

    const goodspassFIVE= 'More';
    const goodscategoryFIVE = ['Sports', 'Outdoors', 'Automotive', 'Food & Grocery', 'Toys Kids & Baby', 'Industrial & Scientific', 'Books', 'Software'];    
    ///////////////////////////////////////////// 
    const couponpass= 'Brands';
    const couponcategory = ['Amazon', 'BestVetCare', 'Namecheap', 'Watchs2u', 'Pin Paws', 'Tru Grit', 'Jegs', 'PishPosh', 'Token Metrics','A1Supplements', 'Magic Carpet', 'EyeGlasses.com', 'Duracell'];    
    
    const couponpassTwo= 'Category';
    const couponcategoryTwo = ['Food', 'Goods', 'Electronics', 'Pet Supplies', 'Eye Wear', 'Home & Garden'];    
    
    
    ///////////////////////////////////
    const travelcategory = ['Air-inclusive Trips', 'Beach', 'Premium', 'Family Fun', 'International & Tours', 'Under $99'];    
    const travelcategoryTWO = ['New Deals', 'Hotels', 'Flights', 'Car Rentals', 'AirBnb'];    



    const [activeTab, setActiveTab] = useState('Local');
    const openCity = (cityName) => {
        setActiveTab(cityName);
    };
    
  
    let state, city;

if (!userCity && !userState) {
  state = defaultState;
  city = defaultCity;
} else {
  state = userState;
  city = userCity;
}



useEffect(() => {
  const image = new Image();
  image.src = expedia;
  image.onload = () => {
    setImageLoaded(true);
  };
}, []);


    return (
        <>
         {showTabs && (
        <div className='container-card' onMouseLeave={handleMouseLeave}>
            <div className='cat-card'>
                <div className='card-list'>
            <div className="categories">
                
                <a className={`category ${activeTab === 'Local' ? 'active' : ''}`}
                    onMouseOver={() => openCity('Local')}
                >
                   <span className='text'>Local</span>
                   <svg
                data-ds-type="Icon"
                width="24"
                height="24"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
                className='svg-icon'
              >
                <path d="M11.29 25.29a1 1 0 1 0 1.41 1.41l10-10a1 1 0 0 0 0-1.41l-10-10a1 1 0 1 0-1.41 1.41l9.3 9.3-9.3 9.29z"></path>
              </svg>
                </a>
                <a className={`category ${activeTab === 'Goods' ? 'active' : ''}`} onMouseOver={() => openCity('Goods')}
                >
                   <span className='text'>Goods</span><svg
                data-ds-type="Icon"
                width="24"
                height="24"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
                className='svg-icon'
              >
                <path d="M11.29 25.29a1 1 0 1 0 1.41 1.41l10-10a1 1 0 0 0 0-1.41l-10-10a1 1 0 1 0-1.41 1.41l9.3 9.3-9.3 9.29z"></path>
              </svg>
                </a>
                <a
                    className={`category ${activeTab === 'Travel' ? 'active' : ''}`}
                    onMouseOver={() => openCity('Travel')}
                >
                    <span className='text'>Hotels & Travel</span><svg
                data-ds-type="Icon"
                width="24"
                height="24"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
                className='svg-icon'
              >
                <path d="M11.29 25.29a1 1 0 1 0 1.41 1.41l10-10a1 1 0 0 0 0-1.41l-10-10a1 1 0 1 0-1.41 1.41l9.3 9.3-9.3 9.29z"></path>
              </svg>
                </a>
                <a
                    className={`category ${activeTab === 'Coupons' ? 'active' : ''}`}
                    onMouseOver={() => openCity('Coupons')}
                >
                    <span className='text'>Coupons</span><svg
                data-ds-type="Icon"
                width="24"
                height="24"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
                className='svg-icon'
              >
                <path d="M11.29 25.29a1 1 0 1 0 1.41 1.41l10-10a1 1 0 0 0 0-1.41l-10-10a1 1 0 1 0-1.41 1.41l9.3 9.3-9.3 9.29z"></path>
              </svg>
                </a>
                <hr />
                <Link to='/dailydeals' className='dailydeals'>
                    <span className='text'>Daily Deals</span>
                </Link>
                <Link to='/greatgifts' className='dailydeals'>
                    <span className='text'>Great Gifts</span>
                </Link>
            </div>
{/** local tab categories  */}
{/** local tab categories  */}
{/** local tab categories  */}
{/** local tab categories  */}            
{/** local tab categories  */}
            <div id="Local" className={`tabcontent ${activeTab === 'Local' ? 'active' : ''}`}>
            <div className="categories-l2">
                <span className="categories-l2-heading">Local
                <Link className="shop-all-link" 
                      to={`/local`} 
                      state={{selectedPage: 'local'}}
                      onClick={handleLinkClick}

                      >Shop All</Link>
                </span>
<div>
    <div className="l2-columns">
        <div className="categories-subcolumn  subnav-categories ">
            <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/local/${state}/${city}/beaty-and-spas`}
                state={{selectedCategory: gaspass, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}

              >
                Beauty &amp; Spas
              </Link>
            </span>
            {categories.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/local/${state}/${city}/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/local/${state}/${city}/Things-To-Do`}
                state={{selectedCategory: gaspassTwo, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}

              >
                Things To Do
              </Link>
            </span>
            {categoriesTwo.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/local/${state}/${city}/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/local/${state}/${city}/Food-and-Drinks`}
                state={{selectedCategory: gaspassThree, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                Food &amp; Drinks
              </Link>
            </span>

            {categoriesThree.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/local/${state}/${city}/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/local/${state}/${city}/Health-and-Fitness`}
                state={{selectedCategory: gaspassFour, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                Health &amp; Fitness
              </Link>
            </span>  
                {categoriesFour.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/local/${state}/${city}/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories  with-left-vertical-bar">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/local/${state}/${city}/More`}
                state={{selectedCategory: gaspassFive, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                More
              </Link>
            </span>  
            {categoriesFive.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/local/${state}/${city}/${category === "Retail" ? "Retail" : category.toLowerCase().replace(/\s+/g, '-')}`} 
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
         </div>
    </div>
</div>
</div>
</div>

{/** goods tab categories  */}
{/** goods tab categories  */}
{/** goods tab categories  */}
{/** goods tab categories  */}
{/** goods tab categories  */}

<div id="Goods" className={`tabcontent ${activeTab === 'Goods' ? 'active' : ''}`}>
            <div className="categories-l2">
                <span className="categories-l2-heading">Goods
                <Link className="shop-all-link" 
                      to={`/goods`} 
                      state={{selectedPage: 'goods'}}
                      onClick={handleLinkClick}

                      >Shop All</Link>
                </span>
<div>
    <div className="l2-columns">
        <div className="categories-subcolumn  subnav-categories ">
            <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/goods/health-and-beauty`}
                state={{selectedCategory: goodspass, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                Health &amp; Beauty
              </Link>
            </span>
            {goodscategory.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/goods/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span  className="subcategories-link l2-column-title">
          Clothing, Shoes, Jewelry & Watches
        </span>
            {goodscategoryTwo.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/goods/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">


        <span className="subcategories-link l2-column-title">
                Home, Garden &amp; Tools
            </span>

            {goodscategoryTHREE.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/goods/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/goods/electronics`}
                state={{selectedCategory: goodspassFOUR, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                Electronics
              </Link>
            </span>  
                {goodscategoryFOUR.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/goods/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories  with-left-vertical-bar">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/goods/more`}
                state={{selectedCategory: goodspassFIVE, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
              >
                More
              </Link>
            </span>  
            {goodscategoryFIVE.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/goods/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
         </div>
    </div>
</div>
</div>
</div>
       
{/** travel tab categories  */}
{/** travel tab categories  */}
{/** travel tab categories  */}
{/** travel tab categories  */}
{/** travel tab categories  */}
{/** travel tab categories  */}



<div id="Travel" className={`tabcontent ${activeTab === 'Travel' ? 'active' : ''}`}>
            <div className="categories-l2">
                <span className="categories-l2-heading">Hotels & Travel
                <Link className="shop-all-link" 
                      to={`/travel`} 
                      state={{selectedPage: 'travel'}}
                      onClick={handleLinkClick}

                      >Shop All</Link>                </span>
<div>
    <div className="l2-columns">
        <div className="categories-subcolumn  subnav-categories ">
            
            {travelcategory.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/travel/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
       
            {travelcategoryTWO.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/travel/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div style={{marginLeft: "40px", marginBottom: "50px"}}>
        <Link to="https://expedia.com/affiliate/rX7S6LQ">
      {imageLoaded ? (
        <img width={370} height={200} src={expedia} alt="https://expedia.com/affiliate/rX7S6LQ" />
      ) : (
      <Loader className='spinnerLarge' />
      )}
    </Link>
        </div>
    </div>
</div>
</div>
</div>

<div id="Coupons" className={`tabcontent ${activeTab === 'Coupons' ? 'active' : ''}`}>
            <div className="categories-l2">
                <span className="categories-l2-heading">Coupons
                <Link className="shop-all-link" 
                      to={`/coupons`} 
                      state={{selectedPage: 'coupons'}}
                      onClick={handleLinkClick}

                      >Shop All</Link>                </span>
<div>
    <div className="l2-columns">
        <div className="categories-subcolumn  subnav-categories ">
            <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/coupons/brands`}
                state={{selectedCategory: couponpass, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}

              >
                Brands
              </Link>
            </span>
            {couponcategory.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/coupons/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
        </div>
        <div className="categories-subcolumn  subnav-categories ">
        <span>
                <Link
                className="subcategories-link l2-column-title"
                to={`/coupons/category`}
                state={{selectedCategory: couponpassTwo, selectedState: state, selectedCity: city}}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}

              >
                Categories
              </Link>
            </span>
            {couponcategoryTwo.map((category, index) => (
                <Link
                key={index}
                className="subcategories-link"
                to={`/coupons/${category.toLowerCase().replace(/\s+/g, '-')}`}
                state={{selectedCategory: category, selectedState: state, selectedCity: city}}
                data-bhc={`category:${category.toLowerCase().replace(/\s+/g, '-')}`}
                role="menuitem"
                tabIndex="0"
                onClick={handleLinkClick}
                >
                {category}
                </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>             
    </div>
  </div>
</div>)}
        </>
    );
};

export default Tabs;

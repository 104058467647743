import React, { useState, useEffect, useRef } from 'react';
import './LocalPopdownCard.css';
import './Header.css'; // Import the CSS for styling
import logo from './components/logov2.png'
import { Link } from 'react-router-dom'; // Import useNavigate
import SearchIcon from '@mui/icons-material/Search';
import Tabs from './components/Tabs';

function Header({ details }) {
  const [isTabsVisible, setTabsVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isTabsVisibleRef = useRef(isTabsVisible);

 
  

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleTabsVisibility = () => {
    setTabsVisible(!isTabsVisible);
   // setTabsVisible(prevState => !prevState);
  };

    const state = localStorage.getItem("userState");
  const city = localStorage.getItem("userCity");

  return (
    <header>
    <div className="page-header"  style={{backgroundColor: "#ee4054"}}>
      <div className='logoheader-sm'>
        
      <img src={logo} 
      className="App-logo" 
      style={{height: "50px", width: "200px", marginBottom: "10px"}}
      alt='pinchcart logo' />
      </div>
      <div className="logoheader">
        <div className='logo-button-container'>
      <img src={logo}
       className="App-logo" 
       style={{height: "50px", width: "200px", marginBottom: "60px"}} 
       alt='pinchcart logo'/>
       <div>
       </div>
       </div>
      </div>

      <div className='form-search-div'>
        <input className='form-search' placeholder='Search Coming Soon' />
        <span className='icon'><SearchIcon /></span>
      </div>
     
    
    


      {//<button className={`menu-button ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}><div className="bar"></div><div className="bar"></div><div className="bar"></div></button>
      }
<nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`} style={{zIndex: "999"}}>
  <ul>
        <li className="local-item">Local
             <ul className="local-items">
              <li><Link 
                  to={`/local/${state}/${city}/beauty-and-spas`}
                 state={{selectedCategory: 'Beauty & Spas', selectedState: state, selectedCity: city}}
                 >Beauty & Spas</Link></li>
              <li><Link 
                 to={`/local/${state}/${city}/Things-To-Do`}
                 state={{selectedCategory: 'Things-To-Do', selectedState: state, selectedCity: city}}
                 >Things-To-Do</Link></li>
              <li><Link 
                 to={`/local/${state}/${city}/Food-and-Drinks`}
                 state={{selectedCategory: 'Food-and-Drinks', selectedState: state, selectedCity: city}}
                  >Food and Drinks</Link></li> 
              <li><Link 
                 to={`/local/${state}/${city}/Health-and-Fitness`}
                 state={{selectedCategory: 'Health-and-Fitness', selectedState: state, selectedCity: city}}
                  >Health and Fitness</Link></li> 
              <li><Link 
                 to={`/local/${state}/${city}/More`}
                 state={{selectedCategory: 'More', selectedState: state, selectedCity: city}}
                  >More...</Link></li>
                </ul>
        </li>
        <li className="goods-item">Goods
            <ul className="goods-items">
                <li className='hb'><Link 
                   to={`/goods/health-and-beauty`}
                    state={{selectedCategory: 'Health & Beauty'}}
                    >Health & Beauty</Link></li> 
                    <ul className="hbSub">
                      <Link to='/goods/aromatherapy'><li className="hbSubUl">Aromatherapy</li></Link>
                      <Link to='/goods/bath-&-body'><li className="hbSubUl">Bath & Baby</li></Link>
                      <Link to='/goods/cosmetics'><li className="hbSubUl">Cosmetics</li></Link>
                      <Link to='/goods/fragrance'><li className="hbSubUl">Fragrance</li></Link>
                      <Link to='/goods/hair-care'><li className="hbSubUl">Hair Care</li></Link>
                      <Link to='/goods/massage'><li className="hbSubUl">Massage</li></Link>
                      <Link to='/goods/mens'><li className="hbSubUl">Men's</li></Link>
                      <Link to='/goods/oral-care'><li className="hbSubUl">Oral Care</li></Link>
                      <Link to='/goods/personal-care'><li className="hbSubUl">Personal Care</li></Link>
                      <Link to='/goods/shaving-&-grooming'><li className="hbSubUl">Shaving & Grooming</li></Link>
                      <Link to='/goods/skin-care'><li className="hbSubUl">Skin Care</li></Link>
                      <Link to='/goods/vitamins-&-supplements'><li className="hbSubUl">Vitamins & supplements</li></Link>
                      </ul>
                <li><Link 
                  to={`/goods/clothing-shoes-jewelry-watches`}
                   state={{selectedCategory: 'Clothing +'}}
                    >Clothing, Shoes, Jewelry & Watches</Link></li>
                    <ul className="hbSub">
                    <Link to='/goods/fashion'><li className="hbSubUl">Fashion</li></Link>
                    <Link to='/goods/women'><li className="hbSubUl">Women</li></Link>
                    <Link to='/goods/men'><li className="hbSubUl">Men</li></Link>
                    <Link to='/goods/girls'><li className="hbSubUl">Girls</li></Link>
                    <Link to='/goods/boys'><li className="hbSubUl">Boys</li></Link>
                    <Link to='/goods/baby'><li className="hbSubUl">Baby</li></Link>
                    <Link to='/goods/luggage'><li className="hbSubUl">Luggage</li></Link>
                    <Link to='/goods/luxury'><li className="hbSubUl">Luxury</li></Link>
                      </ul> 
                <li><Link 
                  to={`/goods/home-and-garden`}
                   state={{selectedCategory: 'Home, Garden & Tools'}}
                    >Home, Garden & Tools</Link></li>
                    <ul className="hbSub">
                    <Link to='/goods/discover'><li className="hbSubUl">Discover</li></Link>
                    <Link to='/goods/decor'><li className="hbSubUl">Decor</li></Link>
                    <Link to='/goods/furniture'><li className="hbSubUl">Furniture</li></Link>
                    <Link to='/goods/kitchen-&-dining'><li className="hbSubUl">Kitchen & Dining</li></Link>
                    <Link to='/goods/bed-&-bath'><li className="hbSubUl">Bed & Bath</li></Link>
                    <Link to='/goods/garden-&-outdoor'><li className="hbSubUl">Garden & Outdoor</li></Link>
                    <Link to='/goods/lighting'><li className="hbSubUl">Lighting</li></Link>
                    <Link to='/goods/appliances'><li className="hbSubUl">Appliances</li></Link>
                    <Link to='/goods/pet-supplies'><li className="hbSubUl">Pet Supplies</li></Link>
                      </ul> 
                <li><Link 
                  to={`/goods/electronics`}
                   state={{selectedCategory: 'Electronics'}}
                    >Electronics</Link></li> 
                     <ul className="hbSub">
                     <Link to='/goods/computers'> <li className="hbSubUl">Computers</li></Link>
                     <Link to='/goods/tv-&-video'><li className="hbSubUl">TV & Video</li></Link>
                     <Link to='/goods/home-audio-&-theater'><li className="hbSubUl">Home Audio & Theater</li></Link>
                     <Link to='/goods/camera-photo-&-video'><li className="hbSubUl">Camera Photo & Video</li></Link>
                     <Link to='/goods/headphones'><li className="hbSubUl">Headphones</li></Link>
                     <Link to='/goods/cell-phones-&-accessories'><li className="hbSubUl">Cell Phones +</li></Link>
                     <Link to='/goods/games'><li className="hbSubUl">Games</li></Link>
                     <Link to='/goods/auto'><li className="hbSubUl">Auto</li></Link>
                     <Link to='/goods/musical'><li className="hbSubUl">Musical</li></Link>
                     <Link to='/goods/wearable'><li className="hbSubUl">Wearable</li></Link>

                      </ul> 
                <li><Link 
                  to={`/goods/more`}
                   state={{selectedCategory: 'More'}}
                    >More...</Link></li>     
                    <ul className="hbSub">
                    <Link to='/goods/sports'><li className="hbSubUl">Sports</li></Link>
                    <Link to='/goods/outdoors'><li className="hbSubUl">Outdoors</li></Link>
                    <Link to='/goods/automotive'><li className="hbSubUl">Automotive</li></Link>
                    <Link to='/goods/food-&-grocery'><li className="hbSubUl">Food & Grocery</li></Link>
                    <Link to='/goods/toys-kids-&-baby'><li className="hbSubUl">Toys Kids & Baby</li></Link>
                    <Link to='/goods/industrial-&-scientific'><li className="hbSubUl">Industrieal & Scientific</li></Link>
                    <Link to='/goods/books'><li className="hbSubUl">Books</li></Link>
                    <Link to='/goods/software'><li className="hbSubUl">Software</li></Link>
  
                      </ul>         
                 
            </ul>
        </li>
        <li className="travel-item"><Link to="/travel">Travel</Link></li>
        <li className="coupon-item"><Link to="/coupons">Coupons</Link></li>
         
        </ul>
      </nav>
      
    </div>
     
     {isTabsVisible && <Tabs details={details} />} 
    </header>
  );
}

export default Header;

// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // Import your Firebase auth object
import { onAuthStateChanged,GoogleAuthProvider,FacebookAuthProvider,TwitterAuthProvider,signInWithPopup,signOut } from '@firebase/auth';
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);


  //const googleSignIn = () => {
   // const provider = new GoogleAuthProvider();
   // signInWithPopup(auth, provider)
 // }



 const googleSignIn = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    setUser(result.user);
    return result;
  } catch (error) {
    console.error('Google sign-in error:', error);
    throw error;
  }
};

  const facebookSignIn = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      return result;
    } 
      catch(error) 
      {
        console.error('Facebook sign-in error:', error);
        throw error;
      }
   }

   const twitterSignIn = async () => {
    try {
        const provider = new TwitterAuthProvider();
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
        return result;
      } 
        catch(error) 
        {
          console.error('Twitter sign-in error:', error);
          throw error;
        }
    
    }

   


const logOut = () => {
  signOut(auth);
}

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      //console.log('User', user);
    });

    return unsubscribe;
  }, []);


  return <AuthContext.Provider value={{twitterSignIn,facebookSignIn ,googleSignIn, logOut, user}}>{children}</AuthContext.Provider>;
}

// StateChangeContext.js
import React, { createContext, useContext, useState } from 'react';

const StateChangeContext = createContext();

export function StateChangeProvider({ children }) {
  const [stateChanged, setStateChanged] = useState(false);

  return (
    <StateChangeContext.Provider value={{ stateChanged, setStateChanged }}>
      {children}
    </StateChangeContext.Provider>
  );
}

export function useStateChange() {
  return useContext(StateChangeContext);
}

import React, { useState, useEffect, useRef } from 'react';
import CashbackHeader from '../CashbackHeader';
//import Breadcrum from './Breadcrum';
//import { useLocation } from 'react-router-dom';
import './Goods.css';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
//import Input from '@mui/material/Input';
//import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from './Footer';
import { db } from '../firebase';
//import AddIcon from '@mui/icons-material/Add';
//import RemoveIcon from '@mui/icons-material/Remove';
//import Popup from '../PopupEmail'
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import Confetti from 'react-confetti';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Loader } from '../loader.svg';
import { useLocation } from "../LocationProvider";
import { useNavigate } from 'react-router-dom';



function Coupon() {
  let strip;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 468 });
  const [showLoader, setShowLoader] = useState(false);
  const { details } = useLocation();

  const drawShoppingCartShape = (ctx, particleSize) => {
    ctx.beginPath();

    const cartWidth = particleSize * 0.8;
    const cartHeight = particleSize * 0.6;
    const wheelRadius = particleSize * 0.1;

    // Draw the shopping cart shape
    ctx.moveTo(0, 0);
    ctx.lineTo(cartWidth, 0);
    ctx.lineTo(cartWidth, cartHeight);
    ctx.lineTo(cartWidth * 0.8, cartHeight);
    ctx.lineTo(cartWidth * 0.7, cartHeight + wheelRadius);
    ctx.arc(cartWidth * 0.5, cartHeight + wheelRadius, wheelRadius, 0, Math.PI, false);
    ctx.arc(cartWidth * 0.3, cartHeight + wheelRadius, wheelRadius, 0, Math.PI, false);
    ctx.lineTo(cartWidth * 0.2, cartHeight);
    ctx.lineTo(0, cartHeight);
    ctx.closePath();

    // Customize the appearance (you can change stroke and fill styles)
    ctx.strokeStyle = 'black'; // Stroke color
    ctx.lineWidth = 2; // Stroke width
    ctx.fillStyle = 'lightgray'; // Fill color
    ctx.fill();
    ctx.stroke();
  };



  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);
 
 // const validateEmail = () => {  
  //  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //  const isValid = emailRegex.test(email);
  //  setIsValidEmail(isValid);
  //};
  
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setIsValidEmail(isValid);
  };



  const handleSendClick = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    // Validate email before sending
    // If the email is valid, you can proceed with sending
    if (isValidEmail) {
      setShowLoader(true);
      console.log(email);
      const userAgent = window.navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
      const userBrowser = navigator.userAgent;


      const docData = {
        Email: email,
        DateTime: new Date(),
        Ipaddress: details.IPv4,
        City: details.city,
        Country_Code: details.country_code,
        Country_Name: details.country_name,
        Latitude: details.latitude,
        Longitude: details.longitude,
        State: details.state,
        Postal_Code: details.postal, 
        User_Mobile: isMobile,
        User_Browser: userBrowser
    }
   
 


    //see if email is already in database
    const getUserEmail = collection(db, 'PreLaunchList');
    const querySnapshot = await getDocs(query(getUserEmail, where('Email', '==', email)));

    if (querySnapshot.size === 1){
      console.log('email already used');
      const joinError = document.getElementById('joinError');
      const joinErrorMobile = document.getElementById('joinErrorMobile');
   
      joinErrorMobile.style.display = 'flex';
      joinErrorMobile.style.color = 'red';       
      joinErrorMobile.style.justifyContent = 'center';  
      joinErrorMobile.style.fontSize = '18px';
      joinErrorMobile.style.fontWeight = '600';
   
        joinError.style.display = 'flex';
        joinError.style.color = 'red';       
        joinError.style.justifyContent = 'center';  
        joinError.style.fontSize = '18px';
        joinError.style.fontWeight = '600';
      setEmailUsed(true);
    }else
    {
      const joinError = document.getElementById('joinError');
      if(isMobile){
      const joinErrorMobile = document.getElementById('joinErrorMobile');
      joinError.style.display = 'none';
      joinErrorMobile.style.display = 'none';
      }
      const docRef = await addDoc(getUserEmail, docData);
      console.log('posted:', docRef)
      setSentEmail(true);
      if(isMobile){
        navigate('/success');
      }


      //console.log(querySnapshot);

    }// Perform your email sending logic here
     // console.log('email is:', isValidEmail)
     // console.log('Email is valid.');
    } else {
    //  console.log('email is:', isValidEmail)
    //  console.log('Email is not valid.');
    }
  };




  const detectBrowser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Firefox')) {
      return 'Firefox';
    } else if (userAgent.includes('Chrome')) {
      return 'Google Chrome';
    } else if (userAgent.includes('Edge')) {
      return 'Microsoft Edge';
    }

    return 'Unknown Browser';
  };


  const [text, setText] = useState(detectBrowser());

  const switchText = () => {
    const currentBrowser = detectBrowser();
    if (currentBrowser === 'Google Chrome') {
      // Set the link or perform the action for Chrome
       Example: window.open('https://chromewebstore.google.com/detail/pinchcart/gmojmpooieblcilokienbhpmlhabimeh', '_blank');
      // You can replace the above line with your specific Chrome extension logic
    }
  
    setText((prevText) => (prevText === detectBrowser() ? 'Coming Soon!' : detectBrowser() ));
    // You can add additional logic or state updates here if needed
  };

  const renderButton = (browser) => (
    <Button key={`${text}-${browser}`} onClick={() => switchText()} variant="contained"
    sx={{ backgroundColor: "#ee4054", width: '350px', height: '55px', borderRadius: '50px', fontSize: '20px', fontWeight: '500', marginLeft:'20px', color: '#ffffff', alignItems: 'center', justifyContent: 'center' }}>
     {text}
    </Button>
  );
   
  return (
    <>
    <CashbackHeader />
      <div className='mobileHeroImg' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <img className='mobileHeroIcon'
            src="handhero.png" // Replace with the actual image URL
            alt="Cashback"
            style={{ width: '150px', height: 'auto', marginTop: '20px', marginBottom: '-25px' }}
          /></div>
      <div className='mobileHero'>Online Shopping with up to 20% Cash Back*</div>    
      <div className='mobileHeroBody'>Download the Pinchcart&trade; Cash Back browser extention. Join. Shop online at our affiliates. We validate qualified purchases every month. <br /><br />
      <div style={{ marginTop: '20px', marginBottom: '0px'}}> Enter your email below to join the launch list and double your Cash Back for life.</div>
</div>    {/**<Popup /> */}


    <div className='mainHero' style={{ display: 'flex' }}>
        {/* Left Division */}
        <div className='leftDiv' style={{ flex: 1,marginTop: '6%',textAlign: 'left', marginRight: '50px' }}>
         <Typography className='heroText' variant="h2" fontWeight="400" fontFamily='Roboto' gutterBottom>
              Online Shopping With Up To 20% Cash Back<span style={{fontSize: '35px', position: 'relative', top: '-25px'}}>*</span></Typography>

           <Typography variant="h6" fontWeight="500" fontFamily='Roboto' gutterBottom>
            Download the Pinchcart&trade; Cash Back browser extention. Join. Shop online at our affiliate websites. We validate qualified purchases every month. Then deposit via Cash App and mail checks.<br /><br /> Enter your email below to join the launch list and double your Cash Back for life.
           </Typography>
           <div className='preJoinContainer'>
             {sentEmail ? (
                        <div style={{height: '900px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: '999'}} className='preJoinContainerForm'>
                          <Confetti 
                            width={2000}
                            height={1000}
                            numberOfPieces={500}
                            gravity={0.2}
                             //drawShape={(ctx) => drawShoppingCartShape(ctx, 20)} // Adjust particle size as needed
                            />
                            <Card style={{ marginTop: '20px', padding: '40px', zIndex: '999' }}>
                              <Typography variant="h5" gutterBottom>
                              Thank you for submitting your email!
                              </Typography>
                              <Typography variant="body1">
                              We'll keep you updated on our pre-launch list.
                              </Typography>
                              <Typography variant="h4" style={{ color: 'green', marginTop: '20px' }}>
                              $10 Value!
                              </Typography>
                              <Typography variant="body1">
                              When we go live. Join using  ( <b> {email} </b> ) and we'll add $10 to your cash back account balance.
                              </Typography>
                            </Card>
                          </div>
          ) : (
            <>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} className='preJoinContainerForm'>
                 
                  <input 
                    style={{ 
                     borderRadius: '25px',	
                      fontSize: '25px',
                      height: '55px', 
                      padding: '8px', 
                      width: '370px', 
                      marginBottom: '10px', 
                      border: '1px solid #ee4054', 
                      outline: 'none'
                      }} 
                      type='email'
                      placeholder="Email"
                      onChange={handleEmailChange}
                      //onChange={(e) => {
                      //const newEmail = (e.target.value);
                      //validateEmail(newEmail);
                      //setEmail(newEmail);
                      //setIsValidEmail(true); // Reset validation when the user types
                      //  }}
                      />
                  <Button 
                    disabled={!isValidEmail}
                    variant="contained" 
                    sx={{ borderRadius: '25px',
                      backgroundColor: '#ee4054', 
                      color: '#ffffff', 
                      marginLeft: '10px', 
                      height: '55px' 
                      }}
                  
                    onClick={handleSendClick}

                    >
                    Send
                  </Button>
                </div>

            </>
          )}
    </div>
    
    
    <div id='joinError' className='joinError'>Aleady Joined the list</div>

  </div>
        {/* Right Division */}

 <div className='rightDiv' style={{ flex: 1 }}>        
        <img className='heroImage'
            src="cashbackhero.png"
            alt="Hero Image"
            style={{ width: '70%', height: 'auto', marginTop: '5%',borderRadius: '20px', marginRight: '150px' }}
          />
        </div>
      </div>
      {isMobile &&  

      
      <><div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '-20px' }} className='preJoinContainerForm'>
                 
                 <input 
                   style={{ 
                    borderRadius: '25px',	
                     fontSize: '25px',
                     height: '55px', 
                     padding: '8px', 
                     width: '350px', 
                     marginBottom: '5px', 
                     border: '1px solid #ee4054', 
                     outline: 'none'
                     }} 
                     type='email'
                     placeholder="Email"
                     onChange={handleEmailChange}
                     //onChange={(e) => {
                     //const newEmail = (e.target.value);
                     //validateEmail(newEmail);
                     //setEmail(newEmail);
                     //setIsValidEmail(true); // Reset validation when the user types
                     //}} 
                   />
                   <div id='joinErrorMobile' className='joinErrorMobile'>Got it. We will email you when we go live.</div>
                 <Button 
                   disabled={!isValidEmail}
                   variant="contained" 
                   sx={{ borderRadius: '25px',
                     backgroundColor: '#ee4054', 
                     color: '#ffffff',
                     height: '55px', 
                     width: '350px'
                     }}
                 
                   onClick={handleSendClick}

                   >
                   Send
                 </Button>
               </div>

           </>
           }
      {!isMobile && 
      <div id='strip' className='downloadStrip' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '50px'}}>
      <img
         src="exticon.png" // Replace with the actual image URL
         alt="Hero Image"
         style={{ display: 'inline-block',width: '75px', height: '75px', marginRight: '15px',borderRadius: '75px' }}
       /> 
       <div style={{fontSize: '20px', lineHeight: '24px', fontWeight: '500'}}>Get The Pinchcart&trade; Cash Back Extension. </div>

   <div>
   {detectBrowser() === 'Firefox' && renderButton('Firefox')}
   {detectBrowser() === 'Google Chrome' && renderButton('Chrome')}
   {detectBrowser() === 'Microsoft Edge' && renderButton('Microsoft Edge')}
   </div>    
 </div>


    }

    {!isMobile && <Footer details={details} />}
    </>
  );
}

export default Coupon;

export const categories = [
    // ... your categories array
        'Local',
        'Goods',
        'Travel',
    ];
  
  export const subcategories = {
    // ... your s Retail: 
    Local:[
      'Face & Skin Care',
      'Massage',
      'Salon',
      'Spas',
      'Hair & Styling',
      'Makeup',
      'Hair Removal',
      'Blowouts & Styling',
      'Tanning',
      'Kids & Activities',
      'Fun & Leisure',
      'Tickets & Events',
      'Sports & Outdoors',
      'Night Life',
      'Restaurants',
      'Beer & Wine',
      'Cake\'s Treats ',
      'Groceries & Market',
      'Gyms',
      'Weight Loss',
      'Fitness',
      'Medical',
      'Natural Medicine',
      'Sports',
      'Dental',
      'Vision',
      'Automotive',
      'Home Servies',
      'Meal Prep',
      'Online Learning',
      'Person Service',
      'Personal Items',
      'Rentals',
      'Pet Adopton'
    ],
    Goods: [
        'Health & Beauty',
        '-Aromatharepy',
        '-Baby & Bath',
        '-Cosmetics',
        '-Fragrance',
        '-Hair Care',
        '-Massage',
        '-Mens',
        '-Oral Care',
        '-Personal Care',
        '-Shaving & Grooming',
        '-Skin Care',
        '-Vitamins & Supplements',
        'Clothing-Shoes-Jewelry-Watches',
        '-Jewelry',
        '-Watches',
        '-Fashion',
        '-Women',
        '-Men',
        '-Girls',
        '-Boys',
        '-Baby',
        '-Luggage',
        'Home-Garden&Tools',
        '-Tools',
        '-Discover',
        '-Decor',
        '-Furniture',
        '-Kitchen & Dining',
        '-Bed & Bath',
        '-Garden & Outdoor',
        '-Lighting',
        '-Appliances',
        '-Pet Supplies',
        'Electronics',
        '-Computer',
        '-TV & Video',
        '-Home Audio & Theater',
        '-Camera Photo & Video',
        '-Cell Phones & Accessories',
        '-Headphones',
        '-Games',
        '-Car Electronics',
        '-Musical',
        '-Wearable',
        'More',
        '-Sports',
        '-Ourdoors',
        '-Automotive',
        '-Food & Grocery',
        '-Toys Kids & Baby',
        '-Industrial & Scientific',
        '-Books',
        '-Software',

    ],
    Travel: [
        'Air-inclusive Trips',
        'Beach',
        'Premium',
        'Family Fun',
        'International & Tours',
        'Under $99',
        'New Deals',
        'Hotels',
        'Flights',
        'Car Rentals',
        'Airbnb'
    ]
  };
  
  export const states = [
    'AllStates',
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'NewHampshire',
    'NewJersey',
    'NewMexico',
    'NewYork',
    'NorthCarolina',
    'NorthDakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'RhodeIsland',
    'SouthCarolina',
    'SouthDakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];
  
  export const citiesData = {
    AllStates: [
        'AllCities'
     ],
     Alabama: [
       'Birmingham',
       'Montgomery',
       'Mobile',
       'Huntsville',
       'Tuscaloosa',
       'Hoover',
       'Dothan',
       'Auburn',
       'Decatur',
       'Madison',
       'Florence',
       'Gadsden',
       'Prattville',
       'Phenix City',
       'Vestavia Hills',
     ],
     Alaska: [
       'Anchorage',
       'Fairbanks',
       'Juneau',
       'Sitka',
       'Ketchikan',
       'Wasilla',
       'Kenai',
       'Kodiak',
       'Bethel',
       'Palmer',
       'Homer',
       'Soldotna',
       'Unalaska',
       'Barrow',
       'Valdez',
     ],
     Arizona: [
       'Phoenix',
       'Tucson',
       'Mesa',
       'Chandler',
       'Scottsdale',
       'Glendale',
       'Gilbert',
       'Tempe',
       'Peoria',
       'Surprise',
       'Yuma',
       'Avondale',
       'Flagstaff',
       'Goodyear',
       'Lake Havasu City',
     ],
     Arkansas: [
       'Little Rock',
       'Fort Smith',
       'Fayetteville',
       'Springdale',
       'Jonesboro',
       'North Little Rock',
       'Conway',
       'Rogers',
       'Pine Bluff',
       'Bentonville',
       'Hot Springs',
       'Benton',
       'Texarkana',
       'Sherwood',
       'Jacksonville',
     ],
     California: [
       'Los Angeles',
       'San Diego',
       'San Jose',
       'San Francisco',
       'Fresno',
       'Sacramento',
       'Long Beach',
       'Oakland',
       'Bakersfield',
       'Anaheim',
       'Santa Ana',
       'Riverside',
       'Stockton',
       'Irvine',
       'Chula Vista',
       // ... other cities
     ],
     Colorado: [
       'Denver',
       'Colorado Springs',
       'Aurora',
       'Fort Collins',
       'Lakewood',
       'Thornton',
       'Arvada',
       'Westminster',
       'Pueblo',
       'Boulder',
       'Greeley',
       'Centennial',
       'Longmont',
       'Loveland',
       'Broomfield',
       // ... other cities
     ],
     Connecticut: [
       'Bridgeport',
       'New Haven',
       'Hartford',
       'Stamford',
       'Waterbury',
       'Norwalk',
       'Danbury',
       'New Britain',
       'West Hartford',
       'Meriden',
       'Bristol',
       'West Haven',
       'Milford',
       'Middletown',
       'Norwich',
       // ... other cities
     ],
     Delaware: [
       'Wilmington',
       'Dover',
       'Newark',
       'Middletown',
       'Smyrna',
       'New Castle',
       'Seaford',
       'Georgetown',
       'Elsmere',
       'Milford',
       'New Castle',
       'Millsboro',
       'Laurel',
       'Harrington',
       'Camden',
       // ... other cities
     ],
     Florida: [
       'Jacksonville',
       'Miami',
       'Tampa',
       'Orlando',
       'St. Petersburg',
       'Hialeah',
       'Tallahassee',
       'Fort Lauderdale',
       'Port St. Lucie',
       'Cape Coral',
       'Pembroke Pines',
       'Hollywood',
       'Miramar',
       'Gainesville',
       'Coral Springs',
       // ... other cities
     ],
     Georgia: [
         'Atlanta',
         'Augusta',
         'Columbus',
         'Macon',
         'Savannah',
         'Athens',
         'Sandy Springs',
         'Roswell',
         'Johns Creek',
         'Albany',
         'Warner Robins',
         'Alpharetta',
         'Marietta',
         'Valdosta',
         'Smyrna',
         // ... other cities
       ],
       Hawaii: [
         'Honolulu',
         'East Honolulu',
         'Pearl City',
         'Hilo',
         'Kailua',
         'Waipahu',
         'Kaneohe',
         'Mililani Town',
         'Kahului',
         'Ewa Gentry',
         'Mililani Mauka',
         'Kihei',
         'Makakilo',
         'Wahiawa',
         'Schofield Barracks',
         // ... other cities
       ],
       Idaho: [
         'Boise',
         'Meridian',
         'Nampa',
         'Idaho Falls',
         'Pocatello',
         'Caldwell',
         'Coeur d\'Alene',
         'Twin Falls',
         'Lewiston',
         'Post Falls',
         'Rexburg',
         'Moscow',
         'Eagle',
         'Kuna',
         'Ammon',
         // ... other cities
       ],
       Illinois: [
         'Chicago',
         'Aurora',
         'Rockford',
         'Joliet',
         'Naperville',
         'Springfield',
         'Peoria',
         'Elgin',
         'Waukegan',
         'Champaign',
         'Bloomington',
         'Decatur',
         'Evanston',
         'Des Plaines',
         'Berwyn',
         // ... other cities
       ],
       Indiana: [
         'Indianapolis',
         'Fort Wayne',
         'Evansville',
         'South Bend',
         'Carmel',
         'Fishers',
         'Bloomington',
         'Hammond',
         'Gary',
         'Lafayette',
         'Muncie',
         'Terre Haute',
         'Kokomo',
         'Noblesville',
         'Anderson',
         // ... other cities
       ],
       Iowa: [
         'Des Moines',
         'Cedar Rapids',
         'Davenport',
         'Sioux City',
         'Iowa City',
         'Waterloo',
         'Council Bluffs',
         'Ames',
         'West Des Moines',
         'Dubuque',
         'Ankeny',
         'Urbandale',
         'Cedar Falls',
         'Marion',
         'Bettendorf',
         // ... other cities
       ],
       Kansas: [
         'Wichita',
         'Overland Park',
         'Kansas City',
         'Olathe',
         'Topeka',
         'Lawrence',
         'Shawnee',
         'Manhattan',
         'Lenexa',
         'Salina',
         'Hutchinson',
         'Leavenworth',
         'Leawood',
         'Dodge City',
         'Garden City',
         // ... other cities
       ],
       Kentucky: [
         'Louisville',
         'Lexington',
         'Bowling Green',
         'Owensboro',
         'Covington',
         'Richmond',
         'Hopkinsville',
         'Florence',
         'Georgetown',
         'Elizabethtown',
         'Henderson',
         'Nicholasville',
         'Jeffersontown',
         'Frankfort',
         'Paducah',
         // ... other cities
       ],
       Louisiana: [
         'New Orleans',
         'Baton Rouge',
         'Shreveport',
         'Lafayette',
         'Lake Charles',
         'Kenner',
         'Bossier City',
         'Monroe',
         'Alexandria',
         'Houma',
         'New Iberia',
         'Slidell',
         'Central',
         'Ruston',
         'Sulphur',
         // ... other cities
       ],
       Maine: [
         'Portland',
         'Lewiston',
         'Bangor',
         'South Portland',
         'Auburn',
         'Biddeford',
         'Sanford',
         'Saco',
         'Augusta',
         'Westbrook',
         'Waterville',
         'Presque Isle',
         'Brewer',
         'Bath',
         'Caribou',
         // ... other cities
       ],
       Maryland: [
         'Baltimore',
         'Columbia',
         'Germantown',
         'Silver Spring',
         'Waldorf',
         'Glen Burnie',
         'Ellicott City',
         'Frederick',
         'Dundalk',
         'Rockville',
         'Bethesda',
         'Gaithersburg',
         'Towson',
         'Bowie',
         'Aspen Hill',
         // ... other cities
       ],
       Massachusetts: [
         'Boston',
         'Worcester',
         'Springfield',
         'Lowell',
         'Cambridge',
         'New Bedford',
         'Brockton',
         'Quincy',
         'Lynn',
         'Fall River',
         'Newton',
         'Lawrence',
         'Somerville',
         'Framingham',
         'Waltham',
         // ... other cities
       ],
       Michigan: [
         'Detroit',
         'Grand Rapids',
         'Warren',
         'Sterling Heights',
         'Lansing',
         'Ann Arbor',
         'Flint',
         'Dearborn',
         'Livonia',
         'Westland',
         'Troy',
         'Farmington Hills',
         'Kalamazoo',
         'Wyoming',
         'Southfield',
         // ... other cities
       ],
       Minnesota: [
         'Minneapolis',
         'St. Paul',
         'Rochester',
         'Bloomington',
         'Duluth',
         'Brooklyn Park',
         'Plymouth',
         'Woodbury',
         'St. Cloud',
         'Eagan',
         'Maple Grove',
         'Coon Rapids',
         'Eden Prairie',
         'Minnetonka',
         'Burnsville',
         // ... other cities
       ],
       Mississippi: [
         'Jackson',
         'Gulfport',
         'Southaven',
         'Hattiesburg',
         'Biloxi',
         'Meridian',
         'Tupelo',
         'Olive Branch',
         'Greenville',
         'Horn Lake',
         'Clinton',
         'Pearl',
         'Ridgeland',
         'Starkville',
         'Vicksburg',
         // ... other cities
       ],
       Missouri: [
         'Kansas City',
         'St. Louis',
         'Springfield',
         'Columbia',
         'Independence',
         'Lees Summit',
         'OFallon',
         'St. Joseph',
         'St. Charles',
         'St. Peters',
         'Blue Springs',
         'Florissant',
         'Joplin',
         'Chesterfield',
         'Jefferson City',
         // ... other cities
       ],
       Montana: [
         'Billings',
         'Missoula',
         'Great Falls',
         'Bozeman',
         'Butte',
         'Helena',
         'Kalispell',
         'Havre',
         'Anaconda',
         'Miles City',
         'Belgrade',
         'Livingston',
         'Laurel',
         'Whitefish',
         'Lewistown',
         // ... other cities
       ],
       Nebraska: [
         'Omaha',
         'Lincoln',
         'Bellevue',
         'Grand Island',
         'Kearney',
         'Fremont',
         'Hastings',
         'Norfolk',
         'North Platte',
         'Columbus',
         'Papillion',
         'La Vista',
         'Scottsbluff',
         'South Sioux City',
         'Beatrice',
         // ... other cities
       ],
       Nevada: [
         'Las Vegas',
         'Henderson',
         'Reno',
         'North Las Vegas',
         'Sparks',
         'Carson City',
         'Elko',
         'Mesquite',
         'Boulder City',
         'Fernley',
         'Fallon',
         'Winnemucca',
         'West Wendover',
         'Ely',
         'Yerington',
         // ... other cities
       ],
       NewHampshire: [
         'Manchester',
         'Nashua',
         'Concord',
         'Dover',
         'Rochester',
         'Salem',
         'Merrimack',
         'Hudson',
         'Londonderry',
         'Keene',
         'Portsmouth',
         'Bedford',
         'Derry',
         'Laconia',
         'Claremont',
         // ... other cities
       ],
       NewJersey: [
         'Newark',
         'Jersey City',
         'Paterson',
         'Elizabeth',
         'Edison',
         'Woodbridge',
         'Lakewood',
         'Toms River',
         'Hamilton',
         'Trenton',
         'Clifton',
         'Camden',
         'Brick',
         'Cherry Hill',
         'Passaic',
         // ... other cities
       ],
       NewMexico: [
         'Albuquerque',
         'Las Cruces',
         'Rio Rancho',
         'Santa Fe',
         'Roswell',
         'Farmington',
         'Clovis',
         'Hobbs',
         'Alamogordo',
         'Carlsbad',
         'Gallup',
         'Deming',
         'Los Lunas',
         'Sunland Park',
         'Las Vegas',
         // ... other cities
       ],
       NewYork: [
         'New York City',
         'Buffalo',
         'Rochester',
         'Yonkers',
         'Syracuse',
         'Albany',
         'New Rochelle',
         'Mount Vernon',
         'Schenectady',
         'Utica',
         'White Plains',
         'Niagara Falls',
         'Troy',
         'Binghamton',
         'Long Beach',
         // ... other cities
       ],
       NorthCarolina: [
         'Charlotte',
         'Raleigh',
         'Greensboro',
         'Durham',
         'Winston-Salem',
         'Fayetteville',
         'Cary',
         'Wilmington',
         'High Point',
         'Greenville',
         'Asheville',
         'Concord',
         'Gastonia',
         'Jacksonville',
         'Rocky Mount',
         // ... other cities
       ],
       NorthDakota: [
         'Fargo',
         'Bismarck',
         'Grand Forks',
         'Minot',
         'West Fargo',
         'Dickinson',
         'Williston',
         'Mandan',
         'Jamestown',
         'Wahpeton',
         'Devils Lake',
         'Watford City',
         'Valley City',
         'Grafton',
         'Lincoln',
         // ... other cities
       ],
       Ohio: [
         'Columbus',
         'Cleveland',
         'Cincinnati',
         'Toledo',
         'Akron',
         'Dayton',
         'Parma',
         'Canton',
         'Youngstown',
         'Lorain',
         'Hamilton',
         'Springfield',
         'Kettering',
         'Elyria',
         'Lakewood',
         // ... other cities
       ],
       Oklahoma: [
         'Oklahoma City',
         'Tulsa',
         'Norman',
         'Broken Arrow',
         'Lawton',
         'Edmond',
         'Moore',
         'Midwest City',
         'Enid',
         'Stillwater',
         'Muskogee',
         'Bartlesville',
         'Owasso',
         'Shawnee',
         'Yukon',
         // ... other cities
       ],
       Oregon: [
         'Portland',
         'Eugene',
         'Salem',
         'Gresham',
         'Hillsboro',
         'Beaverton',
         'Bend',
         'Medford',
         'Springfield',
         'Corvallis',
         'Albany',
         'Tigard',
         'Lake Oswego',
         'Keizer',
         'Grants Pass',
         // ... other cities
       ],
       Pennsylvania: [
         'Philadelphia',
         'Pittsburgh',
         'Allentown',
         'Erie',
         'Reading',
         'Scranton',
         'Bethlehem',
         'Lancaster',
         'Harrisburg',
         'Altoona',
         'York',
         'State College',
         'Wilkes-Barre',
         'Norristown',
         'Chester',
         // ... other cities
       ],
       RhodeIsland: [
         'Providence',
         'Warwick',
         'Cranston',
         'Pawtucket',
         'East Providence',
         'Woonsocket',
         'Coventry',
         'Cumberland',
         'North Providence',
         'South Kingstown',
         'West Warwick',
         'Newport',
         'Bristol',
         'Westerly',
         'Smithfield',
         // ... other cities
       ],
       SouthCarolina: [
         'Columbia',
         'Charleston',
         'North Charleston',
         'Mount Pleasant',
         'Rock Hill',
         'Greenville',
         'Summerville',
         'Spartanburg',
         'Sumter',
         'Hilton Head Island',
         'Florence',
         'Goose Creek',
         'Aiken',
         'Myrtle Beach',
         'Anderson',
         // ... other cities
       ],
       SouthDakota: [
         'Sioux Falls',
         'Rapid City',
         'Aberdeen',
         'Brookings',
         'Watertown',
         'Mitchell',
         'Yankton',
         'Pierre',
         'Huron',
         'Spearfish',
         'Vermillion',
         'Brandon',
         'Madison',
         'Sturgis',
         'Ellsworth AFB (Census Designated Place)',
         // ... other cities
       ],
       Tennessee: [
         'Nashville',
         'Memphis',
         'Knoxville',
         'Chattanooga',
         'Clarksville',
         'Murfreesboro',
         'Franklin',
         'Jackson',
         'Johnson City',
         'Bartlett',
         'Hendersonville',
         'Kingsport',
         'Collierville',
         'Smyrna',
         'Cleveland',
         // ... other cities
       ],
       Texas: [
         'Houston',
         'San Antonio',
         'Dallas',
         'Austin',
         'Fort Worth',
         'El Paso',
         'Arlington',
         'Corpus Christi',
         'Plano',
         'Laredo',
         'Lubbock',
         'Garland',
         'Irving',
         'Amarillo',
         'Grand Prairie',
         // ... other cities
       ],
       Utah: [
         'Salt Lake City',
         'West Valley City',
         'Provo',
         'West Jordan',
         'Orem',
         'Sandy',
         'Ogden',
         'St. George',
         'Layton',
         'Taylorsville',
         'South Jordan',
         'Lehi',
         'Logan',
         'Murray',
         'Draper',
         // ... other cities
       ],
       Vermont: [
         'Burlington',
         'South Burlington',
         'Rutland',
         'Essex',
         'Colchester',
         'Bennington',
         'Brattleboro',
         'Hartford',
         'Milton',
         'Barre',
         'Williston',
         'Montpelier',
         'St. Albans',
         'Winooski',
         'St. Johnsbury',
         // ... other cities
       ],
       Virginia: [
         'Virginia Beach',
         'Norfolk',
         'Chesapeake',
         'Richmond',
         'Newport News',
         'Alexandria',
         'Hampton',
         'Roanoke',
         'Portsmouth',
         'Suffolk',
         'Lynchburg',
         'Harrisonburg',
         'Leesburg',
         'Charlottesville',
         'Danville',
         // ... other cities
     
       ]    
     // ... Add other states and their top 25 cities
   };

  


  
    
    
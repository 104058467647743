import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
    <main>
        {/* Hero unit */}
        <Box >
          <Container style={{marginTop: '50px'}} >
           
          <div align='center' style={{fontSize: '35px', display: 'inline', marginTop: "250px"}}>
            About Pinchcart</div>
            
            <Typography variant="h5" alignitems="center" color="text.secondary" paragraph>
            Your Gateway to Savings!
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            
          <div style={{fontSize: '35px', alignitems: 'left', display: 'flex'}} >  Who We Are</div>
            
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            Hello there! I'm Micheal, a seasoned electrical engineer with a deep-rooted passion for computers, electronics, and robotics. With 15 years of experience under my belt, I've ventured into the digital landscape to bring you an online haven of incredible deals and coupon codes. My journey in engineering and technology has not only fueled my curiosity but also kindled a desire to help people like you discover unbeatable savings on your favorite products.

            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            
             <div style={{fontSize: '35px', alignitems: 'left', display: 'flex'}} >Our Inspiration </div>
            
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            Growing up, I was captivated by the endless possibilities that technology and electronics offered. It's this fascination that ultimately led me to a successful career in robotics. Along the way, I realized the potential to make a difference in people's lives, not just through innovation but also by helping them save money. And thus, PinchCart was born.

            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="h4"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Our Mission
            </Typography>
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
            At PinchCart, our mission is clear: We're here to uncover the best technology available to put money back in consumers pockets. Whether you're a tech enthusiast, a savvy shopper, or simply looking to make your hard-earned money go further, PinchCart is your go-to destination. We've scoured the digital realm to bring you exclusive offers, discounts, and promotions from your favorite brands and retailers.

            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
          <Container maxWidth="sm">
            
          <div style={{fontSize: '35px', alignItems: 'left', display: 'flex'}} > Why Choose PinchCart</div>
         
            <Typography variant="h6" align="left" color="text.secondary" paragraph>
<b>Expert Curation:</b><br/> Our team of experts is dedicated to handpicking the most valuable deals to save you time and money.<br />

<b>User-Centric:</b><br/> We're here to serve you. PinchCart is designed with your convenience in mind, making your shopping experience effortless and rewarding.<br />

<b>Passion-Driven:</b><br /> What sets us apart is our genuine passion for helping you find great savings. We live and breathe technology, and it's our joy to share these discoveries with you.

            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>

          <Container maxWidth="sm">
           
        
          <div style={{fontSize: '35px', alignItems: 'left', display: 'flex'}} > Join Us on this Journey</div>
            
            <Typography variant="h6" align="left" color="text.secondary" paragraph>

            We invite you to join us on this exciting journey of savings, exploration, and innovation. Explore our website, bookmark your favorite deals, and let us be your trusted companion in your online shopping adventures. We're committed to continuously improving and evolving to meet your needs and exceed your expectations. Download the browser extention Pinchcart Cash Back. Join Pinchcart on a Desktop or Laptop at https://pinchcart.com/join. Launch and approve the extention. Go Shop. We take care of the rest.
            <br /><br />Thank you for choosing Pinchart. Let's pinch pennies, a dime at a time!
            <br />Have questions, suggestions, or just want to say hello? Feel free to contact us - we'd love to hear from you. <br /><br />
            Micheal Dhane EECT<br />
            Founder/CEO<br />
            CEO@PINCHCART.COM
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="left"
            >
            </Stack>
          </Container>
        </Box>
        
      </main>
     
    </ThemeProvider>
  );
}
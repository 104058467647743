import React, { useState, useEffect, useRef } from 'react';
import CashbackHeader from '../CashbackHeader';
//import Breadcrum from './Breadcrum';
//import { useLocation } from 'react-router-dom';
import './Goods.css';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
//import Input from '@mui/material/Input';
//import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from './Footer';
import { db } from '../firebase';
//import AddIcon from '@mui/icons-material/Add';
//import RemoveIcon from '@mui/icons-material/Remove';
//import Popup from '../PopupEmail'
import { collection, addDoc, updateDoc, doc, getDocs , where, query} from 'firebase/firestore'; // Import collection and addDoc functions
import Confetti from 'react-confetti';


function MobileSuccess() {
  return (
    <>
        <CashbackHeader />

   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} className='preJoinContainerForm'>
                          <Confetti 
                            width={2000}
                            height={1000}
                            numberOfPieces={500}
                            gravity={0.2}
                             //drawShape={(ctx) => drawShoppingCartShape(ctx, 20)} // Adjust particle size as needed
                            />
                            <Card style={{ marginTop: '50px', padding: '40px' }}>
                              <Typography variant="h5" gutterBottom>
                              Thank you for submitting your email!
                              </Typography>
                              <Typography variant="body1">
                              We'll keep you updated on our pre-launch list.
                              </Typography>
                              <Typography variant="h4" style={{ color: 'green', marginTop: '20px' }}>
                              $10 Value!
                              </Typography>
                              <Typography variant="body1">
                              When we go live. Join using that email and we'll add $10 to your cashback account balance.
                              </Typography>
                            </Card>
                          </div>
    </>
  

  )
};
export default MobileSuccess;

import React, { useState, useEffect, useRef } from 'react';
import './LocalPopdownCard.css';
import './CashBackHeader.css'; // Import the CSS for styling
import logo from './components/logov2.png'
import { Link } from 'react-router-dom'; // Import useNavigate
import SearchIcon from '@mui/icons-material/Search';
import Tabs from './components/Tabs';
import Typography from '@mui/material/Typography';

function CashBackHeader() {
  
  const [text, setText] = useState('Join');

  const switchText = () => {
    setText('Coming Soon');
    // You can add additional logic or state updates here if needed
  };

  return (
    <header className='header' style={{}}>
    <div className="page-header-cashback"  style={{backgroundColor: "#ee4054" }}>
        <div className='logo-button-container'>
      <img src={logo}
       className="App-logo" 
       style={{height: "60px", width: "250px" }} 
       alt='pinchcart logo'/>
      
       </div>
       <div className='headerText' style={{marginLeft: '-60px',color: '#ffffff', fontSize: '18px',fontHeight: '24px', fontWeight: '600', fontFamily: 'Roboto', paddingRight: '20px'}}>
            Download - <Link style={{color: 'white'}} to="https://chromewebstore.google.com/detail/pinchcart/gmojmpooieblcilokienbhpmlhabimeh">Click Here</Link>
        </div>
      {  // <div className='headerJoin'
      //style={{
      //  color: '#ffffff',
      //  fontWeight: '700',
      //  fontSize: '15px',
      //  paddingRight: '150px',
       // cursor: 'pointer', // Add cursor pointer to indicate it's clickable
     // }}
     // onClick={switchText}
   // >
   //  {text}
   // </div>
    
   // <Link to="/goods" className="goodsLink" style={{ color: '#ffffff', fontWeight: '700', fontSize: '15px', textDecoration: 'none', marginRight: '100px' }}>
     //     Goods
      //  </Link>
      }
      </div>
    </header>
  );
}

export default CashBackHeader;

import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../../../src/firebase';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ReactComponent as Loader50 } from '../../../../components/loader50x50.svg';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import './PetSupplies.css'; // Import the CSS for styling
import { collection, where, query, getDocs, doc, updateDoc } from 'firebase/firestore';


function Discover() {
  const couponRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 200, height: 200 });
  const shareUrl = 'pinchcart.com/goods/discover';
  const [data, setData] = useState([]);
  const [likeCounts, setLikeCounts] = useState([]);
  const initialData = data.map((item) => ({
    ...item,
    isSolid: false,
   
  }));
  const [isSolid, setIsSolid] = useState(Array(data.length).fill(false));
  const [affiliateUrls, setAffiliateUrls] = useState(Array(data.length).fill(''));
  const [cardSocialIcons, setCardSocialIcons] = useState([]);
  const [numCardsToDisplay, setNumCardsToDisplay] = useState(4); // Initial number of cards to display
  const [PintrestMedia, setPintrestMedia] = useState(); // Initial number of cards to display
  const [loadingMore, setLoadingMore] = useState(false); // Flag to indicate loading more cards
  const lastCardRef = useRef();
  const [activeCouponIndex, setActiveCouponIndex] = useState(null);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'Product_Deals'),
          where('category', '==', 'Goods'),
          where('subcategory', '==', '-Discover')////////////////database queary
        );
        const querySnapshot = await getDocs(q);

        const newData = [];
        const counts = [];
        const urls = [];
        const socialIcons = [];

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const docId = doc.id;
          newData.push({ id: docId, ...docData });
          counts.push(docData.likeCount);
          const affiliateUrl = docData.affiliate_url;
          urls.push(affiliateUrl);
          socialIcons.push({
            showSocialIcons: false,
            showTwitterIcon: false,
            showInstagramIcon: false,
            showFacebookIcon: false,
          });
        });

        setData(newData);
        setLikeCounts(counts);
        setAffiliateUrls(urls);
        setCardSocialIcons(socialIcons);
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setCardSocialIcons(
      data.map(() => ({
        showSocialIcons: false,
        showTwitterIcon: false,
        showInstagramIcon: false,
        showFacebookIcon: false,
      }))
    );
  }, [data]);

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200 &&
        !loadingMore
      ) {
        setLoadingMore(true);
        setNumCardsToDisplay((prevNum) => prevNum + 4);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadingMore]);

  const handleCouponClick = (index) => {
    setActiveCouponIndex((prevIndex) => (prevIndex === index ? null : index));
      // Check if the affiliate URL exists
      if (data[index].affiliate_url) {
        // Delay opening the URL by 3 seconds
        setTimeout(() => {
          // Use window.open to open the URL in a new tab
           window.open(data[index].affiliate_url, '_blank');
          // Focus on the current tab
          window.focus();
        }, 3000); // 3000 milliseconds (3 seconds)
      } else {
        console.error('Affiliate URL not available yet.');
      }
  
  };

  const handleIconClick = async (index) => {
    try {
      const card = data[index];
      const cardId = card.id;
      const currentLikeCount = likeCounts[index];
      const newLikeCount = isSolid[index] ? currentLikeCount - 1 : currentLikeCount + 1;
      await updateDoc(doc(db, 'Product_Deals', cardId), {
        likeCount: newLikeCount,
      });


      setIsSolid((prevIsSolid) => {
        const updatedIsSolid = [...prevIsSolid];
        updatedIsSolid[index] = !updatedIsSolid[index];
        return updatedIsSolid;
      });

      setLikeCounts((prevCounts) => {
        const updatedCounts = [...prevCounts];
        updatedCounts[index] = newLikeCount;
        return updatedCounts;
      });

    } catch (error) {
      console.error('Error updating like count:', error);
    }
  };

  const handleShareClick = async (index) => {
    const updatedCardSocialIcons = [...cardSocialIcons];

    if (updatedCardSocialIcons[index].showSocialIcons) {
      updatedCardSocialIcons[index] = {
        showSocialIcons: false,
        showTwitterIcon: false,
        showInstagramIcon: false,
        showFacebookIcon: false,
      };
    } else {
      updatedCardSocialIcons[index] = {
        showSocialIcons: true,
        showTwitterIcon: true,
        showInstagramIcon: true,
        showFacebookIcon: true,
      };

    
    }

    setCardSocialIcons(updatedCardSocialIcons);
  };
  return (
    <>
      <div className="card-row">
        {data.slice(0, numCardsToDisplay).map((item, index) => {
          const saleprice = data[index].sale_price;
          const price = item.price;
          const hasDecimalPoint = price.toString().includes('.');
          const salepricehasDecimalPoint = saleprice.toString().includes('.');
          let integerPart = 0;
          let saleintegerPart = 0;
          let salewholePart = saleprice;
          let wholePart = price;

          if (hasDecimalPoint) {
            const parts = price.toString().split('.');
            if (parts.length > 1) {
              integerPart = parseInt(parts[1]);
              wholePart = parseInt(parts[0]);
            }
          }
          if (salepricehasDecimalPoint) {
            const salepart = saleprice.toString().split('.');
            if (salepart.length > 1) {
              saleintegerPart = parseInt(salepart[1]);
              salewholePart = parseInt(salepart[0]);
            }
          }

          if (integerPart >= 0 && integerPart <= 9) {
            integerPart = `0${integerPart}`;
          }

          if (saleintegerPart >= 0 && saleintegerPart <= 9) {
            saleintegerPart = `0${saleintegerPart}`;
          }

          return (
            <Card
              key={index}
              id={`card-${index}`}
              sx={{ maxWidth: 300, minWidth: 300, maxHeight: 470, minHeight: 470, borderRadius: '15px', margin: '20px' }}
            >
              <div className="icon-containter">
                <i className="heart-icon" onClick={() => handleIconClick(index)}>
                  {isSolid[index] ? (
                    <FavoriteIcon className="solidIcon" sx={{ fontSize: 30 }} />
                  ) : (
                    <FavoriteBorderIcon className="outlineIcon" sx={{ fontSize: 30 }} />
                  )}
                  <span className="like-count">{likeCounts[index]}</span>
                </i>
                <i className='share-icon' onClick={() => handleShareClick(index)}>
                  {cardSocialIcons[index].showSocialIcons ?
                    <ShareIcon className='solidIconShare' sx={{ fontSize: 30 }} />
                    : <ShareOutlinedIcon className='outlineIconShare' sx={{ fontSize: 30 }} />}
                </i>
                {cardSocialIcons[index].showFacebookIcon && (
                  <div className='visible'>
                    <i className="fab fa-facebook">
                      <FacebookShareButton url={shareUrl} quote={`${data[index].title} - Price: $${data[index].sale_price}`} hashtag={data[index].brand}>
                        <FacebookRoundedIcon className="FacebookIcon" sx={{ fontSize: 30, marginLeft: '25px' }} />
                      </FacebookShareButton>
                    </i>
                  </div>
                )}
                {cardSocialIcons[index].showInstagramIcon && (
                  <div className='visible'>
                    <i className="fab fa-instagram">
                      <PinterestShareButton url={shareUrl} media={data[index].image_url} description={`${data[index].title} - Price: $${data[index].sale_price}`}>
                        <PinterestIcon className="PintrestIcon" sx={{ fontSize: 30, marginLeft: '25px' }} />
                      </PinterestShareButton>
                    </i>
                  </div>
                )}
                {cardSocialIcons[index].showTwitterIcon && (
                  <div className='visible'>
                    <i className="fab fa-twitter"><TwitterShareButton url={shareUrl} title={`${data[index].title} - Price: $${data[index].sale_price}`} hashtags={['pinchcart','coupons','discover','onsale','discounts']}>
                      <TwitterIcon className="TwitterIcon" sx={{ fontSize: 30, marginLeft: '25px' }} />
                    </TwitterShareButton>
                    </i>
                  </div>
                )}
              </div>
              <CardMedia className='Mui-card-img' sx={{ height: 250, mt: '30px', mb: '-40px' }}>
                {!item.image_url ? <Loader50 className="spinner" sx={{ fontSize: '40' }} /> : ''}
                <img 
                  src={item.image_url}
                // src={`${PROXY_SERVER_URL}/proxy-image?url=${encodeURIComponent(item.image_url)}`}
                  width={imageDimensions.width}
                  height={imageDimensions.height}
                  className="card-img-top"
                  alt="..."
                  title={item.brand}
                />
              </CardMedia>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100px',
                }}
              >
                <Typography gutterBottom variant="h6" component="div" fontWeight={600} align="left">
                  {item.title}
                </Typography>
                <div style={{ marginBottom: '-30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <strike style={{ marginRight: '5px', color: 'red', fontSize: '20px', fontWeight: '600' }}>
                    <span>
                      ${wholePart}
                    </span>
                    {(isNaN(integerPart) || integerPart === 0) ? null : (
                      <span style={{ color: 'red', fontSize: '12px', fontWeight: '300' }}>
                        {integerPart}
                      </span>
                    )}
                  </strike>
                    <div className="coupon-post">
                        <div className={`coupon ${activeCouponIndex === index ? 'active' : ''}`} onClick={() => handleCouponClick(index)} ref={couponRef} data-affiliateurl={data[index].affiliateUrl} >
                          <div className="code">
                               <h2>{data[index].coupon_code}</h2>
                          </div>
                             <div className="mask">Use Code
                            </div>
                        </div>
                    </div>
                  <div style={{ marginLeft: '5px', color: 'green', fontSize: '20px', fontWeight: '600' }}>
                    <span>${salewholePart}</span>
                    {(isNaN(saleintegerPart) || saleintegerPart === 0) ? null : (
                      <span style={{ color: 'green', fontSize: '12px', fontWeight: '300' }}>
                        {saleintegerPart}
                      </span>
                    )}
                  </div>
                </div>
 
              </CardContent>
            </Card>
            
          );
        })}
      </div>
    </>
  );
}

export default Discover;

import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import TermsTheme from './TermsTheme';
import BrandSlider from './BrandSlider';
function Terms() {

  return (
    <>
    <Header />
    <TermsTheme />
    <BrandSlider />
    <Footer />

    </>
  );
}

export default Terms;
import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import AboutTheme from './AboutTheme';
import BrandSlider from './BrandSlider';
function About() {

  return (
    <>
    <Header />
    <AboutTheme />
    <BrandSlider />
    <Footer />

    </>
  );
}

export default About;
import React, { useState } from 'react';

const LocationContext = React.createContext();

export function LocationProvider({ children }) {
  const [details, setDetails] = useState(null);

  const updateDetails = (newDetails) => {
    setDetails(newDetails);
  };

  return (
    <LocationContext.Provider value={{ details, updateDetails }}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocation() {
  return React.useContext(LocationContext);
}

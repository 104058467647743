import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Breadcrum from './Breadcrum';
import { useLocation } from 'react-router-dom';
import './Goods.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Footer from './Footer';
import { db } from '../firebase';
import { collection, where, query, getDocs } from 'firebase/firestore'; // Import collection and addDoc functions
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Popup from '../PopupEmail'
function Coupon(details) {
  const listRef = useRef(null);
  const maxListHeight = 900; // Adjust this value to your preferred maximum 
    const initialCategories = [
      {
        name: 'Brand',
        open: false,
        subcategories: ['BestVetCare', 'Namecheap', 'Watchs2u', 'Pin Paws', 'Tru Grit','Jegs','PishPosh','Token Metrics','A1Supplements', 'Magic Carpet', 'Eye Glasses.com', 'Duracell'],
      },
      {
        name: 'Categories',
        open: false,
        subcategories: ['Food', 'Goods', 'Electronics','Per Supplies','Eye Wear','Home & Garden'],
      },
      {
        name: 'Health & Beauty',
        open: false,
        subcategories: ['Aromatherapy', 'Bath & Body', 'Cosmetics', 'Fragrance', 'Hair Care', 'Massage','Men\'s','Oral Care','Personal Care'],
      },
      {
        name: 'Clothing & Shoes',
        open: false,
        subcategories: ['Watches & Jewelry', 'Fashion', 'Women','Men','Girls','Boys','Baby','Luggage','Luxury'],
      },
      {
        name: 'Home & Garden',
        open: false,
        subcategories: ['Tools', 'Discover', 'Decor','Furniture','Kitchen & Dining','Bed & Bath','Garden & Outdoor','Lighting','Appliances','Pet Supplies'],
      },
      {
        name: 'Electronics',
        open: false,
        subcategories: ['Computers', 'TV & Video', 'Home, Audio & Theater','Camera Phone & Video', 'Cell Phones', 'Accessories','Headphones','Games','Car Electronics','Musical','Wearables'],
      },
      {
        name: 'More',
        open: false,
        subcategories: ['Sports', 'Outdoors', 'Automotive','Food & Grocery', 'Toys Kids & Baby', 'Industrial','Books', 'Software'],
      }
    ];
    const [categories, setCategories] = useState(initialCategories);
    const [clickedButtons, setClickedButtons] = useState(initialCategories.map(() => false));
   
    const toggleCategory = (index) => {
      const updatedCategories = [...categories];
      const categoryToClose = updatedCategories[index]; // Store the category being closed
      updatedCategories[index].open = !updatedCategories[index].open;


   
      const isClicked = clickedButtons[index] || false;
      setClickedButtons((prevClickedButtons) => {
        prevClickedButtons[index] = !isClicked;
        return [...prevClickedButtons];
      });
  
      updatedCategories.forEach((category, idx) => {
        category.className = clickedButtons[idx] ? 'true' : 'false';
      });
  
      const totalOpenHeight = updatedCategories
      .filter((category) => category.open)
      .reduce((total, category) => total + 800 /* Adjust this value based on your design */, 0);
  

      if (totalOpenHeight > '3200') {
       window.scrollTo(0, 0);
      }


      setCategories(updatedCategories);
    };
  

    function arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
    
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
    
      return true;
    }
    
    useEffect(() => {
      const list = listRef.current;
      if (list) {
        const listHeight = list.scrollHeight;
        if (listHeight > maxListHeight) {
          // Automatically close categories one by one from the top down
          let totalHeight = 900;
          const updatedCategories = categories.map((category) => {
            const categoryHeight = 250; // Adjust this value based on your design
            if (totalHeight + categoryHeight > maxListHeight) {
              if (category.open) { // Only update if it's open
                return {
                  ...category,
                  open: false,
                };
              }
            }
            totalHeight += categoryHeight;
            return category;
          });
          if (!arraysAreEqual(updatedCategories, categories)) {
            setCategories(updatedCategories);
          }
        }
      }
    }, [categories]);
    


  const location = useLocation();
  const getCategory = location.pathname.split('/').pop();
  const selectedCategory = location.state?.selectedCategory; // Use the selected category or a default value
  const selectedState = location.state?.selectedState; // Use the selected category or a default value
  const selectedCity = location.state?.selectedCity; // Use the selected category or a default value
  const [data, setData] = useState([]); // State to hold the fetched data
  const page = 'coupons';
  useEffect(() => {
    fetchDataFromFirestore(getCategory);
  }, [getCategory]);
  

 const fetchDataFromFirestore = async (category) => {
  try {
    const q = query(collection(db, 'Product_Deals'), where('category', '==', category));
    const querySnapshot = await getDocs(q);

    const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      setData(data);
      //firebase data from category
      
  } catch (error) {
    console.error('Error fetching data from Firestore:', error);
  }
};


  return (
    <>
    <Header />
    <Popup />
    <Breadcrum page={page} category={selectedCategory} state={selectedState} city={selectedCity}/>
    <div className='box'>
      <div className='left'>
      <div className="local-container">
      <div className="categories" ref={listRef}>
        <ul>
          {categories.map((category, index) => (
            <li key={index}>
              <button
                id={`category-button-${index}`}
                onClick={() => toggleCategory(index)}

  className={`categories-button ${category.open ? 'open' : ''} ${clickedButtons[index] ? 'true' : 'false'} ${index === 1 && clickedButtons[1] && clickedButtons[2] ? 'border-top' : ''}`}  
  >
                {category.name} {category.open ? <span className="removeicon"><RemoveIcon /></span> : <span className="addicon"><AddIcon /></span>}
              </button> 
              <ul className={`subcategories ${category.open ? 'open' : ''}`}>
                {category.subcategories.map((subCategory, subIndex) => (
                  <li key={subIndex}>{subCategory}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
        </div>
      <div className='right'>
          </div>
    </div>

<Footer details={details} /> 

    </>
  );
}

export default Coupon;

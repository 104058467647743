import React from 'react';
import './BrandSlider.css';
import alogo from '../brandlogos/amazonlogo.png';
import blogo from '../brandlogos/duracellLogo.png';
import clogo from '../brandlogos/grillgrils.png';
import dlogo from '../brandlogos/jegslogo.png';
import elogo from '../brandlogos/lenovoLogo.png';
import flogo from '../brandlogos/namecheapLogo.png';
import glogo from '../brandlogos/pinpawslogo.png';
import hlogo from '../brandlogos/pishposhLogo.png';
import ilogo from '../brandlogos/tokenlogo.png';
import jlogo from '../brandlogos/trugritlogo.png';
import klogo from '../brandlogos/watches2u.png';
import { Link } from 'react-router-dom';



const Slider = () => {
  return (
    <div className="slider" style={{height: "120px", marginBottom: '40px'}} >
      <div className="slide-track" style={{marginTop: '25px'}}>
      <div className="slide">
          <Link to="https://amzn.to/45JPly9"><img src={alogo} height="75px" width="175" alt="Brand Logo 1" /></Link>
        </div>
        <div className="slide">
          <Link to="https://duracell.sjv.io/k0bNQn"><img src={blogo} height="75px" width="175" alt="Brand Logo 2" /></Link>
        </div>  
        <div className="slide">
         <Link to="https://grillagrills.pxf.io/1rOVoa"> <img src={clogo} height="75px" width="175" alt="Brand Logo 3" /></Link>
        </div>    
        <div className="slide">
          <Link to="https://jegs.ork2.net/JzQ2ZE"><img src={dlogo} height="75px" width="175" alt="Brand Logo 4" /></Link>
        </div> 
        <div className="slide">
          <img src={elogo} height="75px" width="175" alt="Brand Logo 5" />
        </div> 
        <div className="slide">
        <Link to="https://namecheap.pxf.io/EKVWVe"><img src={flogo} height="75px" width="175" alt="Brand Logo 6" /></Link>
        </div> 
        <div className="slide">
          <Link to="https://pinpaws.sjv.io/Or5W5z"><img src={glogo} height="75px" width="175" alt="Brand Logo 7" /></Link>
        </div> 
        <div className="slide">
          <Link to="https://pish-posh-baby.sjv.io/vNbObO"><img src={hlogo} height="75px" width="175" alt="Brand Logo 8" /></Link>
        </div> 
        <div className="slide">
        <Link to="https://tokenmetrics.sjv.io/0ZO1nE"> <img src={ilogo} height="75px" width="175" alt="Brand Logo 9" /></Link>
        </div>
        <div className="slide">
        <img src={jlogo} height="70px" width="175" alt="Brand Logo 10" />
        </div> 
        <div className="slide">
        <Link to="https://watches2u.pxf.io/EKVgQK"><img src={klogo} height="70px" width="175" alt="Brand Logo 11" /></Link>
        </div> 
      </div>
    </div>
  );
};

export default Slider;
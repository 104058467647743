import React from 'react';
import './Join.css';

  export default function MobileMessage() {

    return (
        <>
        <img src="https://pinchcart.com/apple-touch-icon.png" alt="Apple" style={{ maxWidth: '40%', marginTop: '20px' }} />
         <h1 className='joinText'>Currently No Mobile Devices.</h1>   
         <div className="center-wrapper">
            <div className="mobilecontainer">
    			<h1 className='createAccount'>Join Pinchcart Cash Back using a Desktop PC or Laptop<br /> Apps Coming Soon</h1>
            </div>
        </div>
         
         <footer>
             <p>
             © 2024, Pinchcart™ Pinnacle, Pinchcart.com or its affiliates.
             </p>
         </footer>
         </>
)}
          //sign in with google function
   
    
  